import { action, computed, makeObservable, observable } from "mobx";
import api, { IStorageFileDTO } from "src/services/api";
import {
  ISetDescription,
  SessionInProgram,
} from "src/services/api/availability";
import notificator from "src/services/systemNotifications/notificationCenterService";
import { AuthStore, RouterStore } from "src/stores";
import formatStartEndDate from "src/util/formatStartEndDate";
import { validateEmail } from "src/util/validators";
import dayjs from "dayjs";
import { FunboxMode } from "src/services/api/common";
import navigateToSchedule from "src/util/navigateToSchedule";

export interface IDescriptionData {
  schedule_set_id: string;
  description: ISetDescription | null;
  funbox_id: string;
  location_id: number;
  funbox_mode: FunboxMode;
  set_start_date?: string;
  set_end_date?: string;
  first_nearby_session?: SessionInProgram;
}

export class ScheduleSetDescriptionModalVM {
  constructor(
    private descriptionData: IDescriptionData,
    private routerStore: RouterStore,
    private authStore: AuthStore,
    public onClose: () => void,
    public isNavigateToScheduleButtonVisible: boolean
  ) {
    makeObservable(this);
  }

  get setDatesString() {
    if (
      !this.descriptionData.set_start_date ||
      !this.descriptionData.set_end_date
    ) {
      return undefined;
    }
    return formatStartEndDate({
      start_date: this.descriptionData.set_start_date,
      end_date: this.descriptionData.set_end_date,
    });
  }

  @action.bound navigateToSchedule() {
    if (this.descriptionData.first_nearby_session) {
      navigateToSchedule(this.routerStore, this.authStore.loggedIn, {
        type: "session",
        session_id: this.descriptionData.first_nearby_session.id,
        session_date: this.descriptionData.first_nearby_session.date,
        selectedScheduleMonth: dayjs(
          this.descriptionData.first_nearby_session.date
        )
          .startOf("month")
          .format("YYYY-MM-DD"),
      });
      return;
    }
    navigateToSchedule(this.routerStore, this.authStore.loggedIn, {
      type: "schedule_set",
      schedule_set_id: this.descriptionData.schedule_set_id,
      selectedScheduleMonth: dayjs(this.descriptionData.set_start_date)
        .startOf("month")
        .format("YYYY-MM-DD"),
    });
  }

  @observable email = "";
  @observable emailError: string | null = null;

  private constructUrl(
    pathname: string,
    query: {
      location?: string;
      funbox?: string;
      open_schedule_set_id?: string;
    } = {}
  ): URL {
    const url = new URL(`https://${window.location.hostname}/${pathname}`);
    new URLSearchParams(query).forEach((value, key) =>
      url.searchParams.set(key, value)
    );
    return url;
  }

  @computed get shareLink() {
    const queryParams = {
      location: this.descriptionData.location_id.toString(),
      funbox: this.descriptionData.funbox_id,
      open_schedule_set_id: this.descriptionData.schedule_set_id,
    };
    return this.constructUrl("availability", queryParams);
  }

  @action.bound async sendEmail() {
    if (!validateEmail(this.email)) {
      this.emailError = "Invalid Email";
      return;
    }
    try {
      await api.attendances.shareScheduleSet({
        recipient: this.email,
        schedule_set_id: this.descriptionData.schedule_set_id,
      });
      notificator.success("Shared", "Email sent successfully");
    } catch (e) {
      notificator.error("Error", e);
    } finally {
      this.onClose();
    }
  }

  get attachments(): IStorageFileDTO[] {
    return this.descriptionData.description?.attachments ?? [];
  }

  get description() {
    return this.descriptionData.description;
  }
}
