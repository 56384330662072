import React from "react";

import {
  SmartFormsPageVmProvider,
  useSmartFormsPageVm,
} from "./SmartFormsPageVm";
import { observer } from "mobx-react-lite";
import PageRoot from "src/components/PageRoot";
import PageHeader from "src/components/PageHeader";
import { SmartFormsCompletenessVmProvider } from "./SmartFormsCompletenessVm";
import StudentsSelector from "src/components/StudentSelector";
import { CompletenessPercentage } from "src/components/CompletenessPercentage";
import { SmartFormDTO } from "src/services/api/smartForms";
import useStores from "src/hooks/useStores";
import {
  StudentQuestionSetAction,
  studentQuestionSetRoute,
} from "./StudentQuestionSetPage/StudentQuestionSetPage";
import { Button } from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";

interface IProps {}

const SmartFormsPage: React.FC<IProps> = (props) => (
  <SmartFormsCompletenessVmProvider>
    <SmartFormsPageVmProvider>
      <PersonalInformationPageImpl {...props} />
    </SmartFormsPageVmProvider>
  </SmartFormsCompletenessVmProvider>
);
const PersonalInformationPageImpl: React.FC<IProps> = observer(
  (props: IProps) => {
    const vm = useSmartFormsPageVm();

    return (
      <PageRoot>
        <PageHeader showBackLink>Forms</PageHeader>
        {vm.students.length > 0 ? (
          <PageBody />
        ) : (
          <div className="typography-h3 text-text-color">No Forms found</div>
        )}
      </PageRoot>
    );
  }
);

const PageBody: React.FC = observer(() => {
  const vm = useSmartFormsPageVm();
  const { requiredSets, optionalSets, completedSets } = vm;

  return (
    <div>
      <StudentsSelector
        className={"mb-4"}
        students={vm.students}
        onSelectStudent={(s) => vm.setSelectedStudentId(s.id)}
        selectedStudentId={vm.selectedStudent?.id}
        renderAdditionalStudentInfo={(s) => (
          <CompletenessPercentage
            className="typography-label"
            percentage={vm.completenessVm.completenessPercentageFor(s.id)!}
          />
        )}
      />
      <div className="flex flex-col gap-6">
        {requiredSets.length > 0 && (
          <SetGroup
            title={"Required Forms"}
            smartForms={requiredSets}
            action={"fill"}
          />
        )}
        {optionalSets.length > 0 && (
          <SetGroup
            title={"Optional Forms"}
            smartForms={optionalSets}
            action={"fill"}
          />
        )}
        {completedSets.length > 0 && (
          <SetGroup
            title={"Completed Forms"}
            smartForms={completedSets}
            action={"edit"}
          />
        )}
      </div>
    </div>
  );
});

const SetGroup: React.FC<{
  title: string;
  smartForms: SmartFormDTO[];
  action: StudentQuestionSetAction;
}> = observer(({ title, smartForms, action }) => {
  const { routerStore } = useStores();

  return (
    <div className="flex flex-col">
      <div className="typography-h2 mb-4">{title}</div>
      <div className="flex flex-col items-stretch gap-3">
        {smartForms.map((form) => (
          <div
            key={form.id}
            className="box-border flex w-full flex-row justify-between gap-[10px] rounded-[10px] bg-on-main-color p-4 shadow-big"
          >
            <div className="flex gap-2">
              <i
                className={classNames(
                  "icon ",
                  form.status === "COMPLETE" ||
                    form.status === "AWAITING_APPROVAL"
                    ? "smart-forms-icon bg-icon-dark-green-color"
                    : "smart-forms-blank-icon"
                )}
              />
              <div className="typography-h3 flex-grow text-text-color">
                {form.name}
              </div>
            </div>
            <div
              className="hover:cursor-pointer"
              onClick={() =>
                routerStore.navigateToRoute(
                  studentQuestionSetRoute.build({
                    pathParams: {
                      student_id: String(form.student_id),
                      smart_form_template_id: form.template_id,
                    },
                    searchParams: {
                      action: action,
                    },
                  })
                )
              }
            >
              {action === "edit" ? (
                <i className="icon edit-icon icon-hover" />
              ) : (
                <Button className="whitespace-nowrap" kind="text">
                  {form.resubmission_reason != null ? "Resubmit" : "Fill Out"}
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default SmartFormsPage;
