import { Button, Loader, MarkdownText } from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useLayoutEffect, useRef } from "react";
import LocationSelect from "src/components/LocationSelect";
import PageHeader from "src/components/PageHeader";
import PageRoot from "src/components/PageRoot";
import useStores from "src/hooks/useStores";
import { IProgramTypeDTO } from "src/services/api/common";
import { ROUTES } from "src/stores/RouterStore";
import {
  ExploreOfferingsPageVMProvider,
  useExploreOfferingsPageVM,
} from "./ExploreOfferingsPageVM";
import dayjs from "dayjs";
import InfoMessage from "src/components/InfoMessage/InfoMessage";
import InfiniteScroll from "react-infinite-scroller";
import { Link, Redirect } from "react-router-dom";
import navigateToSchedule from "src/util/navigateToSchedule";
import SelectLocationModal from "src/components/SelectLocationModal/SelectLocationModal";
import AvailabilityFiltersModal, {
  AvailabilityFiltersButton,
} from "src/components/AvailabilityFiltersModal/AvailabilityFiltersModal";
import { AgeRestrictionLabel } from "src/components/AvailabilityCards/CardsComponents";
import { ProgramCard } from "src/components/AvailabilityCards/ProgramCard";
import ScheduleSetDescriptionModal from "../../components/ScheduleSetDescriptionModal/ScheduleSetDescriptionModal";

const ExploreOfferingsPage = observer(() => {
  const { funboxStore } = useStores();
  if (
    funboxStore.selectedFunbox == null ||
    funboxStore.selectedLocation == null
  ) {
    return <Redirect to={ROUTES.ROOT} />;
  }
  return (
    <ExploreOfferingsPageVMProvider
      selectedFunbox={funboxStore.selectedFunbox}
      selectedLocation={funboxStore.selectedLocation}
    >
      <ExploreOfferingsPageImpl />
    </ExploreOfferingsPageVMProvider>
  );
});

const ExploreOfferingsPageImpl = observer(() => {
  const { funboxStore, routerStore, commonStore } = useStores();
  const vm = useExploreOfferingsPageVM();
  if (funboxStore.selectedFunbox == null) {
    return (
      <Redirect
        to={ROUTES.SELECT_FUNBOX_PAGE}
        from={ROUTES.EXPLORE_OFFERINGS}
      />
    );
  }

  if (funboxStore.selectedFunbox.type === "classes") {
    return <Redirect to={ROUTES.WELCOME_SCREEN} />;
  }

  return vm.selectedOffering != null ? (
    <OfferingAvailabilityPage offering={vm.selectedOffering} />
  ) : (
    <PageRoot>
      <div>
        <PageHeader showBackLink>Explore Offerings</PageHeader>
        {funboxStore.isBuyCreditsAndSaveEnabled &&
          funboxStore.hasPaidCreditTypes && (
            <div className="ml-[10px] flex items-center gap-1">
              <div className="typography-main text-gray-text-color">or</div>
              <Button
                onClick={() => routerStore.navigate(ROUTES.FLEXIBLE_PAYMENTS)}
                kind="text"
              >
                <i className="icon credit-types-icon icon-lg" />
                Buy Credits
              </Button>
            </div>
          )}
        <div className={"mt-2 flex justify-between"}>
          <LocationSelect
            className={"ml-[10px]"}
            selectedLocation={funboxStore.selectedLocation}
            onClick={() => (vm.isSelectLocationModalOpen = true)}
          />
          <AvailabilityFiltersButton vm={vm.filtersVM} />
        </div>
        {vm.loading ? (
          <Loader className="mt-10" />
        ) : (
          <div className="mt-4 mb-8 flex flex-col gap-[10px]">
            <div className="typography-h2 flex justify-center gap-[6px] rounded-xl bg-on-main-color p-4 text-header-color shadow-big">
              <i className="icon funbox-icon bg-main-color" />
              <div className="truncate">{vm.selectedFunbox.name}</div>
            </div>
            {vm.availableProgramTypes.map((pt) => (
              <OfferingCard key={pt.id} offering={pt} />
            ))}
            {vm.availableProgramTypes.length === 0 && (
              <InfoMessage className="px-4 py-8">
                {commonStore.companyProfile.name} has not <br></br> released the
                new schedule.<br></br> Please{" "}
                <Link to={ROUTES.HELP}>contact</Link> them for new info
              </InfoMessage>
            )}
          </div>
        )}
        <AvailabilityFiltersModal vm={vm.filtersVM} />
        {vm.isSelectLocationModalOpen && (
          <SelectLocationModal
            onClose={() => (vm.isSelectLocationModalOpen = false)}
            isShowingViewMoreButton={vm.isAtLeastOneOtherFunboxAvailable}
            selectedFunbox={vm.selectedFunbox}
            selectedLocation={vm.selectedLocation}
            onSelectLocation={funboxStore.selectLocationId}
            useGlobalFolderSelector={true}
          />
        )}
      </div>
    </PageRoot>
  );
});

const OfferingCard = observer(({ offering }: { offering: IProgramTypeDTO }) => {
  const vm = useExploreOfferingsPageVM();

  return (
    <div
      onClick={() => vm.selectOfferingToViewSchedules(offering)}
      className="mx-auto box-border w-[346px] cursor-pointer rounded-[10px] bg-on-main-color p-4 shadow-big"
    >
      <div className="flex items-start justify-between gap-3">
        <div className="typography-h3 overflow-hidden text-ellipsis text-header-color">
          {offering.name}
        </div>
        <AgeRestrictionLabel
          from_age={offering.from_age}
          to_age={offering.to_age}
        />
      </div>
      {(offering.thumbnail_file || offering.description) && (
        <MarkdownText
          image={
            offering.thumbnail_file?.download_url
              ? { url: offering.thumbnail_file.download_url }
              : undefined
          }
          className={classNames(
            "typography-small__t mt-4 text-text-color",
            offering.thumbnail_file && "min-h-[130px]"
          )}
        >
          {offering.description}
        </MarkdownText>
      )}

      <Button
        loading={vm.loadingAvailabilityOfferingId === offering.id}
        onClick={(e) => {
          e.stopPropagation();
          vm.selectOfferingToViewSchedules(offering);
        }}
        className="mx-auto mt-2"
        kind="text"
      >
        View Schedules
      </Button>
    </div>
  );
});

const OfferingAvailabilityPage = observer(
  ({ offering }: { offering: IProgramTypeDTO }) => {
    const vm = useExploreOfferingsPageVM();
    const { funboxStore, routerStore, authStore } = useStores();

    const containerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, [vm.availability]);

    return (
      <PageRoot className="flex flex-col">
        <PageHeader
          className="flex flex-col gap-[6px]"
          showBackLink
          onBackLinkClick={() => (vm.selectedOffering = undefined)}
        >
          {offering.name}

          <div className="flex items-start justify-between gap-3">
            <LocationSelect
              disabled
              className="ml-[6px]"
              selectedLocation={funboxStore.selectedLocation}
            />
            <AgeRestrictionLabel
              from_age={offering.from_age}
              to_age={offering.to_age}
            />
          </div>
        </PageHeader>
        <InfiniteScroll
          loader={<Loader className="py-6" />}
          hasMore={vm.availability.has_more}
          loadMore={vm.loadMore}
        >
          <div ref={containerRef} className="flex flex-col gap-[10px] pb-6">
            {vm.availability.items.map((s, index) => (
              <div key={s.id}>
                {(index === 0 ||
                  dayjs(vm.availability.items[index - 1].start_date).format(
                    "MMMM"
                  )) !== dayjs(s.start_date).format("MMMM") && (
                  <div className="typography-h2 mb-[10px] mt-[6px] rounded-xl bg-on-main-color py-4 text-center shadow-big">
                    {dayjs(s.start_date).format("MMMM")}&nbsp;
                    {!dayjs().isSame(s.start_date, "year") &&
                      dayjs(s.start_date).format("YYYY")}
                  </div>
                )}
                {s.programs.map((program) => (
                  <ProgramCard
                    key={program.id}
                    hideAgeRestrictionLabel
                    name={
                      <div>
                        {program.schedule_set_name}
                        {s.selected_description && (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              vm.openScheduleSetDescription(s);
                            }}
                            kind="text"
                            className="!typography-label mt-[2px] whitespace-nowrap "
                          >
                            <i className="icon doc-icon" />
                            Schedule Description
                          </Button>
                        )}
                      </div>
                    }
                    {...{
                      onJoinWaitlistClick: () =>
                        navigateToSchedule(routerStore, authStore.loggedIn, {
                          type: "program",
                          selectedScheduleMonth: dayjs(program.start_date)
                            .startOf("month")
                            .format("YYYY-MM-DD"),
                          schedule_set_id: program.schedule_set_id,
                          program_id: program.id,
                        }),
                      onSelectProgram: () => {
                        navigateToSchedule(routerStore, authStore.loggedIn, {
                          type: "program",
                          selectedScheduleMonth: dayjs(program.start_date)
                            .startOf("month")
                            .format("YYYY-MM-DD"),
                          schedule_set_id: program.schedule_set_id,
                          program_id: program.id,
                        });
                      },
                      onApplyClick: () => {
                        navigateToSchedule(routerStore, authStore.loggedIn, {
                          type: "program",
                          selectedScheduleMonth: dayjs(program.start_date)
                            .startOf("month")
                            .format("YYYY-MM-DD"),
                          schedule_set_id: program.schedule_set_id,
                          program_id: program.id,
                        });
                      },
                      onDropInClick: ({ date, id }) => {
                        navigateToSchedule(routerStore, authStore.loggedIn, {
                          type: "session",
                          session_date: date,
                          session_id: id,
                          selectedScheduleMonth: dayjs(date)
                            .startOf("month")
                            .format("YYYY-MM-DD"),
                        });
                      },

                      showDropIns: true,
                      showApplications: true,
                      selectionKind: "navigateToScheduleButton",
                      isSelected: false,
                      program,
                      selectedParticipants: [],
                      selectedSessions: [],
                      scheduleSetForExploreOfferingsPage: s,
                    }}
                  />
                ))}
              </div>
            ))}
          </div>
        </InfiniteScroll>
        {vm.selectedScheduleSetWithDescription && (
          <ScheduleSetDescriptionModal
            descriptionData={{
              description:
                vm.selectedScheduleSetWithDescription.selected_description,
              location_id: vm.selectedScheduleSetWithDescription.location_id,
              funbox_mode: vm.selectedScheduleSetWithDescription.funbox_mode,
              funbox_id: vm.selectedScheduleSetWithDescription.funbox_id,
              schedule_set_id: vm.selectedScheduleSetWithDescription.id,
              set_start_date: vm.selectedScheduleSetWithDescription.start_date,
              set_end_date: vm.selectedScheduleSetWithDescription.end_date,
              first_nearby_session:
                vm.selectedScheduleSetWithDescription.funbox_mode === "SESSIONS"
                  ? vm.selectedScheduleSetWithDescription.programs.map((p) =>
                      p.sessions.find(
                        (s) =>
                          dayjs(s.date).isSame(dayjs().format("YYYY-MM-DD")) ||
                          dayjs(s.date).isAfter(dayjs().format("YYYY-MM-DD"))
                      )
                    )[0]
                  : undefined,
            }}
            onClose={vm.closeScheduleSetDescription}
            isNavigateToScheduleButtonVisible={true}
          />
        )}
      </PageRoot>
    );
  }
);

export default ExploreOfferingsPage;
