import React from "react";
import PageRoot from "../../components/PageRoot";
import PageHeader from "../../components/PageHeader";
import { observer } from "mobx-react-lite";
import useStores from "../../hooks/useStores";
import notificator from "../../services/systemNotifications/notificationCenterService";
import { IQuestionPipelineDelegate } from "@sizdevteam1/funjoiner-web-shared/components/QuestionPipeline/QuestionPipelineVm";
import QuestionPipeline from "@sizdevteam1/funjoiner-web-shared/components/QuestionPipeline/QuestionPipeline";
import storageFileResolver from "../../services/storageFileResolver";

export type IQuestionPipelinePageProps = {
  description: string;
  delegate: IQuestionPipelineDelegate;
};
export const QuestionPipelinePage: React.FC<IQuestionPipelinePageProps> =
  observer((props) => {
    const { customerStore, questionSetStore } = useStores();
    return (
      <PageRoot>
        <PageHeader showBackLink>Forms</PageHeader>
        <QuestionPipeline
          storageFileResolver={storageFileResolver}
          className={"w-full flex-auto"}
          questionSetProcessorDelegate={questionSetStore}
          studentsProvider={customerStore}
          onError={(error) => notificator.error("Error!", error)}
          {...props}
        />
      </PageRoot>
    );
  });
