import React from "react";
import dayjs from "dayjs";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import useStores from "src/hooks/useStores";
import { useCancelOrReschedulePageVM } from "./CancelOrRescheduleVM";
import { Avatar, Button } from "@sizdevteam1/funjoiner-uikit";
import { ReactNode } from "react";
import PageHeader from "src/components/PageHeader";
import PageRoot from "src/components/PageRoot";
import { IProgramOfSessionsAttendanceDTO } from "src/services/api";
import {
  IAttendanceItem,
  IOvernightProgramAttendanceDTO,
} from "src/services/api/attendances";
import formatStartEndDate from "src/util/formatStartEndDate";
import { GroupedSessions } from "src/components/GroupedSessions/GroupedSessions";

export const ExpiredMsg: React.FC = () => {
  const {
    commonStore: {
      companyProfile: {
        documents: { cancellation_policy },
      },
    },
  } = useStores();
  return (
    <span className={classNames("text-main px-1 text-center text-text-color")}>
      Due to{" "}
      <a className="text-main-color" href={cancellation_policy}>
        Company Policy
      </a>
      , Self Rescheduling and Cancelation expired for this reservation. Please
      get in touch with the business directly for special consideration.
    </span>
  );
};

export const StudentTile: React.FC = observer(() => {
  const student = useCancelOrReschedulePageVM().student;

  return (
    <div className={"flex items-center gap-3"}>
      <Avatar person={student} avatarSize={"44px"} />
      <span className="text-h3 text-text-color">
        {[student.first_name, student.last_name].join(" ")}
      </span>
    </div>
  );
});

export const CancelOrRescheduleFrom: React.FC = observer(() => {
  const vm = useCancelOrReschedulePageVM();

  const { scheduleSetName, programTypeName, location, attendance } = vm.from;
  const type = attendance.type;

  return (
    <div>
      <div
        className={classNames(
          "border-[1px] border-solid border-delete-color bg-card-color ",
          "overflow-hidden rounded-lg shadow-big "
        )}
      >
        <div className="flex items-start justify-between gap-2  p-4">
          <div className={classNames("flex flex-col ")}>
            <div className={"typography-small__t text-gray-text-color"}>
              {scheduleSetName}
            </div>
            <span className="typography-h3 text-text-color">
              {programTypeName}
            </span>
          </div>
          <div className="typography-small flex items-center gap-1 text-gray-text-color">
            <i className="icon locations-icon h-4 w-4" />
            {location.name}
          </div>
        </div>

        {
          {
            program: (
              <OvernightProgramTile
                overnightProgram={attendance as IOvernightProgramAttendanceDTO}
              />
            ),
            program_of_sessions: (
              <ProgramOfSessionsTile
                daycampProgram={
                  {
                    ...attendance,
                  } as IProgramOfSessionsAttendanceDTO
                }
              />
            ),

            session_in_program: (
              <SessionTile session={attendance as IAttendanceItem} />
            ),
          }[type]
        }
      </div>
    </div>
  );
});

const ProgramOfSessionsTile = ({
  daycampProgram,
}: {
  daycampProgram: IProgramOfSessionsAttendanceDTO;
}) => {
  if (daycampProgram.sessions_display_option === "DEFAULT") {
    return (
      <>
        {daycampProgram.items.map((s) => (
          <SessionTile key={s.session_id} session={s} />
        ))}
      </>
    );
  }
  return (
    <GroupedSessions
      displayOption={daycampProgram.sessions_display_option}
      sessions={daycampProgram.items}
    />
  );
};

const SessionTile = ({ session }: { session: IAttendanceItem }) => {
  const date = dayjs(session.date);
  const fakeDate = "2000-01-01";
  const start = dayjs(`${fakeDate}T${session.start_time}`);
  const end = dayjs(`${fakeDate}T${session.end_time}`);

  return (
    <div
      className={
        "flex items-center justify-between py-2 px-4 even:bg-table-row-color"
      }
    >
      <span className="typography-main text-text-color">
        {date.format("MMM Do")}
      </span>
      <span className="typography-main text-gray-text-color">
        {start.format("h:mmA")} - {end.format("h:mmA")}
      </span>
    </div>
  );
};

const OvernightProgramTile = ({
  overnightProgram,
}: {
  overnightProgram: IOvernightProgramAttendanceDTO;
}) => {
  return (
    <div className="typography-main overflow-hidden bg-table-row-color py-2   text-center text-text-color">
      {formatStartEndDate(overnightProgram)}
    </div>
  );
};

interface ISubmitButtonProps {
  onSubmit: () => Promise<void>;
  disabled?: boolean;
  children: string;
}

export const SubmitButton: React.FC<ISubmitButtonProps> = observer(
  (props: ISubmitButtonProps) => {
    return (
      <div className={"box-border max-w-[414px] py-3 "}>
        <Button
          className="mx-auto w-[366px]"
          kind={"action"}
          size={"big"}
          disabled={props.disabled}
          onClick={props.onSubmit}
          autoLoading={true}
        >
          {props.children}
        </Button>
      </div>
    );
  }
);

interface ICancelOrReschedulePageRootProps {
  header: string;
  children: ReactNode[] | ReactNode;
  footer: ReactNode;
}

export const CancelOrReschedulePageRoot: React.FC<ICancelOrReschedulePageRootProps> =
  observer((props: ICancelOrReschedulePageRootProps) => {
    return (
      <>
        <PageRoot className={"min-h-[100dvh] !p-0"}>
          <div>
            <PageHeader showBackLink={true}>{props.header}</PageHeader>
            {props.children}
          </div>
        </PageRoot>
        <div className="sticky inset-0 mt-auto bg-card-color">
          {props.footer}
        </div>
      </>
    );
  });
