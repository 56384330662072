import { observer } from "mobx-react-lite";
import React, { useRef } from "react";
import { useDashboardPageVM } from "../DashboardPageVM";
import { Button, Modal } from "@sizdevteam1/funjoiner-uikit";
import cn from "classnames";

const ScheduleFiltersModal = observer(() => {
  const vm = useDashboardPageVM();
  const ref = useRef(null);

  return (
    <Modal
      contentClassName="!bg-transparent !p-4"
      containerClassName="min-w-[366px] max-h-screen max-w-[414px] w-full !shadow-none"
      alignment="bottom"
      onClose={() => (vm.isScheduleFilterModalOpened = false)}
      closeOnBackDrop
    >
      <div ref={ref} className="flex flex-col gap-3">
        <span className="typography-h2 text-center text-on-main-color">
          Schedules Filter
        </span>
        <FilterOption
          name="All"
          renderName={() => <i className="icon funbox-icon icon-lg" />}
        />
        {vm.funboxNames.map((f, index) => (
          <FilterOption key={index} name={f} />
        ))}
        <FilterOption
          name="Nevermind"
          onClick={() => (vm.isScheduleFilterModalOpened = false)}
          className="!text-delete-color hover:!text-icon-red-color"
        />
      </div>
    </Modal>
  );
});

const FilterOption = observer(
  ({
    name,
    renderName,
    onClick,

    className,
  }: {
    name: string;
    renderName?: () => React.ReactNode;
    onClick?: () => void;
    className?: string;
  }) => {
    const vm = useDashboardPageVM();
    const isSelected = name === vm.selectedFilter;
    return (
      <Button
        className={cn(
          "box-border  !h-[56px]  w-full !rounded-[13px] !border-[3px] !border-solid !bg-table-row-color !p-4",
          isSelected ? "!border-main-color" : "!border-transparent-color",
          className
        )}
        onClick={() => (onClick ?? vm.setFilter)(name)}
        kind="text"
      >
        <div className="flex">
          {renderName?.()}
          <span className="max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap break-words">
            {name}
          </span>
        </div>
      </Button>
    );
  }
);

export default ScheduleFiltersModal;
