import React from "react";
import cn from "classnames";
import MultiAvatar from "src/components/MultiAvatar";
import { Button, Modal } from "@sizdevteam1/funjoiner-uikit";
import { TStudentOrCreateIntention } from "src/stores/ScheduleStore/ParticipantSelectionVM";
import classNames from "classnames";

interface IProps {
  className?: string;
  selectedParticipants: Array<TStudentOrCreateIntention>;
  actions?: {
    select: () => void;
    remove: () => void;
    unlink: () => void;
  };
}

const ParticipantSelect: React.FC<IProps> = ({
  className,
  selectedParticipants,
  actions,
}) => {
  const { unlink, remove, select } = actions ?? {};

  const [removeModalOpened, setRemoveModalOpened] = React.useState(false);
  const [unlinkModalOpened, setUnlinkModalOpened] = React.useState(false);
  const len = selectedParticipants.length;
  const isCustomerScheduling =
    selectedParticipants.length === 1 &&
    (selectedParticipants[0].id === -1 ||
      selectedParticipants[0].type === "CUSTOMER_AS_PARTICIPANT");

  const participantNames = isCustomerScheduling
    ? "(You)"
    : selectedParticipants
        .slice(0, 2)
        .map(({ full_name }) => full_name)
        .join(", ");

  return (
    <div className={cn("flex items-center justify-between", className)}>
      <div className={classNames("flex items-center")}>
        <MultiAvatar participants={selectedParticipants} />
        <div
          style={{ width: actions == null ? 250 : 216 }}
          className="ml-3 flex flex-col gap-1"
        >
          <div
            className={cn(
              "flex items-center text-text-color",
              len > 1 ? "text-main_sb" : "text-h3"
            )}
          >
            <div className="overflow-hidden text-ellipsis whitespace-nowrap">
              {participantNames}
            </div>
            {len === 1 && !isCustomerScheduling && select && (
              <Button onClick={select} kind="icon">
                <i className="icon edit-icon icon-sm !ml-2" />
              </Button>
            )}
            {len > 2 && (
              <div className={"ml-1 text-gray-text-color"}>+{len - 2}</div>
            )}
          </div>
          {len > 1 && (
            <div
              className={
                "typography-main flex items-center text-gray-text-color"
              }
            >
              <Button kind={"text"} onClick={() => setUnlinkModalOpened(true)}>
                Unlink
              </Button>
              <div className="mx-3 h-[20px] w-[1px] bg-separator-color"> </div>
              {len}
              <i className={"icon connect-icon mr-1 ml-[2px]"} />
              Participants
            </div>
          )}
        </div>
      </div>
      {remove && len === 1 && (
        <Button
          className="!typography-label "
          kind={"text"}
          color={"red"}
          onClick={() => setRemoveModalOpened(true)}
        >
          Remove
        </Button>
      )}
      {select && !isCustomerScheduling && len > 1 && (
        <Button onClick={select} kind="icon">
          <i className="icon edit-icon" />
        </Button>
      )}
      {removeModalOpened && remove && (
        <RemoveModal
          onClose={() => setRemoveModalOpened(false)}
          remove={remove}
        />
      )}
      {unlinkModalOpened && unlink && (
        <UnlinkModal
          unlink={unlink}
          onClose={() => setUnlinkModalOpened(false)}
        />
      )}
    </div>
  );
};

interface IRemoveModalProps {
  onClose: () => void;
  remove: () => void;
}

const RemoveModal: React.FC<IRemoveModalProps> = ({ onClose, remove }) => {
  return (
    <Modal
      onClose={onClose}
      headerText={"Remove Participant"}
      headerClassName="!bg-delete-color"
      containerClassName="min-w-[366px] max-w-[414px]"
    >
      <div className={cn("typography-main text-center text-text-color")}>
        If you choose to <strong>REMOVE</strong> this participant, any
        selections attached to them will clear.<br></br>
        <div className="typography-main mt-2">
          You will need to reselect the Participant and their dates again.
        </div>
      </div>
      <div className={"mt-6 grid grid-cols-2 gap-4"}>
        <Button
          className="w-full"
          kind={"secondary"}
          size={"big"}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button color="red" className="w-full" size={"big"} onClick={remove}>
          Remove
        </Button>
      </div>
    </Modal>
  );
};

interface IUnlinkModalProps {
  onClose: () => void;
  unlink: () => void;
}

const UnlinkModal: React.FC<IUnlinkModalProps> = (props) => {
  return (
    <Modal
      onClose={props.onClose}
      headerText={
        <div className="pointer-events-none flex gap-[6px]">
          Unlink <i className="icon couple-people-icon" /> Participants
        </div>
      }
      containerClassName="min-w-[366px] max-w-[414px]"
    >
      <div className={cn("typography-main text-center text-text-color")}>
        If all your dates match, staying linked saves time during registration.
      </div>
      <div className="typography-main_sb mt-2 text-center text-text-color">
        When you “Unlink”, you can adjust Schedules for each person
        individually.
      </div>
      <div className={"mt-6 grid grid-cols-2 gap-4"}>
        <Button
          className="w-full"
          kind={"secondary"}
          size={"big"}
          onClick={props.onClose}
        >
          Nevermind
        </Button>
        <Button className="w-full" size={"big"} onClick={props.unlink}>
          <i className={"icon unlink-icon icon-lg"} />
          Unlink
        </Button>
      </div>
    </Modal>
  );
};
export default ParticipantSelect;
