import React from "react";
import { observer } from "mobx-react-lite";
import PageHeader from "../../../components/PageHeader";
import {
  Button,
  Input,
  Label,
  Modal,
  PhoneInput,
  Switcher,
  Textarea,
} from "@sizdevteam1/funjoiner-uikit";
import PageRoot from "../../../components/PageRoot";
import useStores from "../../../hooks/useStores";
import { ROUTES } from "../../../stores/RouterStore";
import classNames from "classnames";
import {
  PersonalInfoPageVMProvider,
  usePersonalInfoPageVM,
} from "./PersonalInfoPageVM";
import CodeInput from "../../../components/CodeInput";
import TimeoutButton from "../../../components/TimeoutButton";

interface IProps {}

const PersonalInfoPage: React.FC<IProps> = (props) => {
  return (
    <PersonalInfoPageVMProvider>
      <PersonalInfoPageImpl />
    </PersonalInfoPageVMProvider>
  );
};
const PersonalInfoPageImpl: React.FC<IProps> = observer((props) => {
  const { customerStore } = useStores();
  const vm = usePersonalInfoPageVM();
  return (
    <PageRoot>
      <PageHeader
        className="!mb-6"
        showBackLink={true}
        backLinkRoute={ROUTES.PROFILE}
      >
        <div className={"flex flex-row justify-between"}>Personal Info</div>
      </PageHeader>
      <div className={"flex flex-col"}>
        <div className={"flex flex-col gap-3"}>
          <div className="flex items-start gap-3">
            <i className="icon profile-icon h-6 w-6 bg-main-color" />
            {vm.isNameDefined ? (
              <div
                className={
                  "typography-h2 flex w-full items-start justify-between  gap-3 text-text-color"
                }
              >
                <div className="max-w-[294px] break-words">
                  {" "}
                  {vm.customer.full_name}{" "}
                </div>

                <Button
                  kind={"icon"}
                  onClick={() => (vm.isNameModalOpen = true)}
                >
                  <i className="icon edit-icon !bg-main-color hover:!bg-dark-main-color" />
                </Button>
              </div>
            ) : (
              <Button
                onClick={() => (vm.isNameModalOpen = true)}
                kind="text"
                className="w-fit "
              >
                Add Name
              </Button>
            )}
          </div>
          <div key={"phone"} className={"flex gap-3"}>
            <i className={classNames("icon mobile-icon bg-main-color")} />
            {vm.customer.phone_number ? (
              <div
                className={
                  "typography-main flex w-full justify-between text-text-color"
                }
              >
                {vm.customer.phone_number}
                <Button
                  kind={"icon"}
                  onClick={() => (vm.modal.state = "phone")}
                >
                  <i className="icon edit-icon !bg-main-color hover:!bg-dark-main-color" />
                </Button>
              </div>
            ) : (
              <Button kind={"text"} onClick={() => (vm.modal.state = "phone")}>
                Add Now
              </Button>
            )}
          </div>
          <div key={"email"} className={"flex  gap-3"}>
            <i className={classNames("icon emails-icon bg-main-color")} />
            {vm.customer.email ? (
              <div
                className={
                  "typography-main flex w-full justify-between gap-3 text-text-color"
                }
              >
                {vm.customer.email}
                <Button
                  kind={"icon"}
                  onClick={() => (vm.modal.state = "email")}
                >
                  <i className="icon edit-icon !bg-main-color hover:!bg-dark-main-color" />
                </Button>
              </div>
            ) : (
              <Button kind={"text"} onClick={() => (vm.modal.state = "email")}>
                Add Now
              </Button>
            )}
          </div>

          <div className={"flex items-start gap-3"}>
            <i className={classNames("icon address-icon bg-main-color")} />
            {vm.customer.address ? (
              <div className="flex w-full items-start justify-between gap-3">
                <div
                  className={
                    "typography-main max-w-[294px] break-words text-text-color"
                  }
                >
                  {vm.customer.address}
                </div>
                <Button
                  kind={"icon"}
                  onClick={() => (vm.isAddressModalOpen = true)}
                >
                  <i className="icon edit-icon !bg-main-color hover:!bg-dark-main-color" />
                </Button>
              </div>
            ) : (
              <Button
                kind="text"
                className="w-fit"
                onClick={() => (vm.isAddressModalOpen = true)}
              >
                Add Address
              </Button>
            )}
          </div>
        </div>
        <div>
          <div className={"typography-h2 mt-6 text-text-color"}>
            Communication Preferences
          </div>
          <div className={"typography-main mt-4 flex  gap-4 text-text-color"}>
            <Switcher
              value={
                customerStore.customer.is_phone_number_communication_opted_in ??
                false
              }
              onClick={customerStore.updateCommunicationPreferences}
            />
            <div className="flex gap-[6px]">
              <i className="icon communicate-icon bg-main-color" />
              Text (SMS)
            </div>
          </div>
          <div
            className={`typography-small__t mt-3 flex flex-col gap-2
              rounded-[10px] bg-on-main-color px-6 py-4 text-gray-text-color shadow-big`}
          >
            <div className="">
              You agree to receive Text (SMS) messages about changes and updates
              related to your reservations. SMS/Data rates may apply
            </div>
            <div>
              See&nbsp;
              <a
                href="https://funjoin.com/for-business/terms-of-service/"
                target={"_blank"}
                rel="noreferrer"
              >
                Terms of Use
              </a>
              &nbsp; & &nbsp;
              <a
                href="https://funjoin.com/for-business/privacy-policy/"
                target={"_blank"}
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .<br></br>
              Reply <strong>STOP</strong> to unsubscribe anytime.
            </div>
            <Button
              onClick={() => (vm.isReportAbuseModalOpen = true)}
              className="!typography-label w-fit"
              kind="text"
              color="red"
            >
              Report Abuse
            </Button>
          </div>
        </div>
      </div>
      <SetEmailOrPhoneModal />
      {vm.isNameModalOpen && <NameModal />}
      {vm.isAddressModalOpen && <AddressModal />}
      {vm.isReportAbuseModalOpen && <ReportAbuseModal />}
    </PageRoot>
  );
});

const SetEmailOrPhoneModal = observer(() => {
  const vm = usePersonalInfoPageVM().modal;
  if (vm.state === "closed") {
    return null;
  }
  return (
    <Modal
      className={"!z-10"}
      containerClassName="min-w-[366px]  max-w-[414px] w-full "
      headerText={vm.state === "email" ? "Email Address" : "Phone Number"}
      onClose={vm.close}
    >
      {
        {
          input: (
            <div>
              <div className={"typography-main text-center text-text-color"}>
                Enter{" "}
                {vm.state === "email"
                  ? "email address"
                  : "the cell phone number"}{" "}
                you wish to use for your account Log In
              </div>
              <div className={"mt-6"}>
                {vm.state === "email" ? (
                  <Input
                    inputMode={"email"}
                    type={"email"}
                    placeholder={"New Email Address"}
                    value={vm.email}
                    onChange={(e) => vm.setEmail(e.currentTarget.value)}
                  />
                ) : (
                  <PhoneInput value={vm.phone} onChange={vm.setPhone} />
                )}
              </div>
              <div className={"mt-6"}>
                {vm.shouldGetCode ? (
                  <Button
                    autoLoading
                    className={"mx-auto"}
                    kind={"text"}
                    disabled={!vm.isValidInput}
                    onClick={vm.getCode}
                  >
                    Get Code
                  </Button>
                ) : (
                  <Button
                    size={"big"}
                    autoLoading
                    className={"w-full"}
                    disabled={!vm.isValidInput}
                    onClick={vm.save}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          ),
          code_validation: (
            <div className={"relative"}>
              <div className={"typography-main text-center text-text-color"}>
                Confirmation code was sent to:
                <div className={"typography-main_sb"}>{vm.email}</div>
              </div>
              <CodeInput
                disabled={vm.isSubmittingCode}
                className={"mt-6"}
                values={vm.code.split("")}
                onChange={vm.setCode}
              />
              <TimeoutButton
                loading={vm.isSubmittingCode}
                onClick={vm.getCode}
                className={"mt-6"}
              />
            </div>
          ),
        }[vm.step]
      }
    </Modal>
  );
});

const NameModal = observer(() => {
  const vm = usePersonalInfoPageVM().nameModal;
  return (
    <Modal
      containerClassName="min-w-[366px]  max-w-[414px] w-full "
      onClose={vm.onClose}
      headerText={`${vm.isEdit ? "Edit " : "Set"} User Name`}
    >
      <div className="flex flex-col gap-6">
        <div>
          <Label required>Your First Name</Label>
          <Input
            name={"first_name"}
            value={vm.first_name}
            onChange={vm.handleInputChange}
            errorText={vm.first_name_error ?? ""}
          />
        </div>
        <div>
          <Label required>Your Last Name</Label>
          <Input
            name={"last_name"}
            value={vm.last_name}
            onChange={vm.handleInputChange}
            errorText={vm.last_name_error ?? ""}
          />
        </div>
        <Button size="big" autoLoading onClick={vm.submit}>
          Save
        </Button>
      </div>
    </Modal>
  );
});

const AddressModal = observer(() => {
  const vm = usePersonalInfoPageVM().addressModal;
  return (
    <Modal
      containerClassName="min-w-[366px]  max-w-[414px] w-full "
      onClose={vm.onClose}
      headerText={`${vm.isEdit ? "Edit " : "Set"} Address`}
    >
      <div className="flex flex-col gap-6">
        <div>
          <Label>Your Address</Label>
          <Textarea
            textareaClassName="resize-y min-h-[90px]"
            value={vm.address}
            onChange={({ currentTarget }) => vm.setAddress(currentTarget.value)}
            errorText={vm.addressError}
          />
        </div>
        <Button size="big" autoLoading onClick={vm.submit}>
          Save
        </Button>
      </div>
    </Modal>
  );
});

const ReportAbuseModal = observer(() => {
  const vm = usePersonalInfoPageVM().reportAbuseModal;
  return (
    <Modal
      headerClassName="!bg-delete-color"
      containerClassName="min-w-[366px]  max-w-[414px] w-full "
      headerText="Report Abuse"
      onClose={vm.onClose}
    >
      <div className="typography-small__t flex flex-col gap-4 text-text-color">
        <div>
          Businesses licensing FunJoin software are permitted to send only
          transactional Text (SMS) messages.
        </div>
        <div>
          If you've received any promotional/marketing Text (SMS) messages from
          them, please report below.
        </div>
        <div> Enter the message you received:</div>
      </div>
      <Textarea
        className="mt-4"
        textareaClassName="resize-y min-h-[138px]"
        onChange={({ currentTarget }) => vm.setAbuseText(currentTarget.value)}
        value={vm.abuseText}
        errorText={vm.abuseTextError}
      />
      <Button
        className="mt-6 w-full"
        onClick={vm.submit}
        autoLoading
        size="big"
        color="red"
      >
        Report Abuse
      </Button>
    </Modal>
  );
});
export default PersonalInfoPage;
