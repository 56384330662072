import React, { useMemo } from "react";
import useVM from "src/hooks/useVM";
import useStores from "src/hooks/useStores";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import {
  CustomerStore,
  PaymentMethodStore,
  PaymentStore,
  RouterStore,
} from "src/stores";
import {
  IOrderPaymentPlanInstallmentDTO,
  IScheduleAndPayOrderDTO,
  IScheduleAndPayOrderWithPaymentPLanDTO,
} from "src/services/api/orders";
import api, { ICardDTO, IStudentDTO, TAttendanceDTO } from "src/services/api";
import * as Sentry from "@sentry/react";
import notificator from "src/services/systemNotifications/notificationCenterService";
import { computedFn } from "mobx-utils";
import { useElements } from "@stripe/react-stripe-js";
import { StripeElements } from "@stripe/stripe-js";
import { scheduleAndPaySuccessRoute } from "../SuccessPages/ScheduleAndPaySuccessPage";
import groupBy from "../../util/groupBy";
import { PaymentMethodPickerVm } from "../../components/PaymentMethods/PaymentMethodPickerVm";
import { raise } from "@sizdevteam1/funjoiner-uikit";
import exists from "@sizdevteam1/funjoiner-uikit/util/exists";

export class OrderPaymentPlanPageVM {
  constructor(
    scheduleAndPayOrder: IScheduleAndPayOrderDTO,
    private routerStore: RouterStore,
    paymentMethodStore: PaymentMethodStore,
    private customerStore: CustomerStore,
    private paymentStore: PaymentStore,
    private elements: StripeElements | null
  ) {
    makeObservable(this);
    this._order = scheduleAndPayOrder;
    this.loadScheduledAttendances(scheduleAndPayOrder);

    this.paymentMethodPickerVm = new PaymentMethodPickerVm(
      {
        isInvoiceEnabled: computed(() => false),
        applePay: {
          payment: computed(() => {
            const installmentToPay = this.installmentToPay;
            return installmentToPay != null
              ? {
                  final_price: installmentToPay.amount,
                  description: this.order.description,
                }
              : undefined;
          }),
          isImmediateChargeRequired: true,
        },
      },

      elements,
      paymentStore,
      paymentMethodStore
    );

    if (this.scheduledStudents[0]) {
      this.selectStudent(this.scheduledStudents[0]);
    }
  }

  paymentMethodPickerVm: PaymentMethodPickerVm;

  @observable
  rememberCard: boolean = true;

  @observable
  emailInput = "";

  @observable
  emailError: string | null = null;

  @action
  setEmailInput = (v: string) => {
    this.emailError = null;
    this.emailInput = v;
  };

  @computed
  get requiresEmail() {
    return !this.customerStore.customer.email;
  }

  @computed get plan() {
    return this.order.payment_plan!;
  }

  @observable mode: "view_plan" | "payment" = "view_plan";

  @observable installmentToPay?: IOrderPaymentPlanInstallmentDTO;

  @computed get installmentToPayTotalAmountToPay() {
    return this.installmentToPay?.amount ?? 0;
  }

  @observable processingPayment: { card: ICardDTO; amount: number } | null =
    null;

  @action payInstallment = async () => {
    if (!this.order || !this.elements || !this.installmentToPay) return;

    const card = await this.paymentMethodPickerVm.confirm(
      this.paymentMethodPickerVm.selectedPaymentMethod
    );

    try {
      let order: IScheduleAndPayOrderDTO = await api.orders.payInstallment(
        this.order.id,
        this.installmentToPay.id
      );

      const stripeInstallmentClientSecret =
        order.payment_plan?.installments[
          this.installmentToPay.ordinal_number - 1
        ].payment?.stripe_client_secret;

      if (!stripeInstallmentClientSecret) {
        throw new Error("Stripe client Error");
      }
      await this.paymentStore.confirmPayment(
        stripeInstallmentClientSecret,
        card.payment_method_id,
        this.rememberCard
      );

      this.processingPayment = {
        card,
        amount: this.installmentToPay.amount,
      };

      order = await this.paymentStore.waitForOrderInstallmentToProceed(
        this.order.id,
        this.installmentToPay.id,
        20000
      );

      this.paymentStore.saveCompletedOrder(order, card);
      runInAction(() => {
        this.processingPayment = null;
        this.routerStore.navigateToRoute(
          scheduleAndPaySuccessRoute.build({
            state: {
              bookingResult: {
                attendanceIds: order.sign_up.created_attendance_ids,
              },
              installmentId: this.installmentToPay?.id,
            },
          })
        );
      });
    } catch (e) {
      runInAction(() => {
        this.processingPayment = null;
      });
      console.error(e);
      Sentry.captureException(e);
      notificator.error("Payment failed!", e);
    }
  };

  isSelectedToPay = computedFn((installmentId: string) => {
    return this.installmentToPay?.id === installmentId;
  });

  @action.bound toPayment(installment_id: string) {
    const selectedInstallment = this.plan.installments.find(
      (i) => i.id === installment_id
    );
    if (selectedInstallment) {
      this.installmentToPay = selectedInstallment;
      this.mode = "payment";
    }
  }

  @computed get pendingInstallments() {
    return this.plan.installments.filter((i) => !i.is_paid && !i.is_missed);
  }

  @computed get missedInstallments() {
    return this.plan.installments.filter((i) => i.is_missed);
  }

  @computed get creditGroupsByType() {
    const credits = this.order.items.flatMap((i) => i.credits_created);

    return groupBy(credits, "credit_type_id").map((gr) => ({
      type: gr[0].credit_type,
      numberOfCredits: gr.length,
      totalGroupAmount: gr.reduce((acc, item) => {
        return acc + item.credit_type.price;
      }, 0),
    }));
  }

  private readonly _order: IScheduleAndPayOrderDTO;

  @computed get order() {
    return this._order as IScheduleAndPayOrderWithPaymentPLanDTO;
  }

  @observable private _orderAttendances: TAttendanceDTO[] = [];
  @computed get hasAttendances() {
    return this._orderAttendances.length > 0;
  }
  @computed get orderAttendancesForSelectedStudent() {
    return this._orderAttendances.filter(
      (a) => a.student_id === this.selectedStudent?.id
    );
  }

  @observable selectedStudent: Omit<IStudentDTO, "customer"> | null = null;

  @observable isFakeStudentSelectLoading = false;

  @action.bound selectStudent(s: Omit<IStudentDTO, "customer">) {
    if (this.selectedStudent?.id === s.id) {
      return;
    }
    this.isFakeStudentSelectLoading = true;
    this.selectedStudent = s;
    setTimeout(() => {
      this.isFakeStudentSelectLoading = false;
    }, 500);
  }

  @computed get scheduledStudents() {
    return this.customerStore.studentsWithCustomerAsParticipant.filter((s) =>
      Array.from(
        new Set(this._orderAttendances.map((o) => o.student_id))
      ).includes(s.id)
    );
  }
  @observable isLoadingAttendances = false;
  private loadScheduledAttendances(order: IScheduleAndPayOrderDTO) {
    const attendanceIdsOfCreatedCredits = order.items
      .flatMap((i) =>
        i.credits_created.flatMap((c) => c.redeemed_for?.attendance_id)
      )
      .filter(exists);
    if (attendanceIdsOfCreatedCredits.length === 0) {
      return;
    }

    this.isLoadingAttendances = true;
    try {
      api.attendances
        .getAttendancesByIds(attendanceIdsOfCreatedCredits)
        .then((a) => {
          this._orderAttendances = a;
          if (this.scheduledStudents[0]) {
            this.selectStudent(this.scheduledStudents[0]);
          }
        });
    } catch (e) {
      notificator.error("Failed to load attendances", e);
      Sentry.captureException(e);
    } finally {
      this.isLoadingAttendances = false;
    }
  }
}

const ctx = React.createContext<OrderPaymentPlanPageVM | null>(null);

export const OrderPaymentPlanPageVMProvider: React.FC<{
  order: IScheduleAndPayOrderDTO;
}> = ({ children, order }) => {
  const { routerStore, paymentMethodStore, customerStore, paymentStore } =
    useStores();
  const elements = useElements();

  const vm = useMemo(
    () =>
      new OrderPaymentPlanPageVM(
        order,
        routerStore,
        paymentMethodStore,
        customerStore,
        paymentStore,
        elements
      ),
    [
      order,
      routerStore,
      paymentMethodStore,
      customerStore,
      paymentStore,
      elements,
    ]
  );

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useOrderPaymentPlanPageVM = () => useVM(ctx);
