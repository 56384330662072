import {
  action,
  computed,
  IComputedValue,
  makeObservable,
  observable,
  override,
  toJS,
} from "mobx";
import React from "react";
import { ICancelablePromise } from "@sizdevteam1/funjoiner-web-shared/services";
import { debounce } from "debounce";
import exists from "@sizdevteam1/funjoiner-uikit/util/exists";

type Id = number | string;
export type DisplayId = number | string | symbol;

type Option<T extends DisplayId> = {
  id: T;
  name: React.ReactNode;
};

const ALL_OPTION_ID = Symbol("ALL_OPTION_ID");

type MultiSelectVmConfig<T extends Id> = {
  availableOptions: IComputedValue<Option<T>[]>;
} & AllOption<T>;

type AllOption<T extends Id> = AllOptionEnabled<T> | AllOptionDisabled<T>;
type AllOptionEnabled<T extends Id> = {
  supportsAll: true;
  allOption?: React.ReactNode;
  initialValue: MultiSelectValue<T>;
};

type AllOptionDisabled<T extends Id> = {
  supportsAll?: false;
  allOption?: never;
  initialValue: T[];
};

type MultiSelectValue<T> = T[] | "all";

abstract class BaseMultiSelectVm {
  @action.bound
  setError(error: string | undefined) {
    this._error = error;
  }

  @computed
  get error() {
    return this._error;
  }

  @computed
  get isAnythingSelected() {
    return this.selectedDisplayOptions.length > 0;
  }

  @computed
  get isNothingSelected() {
    return !this.isAnythingSelected;
  }

  abstract get selectedDisplayOptions(): Option<DisplayId>[];
  abstract get availableDisplayOptions(): Option<DisplayId>[];

  toMultiSelectProps() {
    return {
      errorText: this.error,
      selected: this.selectedDisplayOptions,
      options: this.availableDisplayOptions,
      onToggle: this.onToggle,
    } as const;
  }

  @action.bound
  protected onToggle(id: DisplayId) {
    this.setError(undefined);
  }

  @observable private _error: string | undefined;
}

export class MultiSelectVm<T extends Id> extends BaseMultiSelectVm {
  constructor(private config: MultiSelectVmConfig<T>) {
    super();
    makeObservable(this);

    this._selectedIds = config.initialValue;

    this._availableOptionsComputed = config.availableOptions;
    if (config.supportsAll) {
      this._allOption = {
        id: ALL_OPTION_ID,
        name: config.allOption ?? "All",
      };
    }
  }

  @computed
  get availableDisplayOptions(): Option<DisplayId>[] {
    const options: Option<DisplayId>[] = [...this._availableOptions];
    if (this._allOption != null) {
      options.push(this._allOption);
    }

    return options;
  }

  @computed
  get selectedDisplayOptions(): Option<DisplayId>[] {
    const availableDisplayOptionsByIds = new Map(
      this.availableDisplayOptions.map((option) => [option.id, option])
    );

    if (this._selectedIds === "all") {
      const allOption = availableDisplayOptionsByIds.get(ALL_OPTION_ID);
      return [allOption].filter(exists);
    }

    return this._selectedIds
      .map((id) => availableDisplayOptionsByIds.get(id))
      .filter(exists);
  }

  @override
  onToggle(id: DisplayId) {
    super.onToggle(id);

    if (this._selectedIds === "all") {
      if (id !== ALL_OPTION_ID) {
        this._selectedIds = [id as T];
      }
      return;
    }

    if (id === ALL_OPTION_ID) {
      this._selectedIds = "all";
      return;
    }

    const index = this._selectedIds.findIndex(
      (selectedId) => selectedId === id
    );
    if (index === -1) {
      this._selectedIds.push(id as T);
    } else {
      this._selectedIds.splice(index, 1);
    }

    if (this._selectedIds.length === 0 && this.config.supportsAll) {
      this._selectedIds = "all";
    }
  }

  @action.bound
  setValue(ids: MultiSelectValue<T>) {
    this._selectedIds = ids;
  }

  @computed
  get selectedIdsWithoutAll(): T[] {
    const selectedIds = this._selectedIds;
    if (this._allOption != null || selectedIds === "all") {
      throw new Error("All option is enabled");
    }
    return toJS(selectedIds);
  }

  @computed
  get payload(): T[] | "all" {
    return toJS(this._selectedIds);
  }

  toMultiSelectProps() {
    return {
      ...super.toMultiSelectProps(),
      nonClosableIds: Array<DisplayId>(ALL_OPTION_ID),
    } as const;
  }

  @computed
  private get _availableOptions() {
    return this._availableOptionsComputed.get();
  }

  @observable private _availableOptionsComputed: IComputedValue<Option<T>[]>;
  @observable protected _selectedIds: MultiSelectValue<T>;
  private readonly _allOption: Option<DisplayId> | undefined;
}

export class SearchMultiSelectVm<
  TId extends Id,
  TEntity
> extends BaseMultiSelectVm {
  constructor(config: SearchConfig<TId, TEntity>) {
    super();
    this._searchConfig = config;
    this._search = debounce(
      this._searchImpl.bind(this),
      this._searchConfig.debounce
    );
    makeObservable(this);

    if (this._searchConfig.fireImmediately) {
      this._searchImpl("").then();
    }
  }

  @computed
  get availableDisplayOptions(): Option<DisplayId>[] {
    const options = this._availableOptions;
    if (this._searchOptions.size > 0) {
      return options.filter((option) =>
        this._searchOptions.has(option.id as TId)
      );
    }
    return options;
  }

  @computed
  get selectedDisplayOptions(): Option<DisplayId>[] {
    const availableOptionsById = new Map(
      this._availableOptions.map((option) => [option.id, option])
    );
    return this._selectedIds.map((id) => availableOptionsById.get(id)!);
  }

  @override
  onToggle(id: DisplayId) {
    super.onToggle(id);
    const index = this._selectedIds.findIndex(
      (selectedId) => selectedId === id
    );

    if (index === -1) {
      this._selectedIds.push(id as TId);
    } else {
      this._selectedIds.splice(index, 1);
    }
  }

  @action.bound
  setValueFromOptions(options: Option<TId>[]) {
    this._availableOptions = mergeByIds(this._availableOptions, options);
    this._selectedIds = options.map((option) => option.id);
  }

  toMultiSelectProps() {
    return {
      ...super.toMultiSelectProps(),
      onSearch: this._search,
    } as const;
  }

  @computed
  get payload() {
    return toJS(this._selectedIds);
  }

  private _prevSearchPromise?: ICancelablePromise<TEntity>;
  @action.bound
  private async _searchImpl(search: string): Promise<void> {
    if (this._prevSearchPromise) this._prevSearchPromise.cancel();
    const searchPromise = this._searchConfig.search(search);
    this._prevSearchPromise = searchPromise;

    const entity = await searchPromise;
    const items = this._searchConfig.mapSearchResultToOptions(entity);
    const selectedOptions = this._selectedIds.map((id) => {
      return this._availableOptions.find((option) => option.id === id)!;
    });
    this._searchOptions = new Set(items.map((item) => item.id));
    this._availableOptions = mergeByIds(selectedOptions, items);
  }

  private readonly _search;
  private readonly _searchConfig: SearchConfig<TId, TEntity>;
  @observable private _availableOptions: Option<TId>[] = [];
  @observable private _searchOptions: Set<TId> = new Set();
  @observable private _selectedIds: TId[] = [];
}

type SearchConfig<TId extends Id, TEntity> = {
  search: SearchTrigger<TEntity>;
  fireImmediately?: boolean;
  mapSearchResultToOptions: (result: TEntity) => Option<TId>[];
  debounce: number;
};

type SearchTrigger<T> = (search: string) => ICancelablePromise<T>;

type Identifiable = {
  id: Id;
};
export function mergeByIds<T extends Identifiable>(
  array: Identifiable[],
  other: Identifiable[]
) {
  const map = new Map(array.map((item) => [item.id, item]));
  for (const item of other) {
    map.set(item.id, item);
  }
  return Array.from(map.values()) as T[];
}
