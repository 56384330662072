import React from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  DatePicker,
  Input,
  Label,
  Select,
  SingleFileUploader,
  Textarea,
} from "@sizdevteam1/funjoiner-uikit";
import {
  ParticipantCreateOrEditPageVMProvider,
  useParticipantCreateOrEditPageVM,
} from "./ParticipantCreateOrEditPageVM";
import PageRoot from "src/components/PageRoot";
import PageHeader from "src/components/PageHeader";
import dayjs from "dayjs";

const ParticipantCreateOrEditPage: React.FC = () => (
  <ParticipantCreateOrEditPageVMProvider>
    <ParticipantCreateOrEditPageImpl />
  </ParticipantCreateOrEditPageVMProvider>
);

const ParticipantCreateOrEditPageImpl: React.FC = observer(() => {
  const vm = useParticipantCreateOrEditPageVM();

  return (
    <PageRoot key={vm.createdParticipantsCount}>
      <PageHeader showBackLink>
        {vm.isEdit
          ? vm.participantOnEdit?.type === "CUSTOMER_AS_PARTICIPANT"
            ? "Edit You as "
            : "Edit "
          : "Add "}
        Participant
      </PageHeader>
      <div className="mt-4 flex flex-col gap-6">
        <SingleFileUploader
          crop={true}
          cropAspect={1}
          defaultSourceUrl={vm.updatePhoto.sourceUrl}
          onSelect={vm.updatePhoto.setNewImage}
          placeholder={"Add Photo"}
          mobile={true}
          loading={vm.updatePhoto.isUploading}
        />
        {(vm.participantOnEdit?.type === "REGULAR" || !vm.isEdit) && (
          <>
            <div>
              <Label required>Participant’s First Name</Label>
              <Input
                name={"first_name"}
                value={vm.first_name}
                onChange={vm.handleInputChange}
                errorText={vm.first_name_error ?? ""}
                placeholder={"First Name"}
              />
            </div>
            <div>
              <Label required>Participant’s Last Name</Label>
              <Input
                name={"last_name"}
                value={vm.last_name}
                onChange={vm.handleInputChange}
                errorText={vm.last_name_error ?? ""}
                placeholder={"Last Name"}
              />
            </div>
          </>
        )}

        <div>
          <Label required>Date of Birth</Label>
          <DatePicker
            value={vm.date}
            onSelect={vm.handleDateChange}
            defaultDate={vm.defaultDate}
            errorText={vm.date_error ?? ""}
            maxDate={dayjs().toISOString()}
          />
        </div>
        {vm.baselineQuestionsForParticipants.gender.status !== "disabled" && (
          <div>
            <Label
              required={
                vm.baselineQuestionsForParticipants.gender.status === "required"
              }
            >
              Participant’s Gender
            </Label>
            <Select
              placeholder="Select Option"
              options={vm.gender.availableOptions}
              selected={vm.gender.selectedOption}
              onSelect={vm.gender.selectOption}
              errorText={vm.gender.field.error ?? undefined}
            />
          </div>
        )}
        {vm.baselineQuestionsForParticipants.pronoun.status !== "disabled" && (
          <div>
            <Label
              required={
                vm.baselineQuestionsForParticipants.pronoun.status ===
                "required"
              }
            >
              Participant’s Pronoun(s)
            </Label>
            <Select
              options={vm.availablePronounOptions}
              selected={vm.selectedPronounOption}
              onSelect={(val) => vm.setPronoun(val.id)}
              errorText={vm.personalPronounError ?? undefined}
            />
          </div>
        )}

        <div className="h-[1px] w-full bg-separator-color"></div>
        <div>
          <Label>
            Any medical considerations for participant? <br></br>
            <span className="!text-placeholder-color">
              (Leave blank if none)
            </span>
          </Label>
          <Input
            name={"medical_considerations"}
            value={vm.medical_considerations}
            onChange={vm.handleInputChange}
            placeholder={"e. g. Headaches, knee pain, etc. "}
          />
        </div>

        <div>
          <Label>
            Does the participant have any allergies?
            <br></br>
            <span className="!text-placeholder-color">
              (Leave blank if none)
            </span>
          </Label>
          <Input
            name={"allergies"}
            value={vm.allergies}
            onChange={vm.handleInputChange}
            placeholder={"e. g. Tomato, broccoli, etc. "}
          />
        </div>

        <div>
          <Label>Notes</Label>
          <Textarea
            textareaClassName="resize-y min-h-[90px]"
            value={vm.notes}
            onChange={(e) => (vm.notes = e.currentTarget.value)}
            placeholder={"Any additional info about the participant"}
          />
        </div>
        <div className="mb-3">
          {vm.isEdit ? (
            <Button
              className="w-full"
              size={"big"}
              onClick={() => vm.submit(vm.returnToSourcePage)}
              autoLoading
            >
              Save
            </Button>
          ) : (
            <div className="flex flex-col gap-3">
              <Button
                autoLoading
                size={"big"}
                onClick={() => vm.submit(vm.returnToSourcePage)}
              >
                Save
              </Button>
              <Button
                autoLoading
                disabled={vm.updatePhoto.isUploading}
                kind={"text"}
                size={"big"}
                onClick={() => vm.submit(vm.reset)}
              >
                Save & Add Another Participant
              </Button>
            </div>
          )}
        </div>
      </div>
    </PageRoot>
  );
});

export default ParticipantCreateOrEditPage;
