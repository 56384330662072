import React from "react";
import { observer } from "mobx-react-lite";
import useStores from "src/hooks/useStores";
import { QuestionPipelinePage } from "../QuestionPipelinePage/QuestionPipelinePage";
import { startIndexForQuestionSet } from "src/util/questionSetUtils";
import { ROUTES } from "src/stores/RouterStore";
import api from "src/services/api";
import { useSchedulePageVM } from "src/stores/ScheduleStore/SchedulePageVM";
import { defineRoute } from "../../util/typedRouting";
import { SmartFormDTO } from "../../services/api/smartForms";

export const beforeScheduleQuestionsPageRoute = defineRoute<{
  state: {
    smart_forms: SmartFormDTO[];
  };
}>({
  path: "/schedule/before-schedule-question-sets",
  build: (path) => path,
});

export const BeforeScheduleQuestionsPage: React.FC = observer(() => {
  const { routerStore } = useStores();
  const vm = useSchedulePageVM();
  const { state } = beforeScheduleQuestionsPageRoute.useParams();

  return (
    <QuestionPipelinePage
      description={
        "The following forms are required before scheduling. Please fill them out to reserve."
      }
      delegate={{
        startIndexFor: startIndexForQuestionSet,
        onFinish: () => {
          routerStore.navigate(ROUTES.SCHEDULE, {
            replace: true,
            searchParams: {
              action: "submit",
            },
          });
        },
        fetchSmartForms: () =>
          state?.smart_forms
            ? state.smart_forms
            : api.requiredForAttendance.getUnansweredSmartFormsRequiredBeforeSchedule(
                [
                  ...(vm.orderVm.order?.sign_up.schedule ?? []),
                  ...(vm.orderVm.order?.sign_up.buy_then_schedule ?? []),
                ]
              ),
        uploadFile: async (file: File) => {
          return await api.files.uploadFile(file, "END_USER");
        },
      }}
    />
  );
});

export default BeforeScheduleQuestionsPage;
