import React from "react";
import PageHeader from "../../../components/PageHeader";
import PageRoot from "../../../components/PageRoot";
import useStores from "../../../hooks/useStores";
import Policies from "../../../components/Policies";
import { Button, MarkdownText, Modal } from "@sizdevteam1/funjoiner-uikit";
import ParticipantSelect from "../SchedulePage/components/ParticipantSelect";
import cn from "classnames";
import Border from "../../../components/Border";
import CreditRow from "../../../components/CreditRow";
import {
  JoinWaitlistPageVMProvider,
  useJoinWaitlistPageVM,
} from "./JoinWaitlistPageVM";
import { observer } from "mobx-react-lite";
import {
  ClosedRegistrationWithWaitlist,
  IAvailableDaycampProgram,
  IAvailableOvernightProgram,
  TAvailableProgram,
} from "src/services/api/availability";
import { Redirect } from "react-router-dom";
import { ROUTES } from "src/stores/RouterStore";
import { useSchedulePageVM } from "src/stores/ScheduleStore/SchedulePageVM";
import { defineRoute } from "src/util/typedRouting";
import { ProgramInfoCard } from "../../../components/ProgramInfoCard";
import { PaymentMethodPicker } from "../../../components/PaymentMethods/PaymentMethodPicker";
import classNames from "classnames";

interface IJoinWaitlistPageArgs {
  program: TAvailableProgram;
  selectedParticipantIds: number[];
}

export const joinWaitlistRoute = defineRoute<{
  state: {
    args: IJoinWaitlistPageArgs;
  };
}>({
  path: "/schedule/waitlist",
  build: (path) => path,
});

const JoinWaitlistPage = () => {
  const state = joinWaitlistRoute.useParams()?.state;

  if (!state?.args || !isClosedRegistrationWithWaitlist(state.args.program)) {
    return <Redirect to={ROUTES.SCHEDULE} />;
  }

  return (
    <JoinWaitlistPageVMProvider
      program={state.args.program}
      selectedParticipantIds={state.args.selectedParticipantIds}
    >
      <JoinWaitlistPageImpl />
    </JoinWaitlistPageVMProvider>
  );
};

const JoinWaitlistPageImpl = observer(() => {
  const vm = useJoinWaitlistPageVM();
  const schedulePageVm = useSchedulePageVM();

  return (
    <PageRoot>
      <PageHeader
        showBackLink
        onBackLinkClick={() => {
          vm.dispose();
          schedulePageVm.goBackFromWaitlist();
        }}
      >
        Join the Waitlist
      </PageHeader>
      <div className={"space-y-1"}>
        {vm.program.registration.waitlist.requires_payment_information && (
          <div className={"typography-main text-text-color"}>
            By joining the Waitlist you agree to be automatically charged for
            the schedule below when approved
          </div>
        )}
        {vm.program.registration.waitlist.policy && (
          <Button
            onClick={() => (vm.isShowingPolicy = true)}
            kind={"text"}
            className={"!typography-main_sb mt-1 w-fit"}
          >
            <i className={"icon doc-icon icon-lg"} />
            Waitlist Policy
          </Button>
        )}
      </div>
      <ParticipantSelect
        className={classNames(
          (vm.program.registration.waitlist.requires_payment_information ||
            vm.program.registration.waitlist.policy) &&
            "mt-4"
        )}
        selectedParticipants={vm.selectedParticipants}
      />
      <ProgramInfoCard
        className={"mt-4"}
        scheduleSetName={vm.program.schedule_set_name}
        programInfo={{
          ...vm.program,
          program_type_name: vm.program.program_type.name,
        }}
      />
      <RequiredCredits
        program={vm.program}
        quantity={vm.selectedParticipants.length}
        className={"my-8"}
      />
      {vm.program.registration.waitlist.requires_payment_information && (
        <PaymentMethodPicker
          description={
            "Payment information is required. Choose your default payment method below:"
          }
          vm={vm.paymentMethodPickerVm}
        />
      )}
      <Policies className={"mt-auto"} buttonName={"Join Waitlist"} />
      <Button
        className={"mt-4"}
        size={"big"}
        autoLoading
        onClick={vm.submitAndFinish}
      >
        Join Now and Finish
      </Button>
      <Button
        className={"mt-5 mb-3"}
        kind={"text"}
        autoLoading
        onClick={async () => {
          await vm.submit();
          schedulePageVm.availabilityVM.refresh();
          schedulePageVm.goBackFromWaitlist();
        }}
      >
        Join Now and Continue Scheduling
      </Button>
      {vm.isShowingPolicy && (
        <Modal
          onClose={() => (vm.isShowingPolicy = false)}
          headerText={"Waitlist Policy"}
          containerClassName="min-w-[366px] max-w-[414px] w-full"
        >
          <MarkdownText className="typography-main text-text-color">
            {vm.program.registration.waitlist.policy}
          </MarkdownText>
        </Modal>
      )}
    </PageRoot>
  );
});

interface IRequiredCreditsProps {
  className?: string;
  program: IAvailableDaycampProgram | IAvailableOvernightProgram;
  quantity: number;
}

const RequiredCredits: React.FC<IRequiredCreditsProps> = (props) => {
  const { commonStore } = useStores();

  const creditType = Object.values(commonStore.creditTypesById).find(
    (ct) =>
      ct.active_program_type_ids.includes(props.program.program_type.id) &&
      ct.is_program_credit
  );
  if (creditType == null) {
    throw new Error(
      `Failed to find Credit type for program ${props.program.program_type.id}`
    );
  }
  return (
    <div className={cn(props.className)}>
      <div
        className={
          "typography-h3 rounded-t-[10px] bg-on-main-color p-4 text-header-color"
        }
      >
        Required Credits
      </div>
      <CreditRow
        className={"bg-on-main-color py-0"}
        credit_type_id={creditType.id}
        is_program_credit={creditType.is_program_credit}
        name={creditType.name}
        is_free={creditType.is_free}
        quantity={props.quantity}
        price={creditType.price}
      />

      <Border />
    </div>
  );
};

export default JoinWaitlistPage;

function isClosedRegistrationWithWaitlist(
  program: TAvailableProgram
): program is TAvailableProgram & ClosedRegistrationWithWaitlist {
  return (
    program.registration.status === "CLOSED" &&
    program.registration.waitlist !== null
  );
}
