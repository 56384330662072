import { matchPath, useLocation, useParams } from "react-router-dom";
import { RouterStore } from "../stores";

export function defineRoute<T extends GenericPageParams>(routeParams: {
  path: string;
  build: (path: string, params: T["pathParams"]) => string;
}) {
  return {
    path: routeParams.path,
    build: function (params: T): PageConfig {
      const path = routeParams.build(routeParams.path, params.pathParams);

      return {
        path: path,
        searchParams: params.searchParams,
        state: params.state,
        absoluteUrl: (function (): string {
          let url = `${window.location.origin}${path}`;
          if (params.searchParams != null) {
            url += "?" + new URLSearchParams(params.searchParams).toString();
          }

          return url;
        })(),
      };
    },
    useParams: () => usePageParams<T>(),
    getParams: (routerStore: RouterStore): ReturnPageParams<T> => {
      return {
        pathParams: matchPath(window.location.pathname, {
          path: routeParams.path,
        })?.params as T["pathParams"],
        searchParams: routerStore.searchParams,
        state: routerStore.historyState,
      };
    },
    setSearchParams: (
      routerStore: RouterStore,
      params: NonNullable<Partial<T["searchParams"]>>
    ) => {
      for (const [key, value] of Object.entries(params)) {
        routerStore.setSearchParam(key, value as string | undefined, true);
      }
    },
  };
}

export type PageConfig = {
  path: string;
  searchParams: Record<string, string> | undefined;
  state: Record<string, any> | undefined;
  absoluteUrl: string;
};

type GenericPageParams = {
  pathParams?: Record<string, string>;
  searchParams?: Record<string, string>;
  state?: Record<string, any>;
};

export type ReturnPageParams<T extends GenericPageParams> = {
  pathParams: T["pathParams"];
  searchParams: Exclude<Partial<T["searchParams"]>, undefined>;
  state?: Partial<T["state"]>;
};

function usePageParams<K extends GenericPageParams>(): ReturnPageParams<K> {
  const pathParams =
    useParams<K["pathParams"] extends {} ? K["pathParams"] : {}>();

  const { search, state } = useLocation<K["state"]>();
  const searchParams: K["searchParams"] = Object.fromEntries(
    new URLSearchParams(search).entries()
  );

  return {
    pathParams,
    searchParams,
    state,
  };
}
