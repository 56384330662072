import {
  capitalize,
  ExpandableRow,
  PriceWithCents,
} from "@sizdevteam1/funjoiner-uikit";
import cn from "classnames";
import React, { useState } from "react";
import { IFeeInOrder } from "src/services/api/orders";
import { twMerge } from "tailwind-merge";

const Fee = ({ fee, className }: { fee: IFeeInOrder; className?: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  return fee.fee_description ? (
    <ExpandableRow
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      children={
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={twMerge(
            `group typography-label box-border 
             flex w-[366px] cursor-pointer items-center justify-between gap-2 px-4 py-1 text-gray-text-color`,
            className
          )}
        >
          <div className="flex items-center">
            {" "}
            <span>{capitalize(fee.fee_name)}</span>
            <i
              className={cn(
                "icon  chevron-down-icon   ml-[2px] !h-4  !w-4 transition-all",
                isOpen && "rotate-180"
              )}
            />{" "}
          </div>
          <div className="text-text-color">
            <PriceWithCents
              amount={fee.amount}
              prefix="$"
              typography="typography-label"
            />
          </div>
        </div>
      }
      expand={
        <div className="typography-small__t mt-2 w-[318px] break-words  bg-table-row-color px-6 py-3 text-text-color">
          {fee.fee_description}
        </div>
      }
    />
  ) : (
    <div
      className={twMerge(
        "typography-label flex items-center justify-between gap-2 px-4 py-1 text-gray-text-color",
        className
      )}
    >
      <span> {capitalize(fee.fee_name)}</span>
      <span className="text-text-color">
        <PriceWithCents
          amount={fee.amount}
          prefix="$"
          typography="typography-label"
        />
      </span>
    </div>
  );
};

export default Fee;
