import React from "react";
import { observer } from "mobx-react-lite";
import {
  ApplicationProcessPageVmProvider,
  useApplicationProcessPageVm,
} from "./ApplicationProcessPageVm";
import { defineRoute } from "../../util/typedRouting";
import PageRoot from "../../components/PageRoot";
import { IStudentDTO } from "../../services/api";
import PageHeader from "../../components/PageHeader";
import cn from "classnames";
import useStores from "../../hooks/useStores";
import {
  Avatar,
  Button,
  Loader,
  MarkdownText,
  PriceWithCents,
  Splash,
} from "@sizdevteam1/funjoiner-uikit";
import { PieChartCompletenessCard } from "../../components/PieChartCompletenessCard";
import { ProgramInfoCard } from "../../components/ProgramInfoCard";
import CreditRow from "../../components/CreditRow";
import Border from "../../components/Border";
import Fee from "../Checkout&Payment/Checkout/components/Fee";
import StickyFooter from "../../components/StickyFooter";
import Policies from "../../components/Policies";
import { StrikethroughDivider } from "../../components/StrikethroughDivider";
import { PaymentMethodPicker } from "../../components/PaymentMethods/PaymentMethodPicker";
import styles from "./ApplicationProcessPage.module.scss";

export const applicationProcessPageRoute = defineRoute<{
  searchParams: {
    documentId?: string;
    programId: string;
    scheduleSetId: string;
    selectedParticipantIds: string;
  };
}>({
  path: "/application-process/",
  build: (path, params) => path,
});

interface IProps {}

const ApplicationProcessPage: React.FC<IProps> = observer((props) => {
  return (
    <ApplicationProcessPageVmProvider>
      <ApplicationProcessPageImpl {...props} />
    </ApplicationProcessPageVmProvider>
  );
});

const ApplicationProcessPageImpl: React.FC<IProps> = observer((props) => {
  const vm = useApplicationProcessPageVm();
  if (vm.isLoading || vm.calculatedOrder == null) {
    return <Splash pathToAnimation={"/splash.json"} />;
  }

  return (
    <>
      <PageRoot>
        <PageHeader
          showBackLink
          className={cn("typography-h1  text-header-color")}
        >
          Application Process
        </PageHeader>

        {vm.applicationSettings.description && (
          <MarkdownText className={"typography-main text-text-color"}>
            {vm.applicationSettings.description}
          </MarkdownText>
        )}
        <div className={"mt-3 flex flex-col gap-6"}>
          <ApplicationPolicy />
          <StudentsToApply />
          <StrikethroughDivider>Schedule Details</StrikethroughDivider>
          <ProgramInfoCard
            scheduleSetName={vm.scheduleSet.name}
            programInfo={{
              ...vm.program,
              program_type_name: vm.program.program_type_name,
            }}
          />
          {vm.displayPrice && <RequiredCredits />}
          {vm.isPaymentInformationRequired && (
            <PaymentMethodPicker
              vm={vm.paymentMethodPickerVm}
              description={
                "Payment information is required. Choose your default payment method below:"
              }
            />
          )}
          <div className={"h-[46px]"} />
        </div>
      </PageRoot>
      <BottomButton />
    </>
  );
});

const BottomButton = observer(() => {
  const vm = useApplicationProcessPageVm();

  return (
    <StickyFooter className="py-3 px-6">
      <Policies buttonName={"Apply Now"} />
      <Button
        disabled={vm.isApplyButtonDisabled}
        autoLoading
        onClick={vm.confirmApplication}
        size="big"
        className="mx-auto mt-3 w-full"
      >
        Apply Now
      </Button>
    </StickyFooter>
  );
});

const RequiredCredits = observer(({ className }: { className?: string }) => {
  const vm = useApplicationProcessPageVm();
  return vm.isCalculatingOrder ? (
    <Loader />
  ) : (
    <div className={cn(className)}>
      <div
        className={
          "typography-h3 rounded-t-[10px] bg-on-main-color p-4 text-header-color"
        }
      >
        Required Credits
      </div>
      {vm.calculatedOrder?.items.map(({ credit_type, quantity }) => {
        return (
          <CreditRow
            is_free={credit_type.is_free}
            className={"bg-on-main-color py-0"}
            credit_type_id={credit_type.id}
            is_program_credit={credit_type.is_program_credit}
            name={credit_type.name}
            quantity={quantity}
            price={credit_type.price}
          />
        );
      })}
      <div className={"flex flex-col items-stretch bg-on-main-color pt-4 "}>
        <div className={"h-[1px] bg-separator-color"} />
        {vm.calculatedOrder?.own_fees.map((fee) => (
          <Fee key={fee.id} className={"mt-3"} fee={fee} />
        ))}
        <div className={"mt-3 flex flex-row justify-between px-4"}>
          <div className={"typography-main_sb"}>Final Price</div>
          <PriceWithCents
            amount={vm.calculatedOrder?.final_price ?? 0}
            prefix="$"
            typography="typography-h2"
          />
        </div>
      </div>
      <Border />
    </div>
  );
});
const StudentsToApply = observer(({ className }: { className?: string }) => {
  const { selectedParticipants, haveAllDocsAndFormsCompleted } =
    useApplicationProcessPageVm();
  return (
    <div className={cn("flex flex-col", className)}>
      <div className={"typography-h2 text-text-color"}>
        {haveAllDocsAndFormsCompleted
          ? "Ready to Apply Now"
          : "Required to Apply Now"}
      </div>
      {haveAllDocsAndFormsCompleted && (
        <div className={"typography-main mt-3 text-gray-text-color"}>
          Check Application Details before submitting and click "Apply Now"
        </div>
      )}
      <div className={"mt-6 flex flex-col gap-6"}>
        {selectedParticipants.map((student) => (
          <StudentInfo key={student.id} student={student} />
        ))}
      </div>
    </div>
  );
});

const StudentInfo = observer(({ student }: { student: IStudentDTO }) => {
  const vm = useApplicationProcessPageVm();
  const numberOfBlocks =
    +vm.isDocumentsPieVisible(student.id) + +vm.isFormsPieVisible(student.id);
  return (
    <div className={"flex flex-col gap-4"}>
      {numberOfBlocks === 2 && (
        <div className={"flex flex-row items-center gap-3"}>
          <Avatar person={student} avatarSize={"44px"} />
          <div className={"typography-main_sb flex-1 text-text-color"}>
            {student.full_name}
          </div>
        </div>
      )}
      <div className={cn("grid grid-cols-2 gap-4")}>
        {numberOfBlocks === 1 && (
          <div className={"flex flex-row items-center gap-3"}>
            <Avatar person={student} avatarSize={"44px"} />
            <div className={"typography-main_sb flex-1 text-text-color"}>
              {student.full_name}
            </div>
          </div>
        )}
        {vm.isDocumentsPieVisible(student.id) && (
          <PieChartCompletenessCard
            name={"Docs"}
            className={cn(
              "relative",
              vm.highlightedPieBlocks.includes(student.id + "_docs") &&
                styles.highlighted
            )}
            ref={vm.getChartRef(student.id, "docs")}
            isLoading={vm.isPollingDocuments(student.id)}
            percentage={vm.documentCompletenessPercentage(student.id)}
            count={vm.unsignedDocumentCount(student.id)}
            renderNavigateButton={() => (
              <Button
                autoLoading
                disabled={vm.isPollingDocuments(student.id)}
                onClick={() => vm.navigateToIncompleteDocument(student.id)}
                className="!typography-h4"
                kind="text"
              >
                <i className="icon doc-icon icon-lg" />
                Sign Now
              </Button>
            )}
          />
        )}
        {vm.isFormsPieVisible(student.id) && (
          <PieChartCompletenessCard
            name={"Forms"}
            className={cn(
              "relative",
              vm.highlightedPieBlocks.includes(student.id + "_forms") &&
                styles.highlighted
            )}
            ref={vm.getChartRef(student.id, "forms")}
            percentage={vm.smartFormCompletenessPercentage(student.id)}
            count={vm.unansweredSmartFormCount(student.id)}
            renderNavigateButton={() => (
              <Button
                onClick={() => vm.navigateToIncompleteSmartForms(student.id)}
                className="!typography-h4"
                kind="text"
              >
                <i className="icon smart-forms-icon icon-lg" />
                Fill Now
              </Button>
            )}
          />
        )}
      </div>
    </div>
  );
});

const ApplicationPolicy = observer(({ className }: { className?: string }) => {
  const { commonStore } = useStores();
  const applicationPolicy =
    commonStore.companyProfile.documents.application_policy;

  if (!applicationPolicy) {
    return null;
  }

  return (
    <div className={cn(className)}>
      <a
        className="group typography-main flex items-center gap-1"
        target={"_blank"}
        href={applicationPolicy}
        rel="noreferrer"
      >
        <i
          className={
            "icon doc-icon bg-main-color group-hover:bg-dark-main-color"
          }
        />
        Application Policy
      </a>
    </div>
  );
});
export default ApplicationProcessPage;
