import ReceiptSection from "./PaymentSuccessPage/ReceiptSection";
import { defineRoute } from "../../util/typedRouting";
import { IScheduleAndPayOrderDTO } from "../../services/api/orders";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../stores/RouterStore";
import PageRoot from "../../components/PageRoot";
import React from "react";
import Success from "../../assets/imgs/payment-success.svg";
import { Button, Image } from "@sizdevteam1/funjoiner-uikit";
import { downloadFile } from "../../util/downloadFile";
import api from "../../services/api";
import useStores from "../../hooks/useStores";

export const invoiceSentSuccessRoute = defineRoute<{
  state: {
    order: IScheduleAndPayOrderDTO;
  };
}>({
  path: "/invoice_sent_success",
  build: (path) => path,
});
const InvoiceSentSuccessPage = () => {
  const { routerStore } = useStores();
  const { state } = invoiceSentSuccessRoute.useParams();

  if (state?.order == null) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }
  const order = state.order;

  return (
    <PageRoot>
      <div className={"flex flex-col gap-6"}>
        <div>
          <ReceiptSection
            order={state.order}
            paymentOption={{
              type: "invoice",
            }}
            titleNode={
              <div className={"flex flex-col gap-4"}>
                <Image
                  imageClassName="h-[58px] w-[58px]"
                  src={Success}
                  alt="success"
                />
                <div className={"typography-h2 text-header-color"}>
                  Invoice Sent!
                </div>
              </div>
            }
          />
        </div>
        <div
          className={
            "flex flex-row items-center justify-center overflow-clip rounded-[10px] bg-on-main-color p-5 shadow-big"
          }
        >
          <Button
            kind={"text"}
            autoLoading={true}
            onClick={() =>
              downloadFile({
                apiCall: () => api.invoices.downloadInvoicePdf(order.id),
                fileName: `invoice-${order.id}.pdf`,
              })
            }
          >
            <i className={"icon invoice-icon icon-lg bg-main-color"} /> Download
            PDF Invoice
          </Button>
        </div>
        <Button
          kind={"text"}
          onClick={() => routerStore.navigate(ROUTES.DASHBOARD)}
        >
          Back to Dashboard
        </Button>
      </div>
    </PageRoot>
  );
};

export default InvoiceSentSuccessPage;
