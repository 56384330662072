import { ISOString, TimeString } from "@sizdevteam1/funjoiner-uikit";
import dayjs from "dayjs";

interface MonthWithSessions<T extends DateTimeObject> {
  startOfMonth: dayjs.Dayjs;
  endOfMonth: dayjs.Dayjs;
  sessions: T[];
}
const getMonthStartAndEnd = (date: string) => {
  const startOfMonth = dayjs(date).startOf("month");
  const endOfMonth = dayjs(date).endOf("month");
  return { startOfMonth, endOfMonth };
};

export const getMonthsWithSessions = <T extends DateTimeObject>(
  sessions: T[]
) => {
  const monthsWithSessions: MonthWithSessions<T>[] = [];

  sessions.forEach((s) => {
    const { startOfMonth, endOfMonth } = getMonthStartAndEnd(s.date);
    let monthIndex = monthsWithSessions.findIndex((month) =>
      month.startOfMonth.isSame(startOfMonth)
    );

    if (monthIndex === -1) {
      monthsWithSessions.push({ startOfMonth, endOfMonth, sessions: [s] });
      monthIndex = monthsWithSessions.length - 1;
    } else {
      if (endOfMonth.isAfter(monthsWithSessions[monthIndex].endOfMonth)) {
        monthsWithSessions[monthIndex].endOfMonth = endOfMonth;
      }

      monthsWithSessions[monthIndex].sessions.push(s);
    }
  });
  return monthsWithSessions;
};

export interface DateTimeObject {
  date: ISOString;
  start_time: TimeString;
  end_time: TimeString;
}

interface WeekWithSessions<T extends DateTimeObject> {
  startOfWeek: dayjs.Dayjs;
  endOfWeek: dayjs.Dayjs;
  sessions: T[];
}
const getWeekStartAndEnd = (date: string) => {
  const startOfWeek = dayjs(date).startOf("week");
  const endOfWeek = dayjs(date).endOf("week");
  return { startOfWeek, endOfWeek };
};

export const getWeeksWithSessions = <T extends DateTimeObject>(
  sessions: T[]
) => {
  const weeksWithSessions: WeekWithSessions<T>[] = [];

  sessions.forEach((s) => {
    const { startOfWeek, endOfWeek } = getWeekStartAndEnd(s.date);

    let weekIndex = weeksWithSessions.findIndex((week) =>
      week.startOfWeek.isSame(startOfWeek)
    );

    if (weekIndex === -1) {
      weeksWithSessions.push({ startOfWeek, endOfWeek, sessions: [s] });
      weekIndex = weeksWithSessions.length - 1;
    } else {
      if (endOfWeek.isAfter(weeksWithSessions[weekIndex].endOfWeek)) {
        weeksWithSessions[weekIndex].endOfWeek = endOfWeek;
      }

      weeksWithSessions[weekIndex].sessions.push(s);
    }
  });

  return weeksWithSessions;
};

interface DaysWithSessions<T extends DateTimeObject> {
  day: dayjs.Dayjs;
  sessions: T[];
}

export const getDaysWithSessions = <T extends DateTimeObject>(
  sessions: T[]
) => {
  const daysWithSessions: DaysWithSessions<T>[] = [];

  sessions.forEach((s) => {
    const day = dayjs(s.date);

    let dayIndex = daysWithSessions.findIndex((d) => d.day.isSame(day));

    if (dayIndex === -1) {
      daysWithSessions.push({ day: day, sessions: [s] });
    } else {
      daysWithSessions[dayIndex].sessions.push(s);
    }
  });

  return daysWithSessions;
};
