import React, { useEffect, useMemo } from "react";
import { action, computed, makeObservable, observable } from "mobx";
import api from "../../../services/api";
import RouterStore, { ROUTES } from "../../../stores/RouterStore";
import useStores from "../../../hooks/useStores";
import useVM from "../../../hooks/useVM";
import {
  CustomerStore,
  PaymentMethodStore,
  PaymentStore,
} from "../../../stores";
import { StripeElements } from "@stripe/stripe-js";
import { useElements } from "@stripe/react-stripe-js";
import notificator from "../../../services/systemNotifications/notificationCenterService";
import {
  ClosedRegistrationWithWaitlist,
  TAvailableProgram,
} from "src/services/api/availability";
import { PaymentMethodPickerVm } from "../../../components/PaymentMethods/PaymentMethodPickerVm";

class JoinWaitlistPageVM {
  dispose = () => {
    this.paymentMethodPickerVm.dispose();
  };

  constructor(
    public program: TAvailableProgram & ClosedRegistrationWithWaitlist,
    private readonly selectedParticipantIds: number[],
    private customerStore: CustomerStore,
    private paymentStore: PaymentStore,
    private paymentMethodStore: PaymentMethodStore,
    private routerStore: RouterStore,
    private elements: StripeElements | null
  ) {
    this.dispose.bind(this);
    makeObservable(this);
  }

  get selectedParticipants() {
    return this.customerStore.studentsWithCustomerAsParticipant.filter((e) =>
      this.selectedParticipantIds.includes(e.id)
    );
  }
  paymentMethodPickerVm = new PaymentMethodPickerVm(
    {
      isInvoiceEnabled: computed(() => false),
      applePay: undefined,
    },
    this.elements,
    this.paymentStore,
    this.paymentMethodStore
  );

  @observable
  isShowingPolicy = false;

  @action.bound
  async submitAndFinish() {
    await this.submit();
    this.routerStore.navigate(ROUTES.THANKYOU, {
      searchParams: { waitlist: "true" },
    });
  }

  async submit() {
    if (this.program.registration.waitlist.requires_payment_information) {
      await this.paymentMethodPickerVm.confirm(
        this.paymentMethodPickerVm.selectedPaymentMethod
      );
    }

    try {
      await api.waitlists.addStudents(this.program.schedule_set_id, {
        program_id: this.program.id,
        student_ids: this.selectedParticipants.map((e) => e.id),
      });
      await this.customerStore.loadWaitlistRecords();
    } catch (e) {
      //fixme: should use generic method to show error messages https://funjoiner.atlassian.net/browse/FJ-1302
      notificator.error("Error", e);
      throw e;
    }
  }
}

const ctx = React.createContext<JoinWaitlistPageVM | null>(null);

export const JoinWaitlistPageVMProvider: React.FC<{
  program: TAvailableProgram & ClosedRegistrationWithWaitlist;
  selectedParticipantIds: number[];
}> = ({ program, selectedParticipantIds, children }) => {
  const { routerStore, customerStore, paymentStore, paymentMethodStore } =
    useStores();
  const elements = useElements();
  const vm = useMemo(
    () =>
      new JoinWaitlistPageVM(
        program,
        selectedParticipantIds,
        customerStore,
        paymentStore,
        paymentMethodStore,
        routerStore,
        elements
      ),
    [
      program,
      selectedParticipantIds,
      customerStore,
      paymentStore,
      paymentMethodStore,
      routerStore,
      elements,
    ]
  );
  useEffect(() => () => vm.dispose(), [vm]);
  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useJoinWaitlistPageVM = () => useVM(ctx);
