import React, { useEffect, useMemo } from "react";
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  toJS,
} from "mobx";
import useStores from "../../hooks/useStores";
import useVM from "../../hooks/useVM";
import { applicationPageRoute } from "./ApplicationPage";
import {
  APPLICATION_STATUSES,
  ApplicationDTO,
} from "../../services/api/availability";
import {
  CommonStore,
  CustomerStore,
  DocumentsStore,
  RouterStore,
} from "../../stores";
import { applicationsAndWaitlistsPageRoute } from "../ApplicationsAndWaitlistsPage/ApplicationsAndWaitlistsPage";
import { computedFn } from "mobx-utils";
import { smartFormPipelinePageRoute } from "../SmartFormPipelinePage/SmartFormPipelinePage";
import { InvoiceVm } from "../../models/InvoiceVm";
import { withNotification } from "../../util/withNotification";

export class ApplicationPageVm {
  constructor(
    private applicationId: string,
    private documentId: string | undefined,
    private customerStore: CustomerStore,
    private commonStore: CommonStore,
    private routerStore: RouterStore,
    private documentsStore: DocumentsStore
  ) {
    makeObservable(this);
    this.invoiceVm = new InvoiceVm(() => this._navigateToApplicationList());
    this._init();
    this._disposers.push(
      reaction(
        () => this._application,
        (application) => {
          if (
            application == null ||
            !APPLICATION_STATUSES.includes(application.status)
          ) {
            this._navigateToApplicationList();
          }
        },
        { fireImmediately: true }
      )
    );
  }

  invoiceVm: InvoiceVm;

  @action
  private _navigateToApplicationList = () => {
    this.routerStore.navigateToRoute(
      applicationsAndWaitlistsPageRoute.build({}),
      { replace: true }
    );
  };

  @computed
  get smartForms() {
    return this.application?.smart_forms ?? [];
  }

  @computed
  get documents() {
    return this.application?.documents ?? [];
  }

  @computed
  private get _application(): ApplicationDTO | undefined {
    return this.customerStore.applications.find(
      (application) => application.id === this.applicationId
    );
  }

  @computed
  get application() {
    return this._application;
  }

  private _disposers = Array<() => void>();

  dispose = () => {
    this._disposers.forEach((d) => d());
  };

  navigateToDocumentSignature = async (documentId: string) => {
    await this.documentsStore.signDocument(
      documentId,
      (document) =>
        applicationPageRoute.build({
          searchParams: {
            document_id: document.id,
          },
          pathParams: {
            application_id: this.applicationId,
          },
        }).absoluteUrl
    );
  };

  navigateToSmartForm = async (smartFormId: string) => {
    const smartForm = this.smartForms.find((s) => s.id === smartFormId);
    if (smartForm == null || smartForm.status !== "INCOMPLETE") {
      return;
    }

    this.routerStore.navigateToRoute(
      smartFormPipelinePageRoute.build({
        state: { smartForms: [toJS(smartForm)] },
      })
    );
  };

  @observable
  withdrawalModal: { onConfirm: () => void; onClose: () => void } | null = null;

  withdrawApplication = async () => {
    this.withdrawalModal = {
      onConfirm: async () => {
        await withNotification(async () => {
          await this.customerStore.withdrawApplication(this.applicationId);
          this._navigateToApplicationList();
        });
        this.withdrawalModal = null;
      },
      onClose: () => {
        this.withdrawalModal = null;
      },
    };
  };

  archiveApplication = async () => {
    await withNotification(async () => {
      await this.customerStore.archiveApplication(this.applicationId);
      this._navigateToApplicationList();
    });
  };

  isPollingDocument = computedFn((documentId: string) => {
    return this.documentsStore.isWaitingForDocumentUpdate(documentId);
  });

  private async _init() {
    if (this.documentId != null) {
      applicationPageRoute.setSearchParams(this.routerStore, {
        document_id: undefined,
      });
      try {
        await this.documentsStore.waitUntilDocumentIsSigned(this.documentId);
        await this.customerStore.loadApplications();
      } catch (e) {}
    }
  }

  openApplicationPolicy = () => {
    if (!this.applicationPolicy) {
      return;
    }
    window.open(this.applicationPolicy, "_blank");
  };
  get applicationPolicy() {
    return this.commonStore.companyProfile.documents.application_policy;
  }
}

const ctx = React.createContext<ApplicationPageVm | null>(null);

export const ApplicationPageVmProvider: React.FC<{}> = ({ children }) => {
  const {
    pathParams: { application_id },
    searchParams: { document_id },
  } = applicationPageRoute.useParams();

  const { customerStore, documentsStore, commonStore, routerStore } =
    useStores();
  const vm = useMemo(
    () =>
      new ApplicationPageVm(
        application_id,
        document_id,
        customerStore,
        commonStore,
        routerStore,
        documentsStore
      ),
    [
      application_id,
      commonStore,
      customerStore,
      document_id,
      documentsStore,
      routerStore,
    ]
  );
  useEffect(() => () => vm.dispose(), [vm]);
  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useApplicationPageVm = () => useVM(ctx);
