import React from "react";
import CardsListItem from "src/components/cards/CardsListItem";
import { ICardDTO } from "../../../services/api";
import PageRoot from "../../../components/PageRoot";
import { LoadingRoller, PriceWithCents } from "@sizdevteam1/funjoiner-uikit";

interface IProps {
  card: ICardDTO;
  amount: number;
}

const ProcessingPayment: React.FC<IProps> = ({ card, amount }) => {
  return (
    <PageRoot
      className={"flex flex-col items-stretch justify-center text-center"}
    >
      <LoadingRoller />
      <Title>Waiting for payment</Title>
      <Subtitle>We are confirming your purchase with Stripe...</Subtitle>
      <CardsListItem
        className="sticky bottom-3"
        data={card}
        trailing={
          <div className={"typography-h3 ml-2"}>
            <PriceWithCents
              amount={amount}
              prefix="$"
              typography="typography-h3"
            />
          </div>
        }
      />
    </PageRoot>
  );
};

const Title: React.FC = ({ children }) => (
  <div className={"text-h1 mt-7 mb-4 text-text-color"}>{children}</div>
);

const Subtitle: React.FC = ({ children }) => (
  <div className={"text-main mb-auto text-gray-text-color"}>{children}</div>
);

export default ProcessingPayment;
