import React, { forwardRef } from "react";
import PieChart from "../pages/DashboardPage/components/PieChart/PieChart";
import { Loader } from "@sizdevteam1/funjoiner-uikit";
import cn from "classnames";

export const PieChartCompletenessCard = forwardRef<
  HTMLDivElement,
  {
    className?: string;
    name: string;
    percentage: number;
    count: number;
    renderNavigateButton: () => React.ReactNode;
    isLoading?: boolean;
  }
>(
  (
    {
      name,
      percentage,
      className,
      count,
      renderNavigateButton,
      isLoading = false,
    },
    ref
  ) => {
    const isCompleted = percentage === 100;
    return (
      <div
        ref={ref}
        className={cn(
          "flex  max-w-[175px] justify-between rounded-lg bg-on-main-color px-[10px] py-3 shadow-big",
          className
        )}
      >
        <PieChart percentage={percentage} />
        <div className="flex flex-col justify-between">
          <div className="typography-label flex items-center justify-end gap-[2px] text-gray-text-color">
            <div>{name}</div>
            {!isCompleted && (
              <>
                <div>•</div>
                {isLoading ? (
                  <Loader className={"!m-0 !h-4 !w-4 !p-0"} />
                ) : (
                  <span className="text-text-color">{count}</span>
                )}
              </>
            )}
          </div>
          <div className="flex gap-[2px]">
            {isCompleted ? (
              <span className="typography-label  pb-[4.5px] text-icon-dark-green-color">
                Completed!
              </span>
            ) : (
              renderNavigateButton()
            )}
          </div>
        </div>
      </div>
    );
  }
);
