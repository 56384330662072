import React, { useLayoutEffect, useRef, useState } from "react";
import PageHeader from "../../components/PageHeader";
import PageRoot from "../../components/PageRoot";
import styles from "./SelectFunboxPage.module.scss";
import { observer } from "mobx-react-lite";

import {
  SelectFunboxPageVMProvider,
  useSelectFunboxPageVM,
} from "./SelectFunboxPageVM";
import {
  Button,
  ExpandableRow,
  Image,
  MarkdownText,
  SegmentedControl,
  Select,
} from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { IFunboxDTO, ILocationDTO } from "src/services/api";
import { ROUTES } from "../../stores/RouterStore";
import useStores from "../../hooks/useStores";
import FunboxPlaceholderImage from "src/components/FunboxPlaceholderImage";

const SelectFunboxPage = () => {
  return (
    <SelectFunboxPageVMProvider>
      <SelectFunboxPageImpl />
    </SelectFunboxPageVMProvider>
  );
};

const SelectFunboxPageImpl = observer(() => {
  const vm = useSelectFunboxPageVM();
  const { routerStore } = useStores();
  return (
    <PageRoot>
      <PageHeader className="text-center">{vm.companyName}</PageHeader>
      <div className="typography-main text-gray-text-color">
        Select a {vm.selectedTab === "location" ? "Location" : "FUNBOX"} to
        proceed:
      </div>
      <SegmentedControl
        className="mt-4"
        options={vm.tabOptions}
        selected={vm.selectedTab}
        onSelect={vm.selectTab}
      />
      {vm.atLeastOneLocationFolderExist && (
        <Select
          className="mt-4"
          inputContainerClassName="!pl-[26px]"
          renderSelected={() => (
            <div
              className={classNames(
                "typography-h2 flex w-full justify-center text-center [&>div]:text-header-color [&>div>i]:hidden",
                vm.selectedLocationFolderOption.id === "all" && "mr-[30px]"
              )}
            >
              {vm.selectedLocationFolderOption.name}
            </div>
          )}
          options={vm.locationFolderOptions}
          onSelect={vm.selectLocationFolderOption}
        />
      )}
      <div className="my-4 flex flex-col gap-5">
        {vm.selectedTab === "funbox" &&
          vm.funboxesByFolder.map((f) => (
            <StackedTile
              key={f.id}
              isExpanded={vm.selectedFunboxId === f.id}
              onMainTileClick={() => vm.selectFunboxAndReturnToSourcePage(f)}
              expandedTileFixedHeight={100}
              mainTile={
                <FunboxMainTile
                  funbox={f}
                  isSelected={vm.selectedFunboxId === f.id}
                />
              }
              additionalTiles={f.active_locations.map((l) => (
                <LocationExpandedTile
                  key={l.id}
                  location={l}
                  selectLocation={() => vm.selectLocation(l.id)}
                  isSelected={f.id === vm.selectedFunboxId}
                />
              ))}
            />
          ))}
        {vm.hasFunboxes &&
          vm.selectedTab === "funbox" &&
          vm.funboxesByFolder.length === 0 && (
            <div className="flex flex-col gap-2 rounded-[10px] bg-card-color px-4 py-8 shadow-big">
              <div className="typography-main_sb text-center text-gray-text-color">
                No FUNBOX in this Folder
              </div>
              <Button
                onClick={() =>
                  vm.selectLocationFolderOption({
                    name: <div>All Locations</div>,
                    id: "all",
                  })
                }
                kind="text"
              >
                See All Locations
              </Button>
            </div>
          )}
        {vm.selectedTab === "location" &&
          vm.locationsWithFunboxesByFolder.map((l) => (
            <StackedTile
              key={l.id}
              isExpanded={vm.selectedLocationId === l.id}
              onMainTileClick={() => vm.selectLocation(l.id)}
              expandedTileFixedHeight={120}
              mainTile={
                <LocationMainTile
                  isSelected={vm.selectedLocationId === l.id}
                  location={l}
                />
              }
              additionalTiles={l.funboxes.map((f) => (
                <FunboxExpandedTile
                  key={f.id}
                  selectFunbox={vm.selectFunboxAndReturnToSourcePage}
                  funbox={f}
                />
              ))}
            />
          ))}
        {vm.hasFunboxes &&
          vm.selectedTab === "location" &&
          vm.locationsWithFunboxesByFolder.length === 0 && (
            <div className="flex flex-col gap-2 rounded-[10px] bg-card-color px-4 py-8 shadow-big">
              <div className="typography-main_sb text-center text-gray-text-color">
                No Locations in this Folder
              </div>
              <Button
                onClick={() =>
                  vm.selectLocationFolderOption({
                    name: <div>All Locations</div>,
                    id: "all",
                  })
                }
                kind="text"
              >
                See All Locations
              </Button>
            </div>
          )}
        {!vm.hasFunboxes && (
          <div className="flex flex-col gap-2 rounded-[10px] bg-card-color px-4 py-8 shadow-big">
            <div className="typography-main_sb text-center text-gray-text-color">
              No FUNBOX or Location
            </div>
            <Button
              onClick={() =>
                routerStore.navigate(ROUTES.DASHBOARD, { replace: true })
              }
              kind="text"
            >
              Go to Dashboard
            </Button>
          </div>
        )}
      </div>
    </PageRoot>
  );
});

const FunboxExpandedTile = ({
  funbox,
  selectFunbox,
}: {
  funbox: IFunboxDTO;
  selectFunbox: (funbox: IFunboxDTO) => void;
}) => {
  return (
    <div
      onClick={() => selectFunbox(funbox)}
      className={classNames(
        "box-border  flex w-[350px]  cursor-pointer gap-4 rounded-[10px]  bg-on-main-color p-4"
      )}
    >
      {funbox.thumbnail ? (
        <Image
          className="!h-[88px] !w-[88px] flex-shrink-0"
          src={funbox.thumbnail}
          imageClassName=" object-cover rounded-[8px]"
        />
      ) : (
        <FunboxPlaceholderImage
          containerClassName="h-[88px] min-w-[88px] w-[88px]"
          iconClassName="w-[54px] h-[54px]"
        />
      )}
      <div className="flex w-full flex-col gap-1">
        <div className="typography-main_sb flex  gap-1  text-header-color ">
          <i className="icon funbox-icon bg-main-color" />
          <div className="max-w-[160px] overflow-hidden text-ellipsis whitespace-nowrap">
            {funbox.name}
          </div>
          <i className="icon forward-icon ml-auto bg-main-color" />
        </div>
        <div className="typography-small__t h-[60px] w-[198px]  overflow-hidden whitespace-pre-line  break-words  text-gray-text-color">
          {funbox.requirements}
        </div>
      </div>
    </div>
  );
};

const FunboxMainTile = ({
  funbox,
  isSelected,
}: {
  funbox: IFunboxDTO;
  isSelected: boolean;
}) => {
  return (
    <div
      className={classNames(
        "box-border w-[366px] cursor-pointer  rounded-[10px]   shadow-big ",
        isSelected && styles.selectedBorder
      )}
    >
      {funbox.description.length > 0 ? (
        <ExpandableRow
          className={classNames("!transition-none")}
          isOpen={isSelected}
          children={
            <div
              className={twMerge(
                "box-border flex gap-4 bg-on-main-color p-4 transition-all ease-in-out ",
                isSelected ? "rounded-t-[10px]" : "rounded-[10px]"
              )}
            >
              {funbox.thumbnail ? (
                <Image
                  className="z-20  !h-[120px] !w-[120px] shrink-0"
                  imageClassName="rounded-[8px] fit-contain"
                  src={funbox.thumbnail}
                />
              ) : (
                <FunboxPlaceholderImage />
              )}

              <div className="flex flex-col">
                <div className="typography-h2 w-[198px] overflow-hidden text-ellipsis whitespace-nowrap text-text-color">
                  {funbox.name}
                </div>
                <div className="typography-small__t mt-3  h-[60px] overflow-hidden whitespace-pre-line text-gray-text-color">
                  {funbox.requirements}
                </div>
                <Button
                  className="!typography-label mt-2 !justify-start"
                  kind="text"
                >
                  Details & Locations
                  <i
                    className={classNames(
                      "icon chevron-down-icon ml-[2px] transition-all",
                      isSelected && "rotate-180"
                    )}
                  />
                </Button>
              </div>
            </div>
          }
          expand={
            <div
              className={
                "typography-small__t box-border min-h-[152px] rounded-b-[10px] bg-table-row-color p-4 text-gray-text-color"
              }
            >
              <MarkdownText>{funbox.description}</MarkdownText>
            </div>
          }
        />
      ) : (
        <div
          className={twMerge(
            "box-border flex  gap-4 bg-on-main-color p-4 shadow-big transition-all ease-in-out ",
            "rounded-[10px]"
          )}
        >
          {funbox.thumbnail ? (
            <Image
              className="!h-[120px]  !w-[120px] shrink-0 "
              imageClassName="rounded-[8px] fit-contain"
              src={funbox.thumbnail}
            />
          ) : (
            <FunboxPlaceholderImage />
          )}

          <div className="flex flex-col">
            <div className="typography-h2 w-[198px] overflow-hidden text-ellipsis whitespace-nowrap text-text-color">
              {funbox.name}
            </div>
            <div className="typography-small__t mt-3  h-[60px] overflow-hidden whitespace-pre-line text-gray-text-color">
              {funbox.requirements}
            </div>
            <Button className="!justify-start" kind="text">
              Details & Locations
              <i
                className={classNames(
                  "icon chevron-down-icon ml-[2px] transition-all",
                  isSelected && "rotate-180"
                )}
              />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const LocationMainTile = ({
  location,
  isSelected,
  className,
}: {
  location: ILocationDTO;
  isSelected: boolean;
  className?: string;
}) => {
  return (
    <div
      className={twMerge(
        "box-border flex  min-h-[120px] w-[366px] gap-3 text-ellipsis rounded-[10px] bg-on-main-color p-5 shadow-big",
        className,
        isSelected && styles.selectedBorder
      )}
    >
      <i className="icon location-color-icon h-8 w-8 bg-main-color" />
      <div className="flex flex-col gap-1">
        <div className="typography-h3 text-header-color">{location.name}</div>
        <div className="typography-small__t text-gray-text-color">
          {location.address}
        </div>
      </div>
    </div>
  );
};

const LocationExpandedTile = ({
  location,
  selectLocation,
  isSelected,
}: {
  location: ILocationDTO;
  selectLocation: () => void;
  isSelected: boolean;
}) => {
  return (
    <div
      onClick={selectLocation}
      className={classNames(
        `box-border flex h-[100px] !w-[350px] cursor-pointer flex-col justify-between gap-[2px]
         overflow-hidden rounded-[10px] bg-on-main-color p-4`,
        isSelected && "border-[1px] border-solid border-separator-color"
      )}
    >
      <div className="flex">
        <i className="icon locations-icon bg-main-color" />
        <div className="typography-main_sb ml-1 text-header-color">
          {location.name}
        </div>
        <i className="icon forward-icon ml-auto bg-main-color" />
      </div>
      <div className="typography-small__t overflow-hidden text-ellipsis pl-[5px] text-gray-text-color">
        {location.address}
      </div>
    </div>
  );
};

const StackedTile = ({
  mainTile,
  additionalTiles,
  isExpanded,
  onMainTileClick,
  expandedTileFixedHeight,
}: {
  mainTile: React.ReactNode;
  additionalTiles: React.ReactNode[];
  isExpanded: boolean;
  onMainTileClick: () => void;
  expandedTileFixedHeight: number;
}) => {
  const mainTileWrapperRef = useRef<HTMLDivElement>(null);
  const [mainTileHeight, setMainTileHeight] = useState(0);
  const [
    isAnimationOfAdditionalTilesEnded,
    setIsAnimationOfAdditionalTilesEnded,
  ] = useState(false);

  useLayoutEffect(() => {
    if (isExpanded) {
      setTimeout(() => {
        setIsAnimationOfAdditionalTilesEnded(true);
      }, 300);
    } else setIsAnimationOfAdditionalTilesEnded(false);
    if (
      mainTileWrapperRef.current &&
      mainTileHeight !== mainTileWrapperRef.current.offsetHeight
    ) {
      setMainTileHeight(mainTileWrapperRef.current.offsetHeight);
    }
  }, [isExpanded, mainTileHeight, mainTileWrapperRef.current?.offsetHeight]);

  const maxVisibleCollapsedItems = 2;
  const visibleCollapsedItems = additionalTiles.slice(
    -maxVisibleCollapsedItems
  );

  const gap = 8;
  const height =
    mainTileHeight + (expandedTileFixedHeight + gap) * additionalTiles.length;

  const hasAdditionalTiles = additionalTiles.length > 0;

  return (
    <div
      className={classNames(
        "transition-height flex flex-col items-end  duration-300 ease-linear"
      )}
      style={{
        height:
          mainTileHeight !== 0
            ? isExpanded
              ? height
              : mainTileHeight
            : "auto",
        marginBottom: isExpanded ? 0 : gap * visibleCollapsedItems.length,
      }}
    >
      <div
        ref={mainTileWrapperRef}
        className={classNames(hasAdditionalTiles && "cursor-pointer")}
        onClick={onMainTileClick}
      >
        {mainTile}
      </div>
      {additionalTiles.map((a, index) => (
        <div
          style={{
            transform: isExpanded
              ? undefined
              : `translateY(-${
                  expandedTileFixedHeight * (index + 1) +
                  gap *
                    (visibleCollapsedItems.includes(a)
                      ? index + 1 - (visibleCollapsedItems.indexOf(a) + 0.7)
                      : index + 1)
                }px) translateX(-8px) scale(${
                  366 / 352 -
                  0.02 * Math.max(1, visibleCollapsedItems.indexOf(a) + 1)
                })`,
            transition: "transform 0.3s ease-in-out",
            zIndex:
              isAnimationOfAdditionalTilesEnded && isExpanded ? 0 : -index - 1,
          }}
          className={classNames(
            "mt-2 rounded-[10px]",
            !isExpanded && !visibleCollapsedItems.includes(a)
              ? "shadow-none"
              : "shadow-big"
          )}
          key={index}
        >
          {a}
        </div>
      ))}
    </div>
  );
};

export default SelectFunboxPage;
