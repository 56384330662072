import React from "react";
import cn from "classnames";
import { Avatar } from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";

interface IProps {
  onClick?: () => void;
  participants: {
    first_name?: string;
    last_name?: string;
    photo?: string;
  }[];
}

const MultiAvatar: React.FC<IProps> = ({ participants, onClick }) => {
  const students = participants.slice(0, 2);
  const double = students.length === 2;
  const avatarSize = double ? 38 : 44;
  return (
    <div
      className={cn(
        "relative flex flex-col",
        double ? "h-[58px] w-[58px]" : "h-[44px] w-[44px]",
        onClick && "cursor-pointer"
      )}
      onClick={onClick}
    >
      {students.map((participant, index) => (
        <Avatar
          style={{ zIndex: participant.photo ? index : -index }}
          className={classNames(
            double &&
              "absolute first:left-0 first:top-0  last:bottom-0 last:right-0",
            participant.photo &&
              double &&
              "box-content rounded-full border-[2px] border-solid border-on-main-color"
          )}
          key={index}
          avatarSize={avatarSize + "px"}
          person={participant}
        />
      ))}
    </div>
  );
};

export default MultiAvatar;
