import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Button, Loader, MonthSelector } from "@sizdevteam1/funjoiner-uikit";
import useStores from "../../../hooks/useStores";
import NoAvailableProgramsMessage from "./NoAvailableProgramsMessage";
import { useAvailabilityPageVM } from "../AvailablilityPageVM";
import ScheduleSetCard from "src/components/AvailabilityCards/ScheduleSetCard";
import { ProgramCard } from "src/components/AvailabilityCards/ProgramCard";
import isDaycampProgram from "src/util/isDayCampProgram";
import classNames from "classnames";

const MainSection: React.FC = observer(() => {
  const { commonStore, funboxStore } = useStores();
  const vm = useAvailabilityPageVM();
  const avVM = vm.availabilityVM;

  useEffect(() => {
    if (avVM.availability.scheduleSets.length === 1) {
      vm.toggleScheduleSet(avVM.availability.scheduleSets[0].id);
    }
  }, [avVM.availability.scheduleSets.length]);

  return (
    <div>
      <MonthSelector
        className={"rounded-xl bg-card-color px-2 py-4 shadow-big"}
        kind={"mobile"}
        selectedMonth={avVM.selectedMonth}
        onNextMonth={vm.nextMonth}
        onPrevMonth={vm.previousMonth}
        minMonth={avVM.minAvailableDate}
        maxMonth={avVM.selectedLocationMaxProgramMonth ?? avVM.minAvailableDate}
        onNextYear={() => {}}
        onPrevYear={() => {}}
      />
      <div className={classNames("relative ")}>
        {avVM.loading && (
          <div className="absolute inset-0 py-[70px]">
            <Loader />
          </div>
        )}
        <div
          className={classNames(
            "mt-4 mb-7 flex flex-col gap-4",
            avVM.loading && "opacity-25"
          )}
        >
          {!vm.isSomethingAvailable && !avVM.loading ? (
            <NoAvailableProgramsMessage
              company={commonStore.companyProfile}
              hasSelectedLocationSessions={avVM.hasSelectedLocationPrograms}
              selectedLocation={funboxStore.selectedLocation}
            />
          ) : (
            <>
              {vm.availability.scheduleSets.map((s) => (
                <ScheduleSetCard
                  key={s.id}
                  set={s}
                  isOpen={vm.expandedScheduleSetId === s.id}
                  setOpenedId={vm.toggleScheduleSet}
                  isNavigateToScheduleButtonInDescriptionModalVisible={true}
                  programCards={s.programs.map((program) => (
                    <ProgramCard
                      key={program.id}
                      {...{
                        onApplyClick: () => vm.navigateToApply(program),
                        onJoinWaitlistClick: () =>
                          vm.navigateToJoinWaitlist(program),
                        onSelectProgram: () =>
                          isDaycampProgram(program) &&
                          s.funbox_mode === "SESSIONS" &&
                          program.available_drop_ins.length > 0
                            ? vm.navigateToScheduleSession({
                                date: program.available_drop_ins[0].date,
                                id: program.available_drop_ins[0].id,
                              })
                            : vm.navigateToScheduleProgram(program),
                        onDropInClick: vm.navigateToScheduleSession,
                        isSelected: false,
                        program,
                        selectedParticipants: [],
                        selectedSessions: [],
                        selectionKind: "navigateToScheduleButton",
                        showDropIns: true,
                        showApplications: true,
                      }}
                    />
                  ))}
                />
              ))}

              {avVM.hasNextMonth && !avVM.loading && (
                <Button kind={"text"} onClick={vm.nextMonth}>
                  More Programs Next Month!
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default MainSection;
