import { Loader } from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { OrderState, OrderVm } from "src/stores/ScheduleStore/SchedulePageVM";
import CreditsToBuyOrSchedule from "./components/CreditsToBuyOrSchedule";

interface IProps {
  calculateOrderVm: OrderVm;
  className?: string;
}

const SummarySection: React.FC<IProps> = observer(
  ({ calculateOrderVm, className }) => {
    return (
      <section className={classNames(className)}>
        {calculateOrderVm.isLoading && (
          <Loader className={classNames("py-[17.5px] px-0")} />
        )}
        {calculateOrderVm.state && (
          <CreditsToBuyOrSchedule
            itemsToBuy={calculateOrderVm.state.creditsToBuy}
            itemsToSchedule={calculateOrderVm.state.creditsToUse}
          />
        )}
      </section>
    );
  }
);

export default SummarySection;
