import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { Redirect, Switch } from "react-router-dom";
import LocationSelect from "src/components/LocationSelect";
import ScheduleDatesButton from "src/components/ScheduleDatesButton/ScheduleDatesButton";
import useStores from "src/hooks/useStores";
import { TAttendanceDTO } from "src/services/api";
import { ROUTES } from "src/stores/RouterStore";
import { defineRoute } from "src/util/typedRouting";

import {
  CancelOrRescheduleVMProvider,
  useCancelOrReschedulePageVM,
} from "./CancelOrRescheduleVM";

import {
  CancelOrRescheduleFrom,
  CancelOrReschedulePageRoot,
  ExpiredMsg,
  StudentTile,
} from "./common";
import { useAsync } from "react-async-hook";
import { Button, Splash } from "@sizdevteam1/funjoiner-uikit";
import SelectLocationModal from "src/components/SelectLocationModal/SelectLocationModal";
import ProgramScheduleTable from "../Schedule/SchedulePage/components/ScheduleTables/ProgramScheduleTable";
import SessionsScheduleTable from "../Schedule/SchedulePage/components/ScheduleTables/SessionsScheduleTable";
import SelectProgram from "../Schedule/SchedulePage/components/SelectProgram";
import SelectSession from "../Schedule/SchedulePage/components/SelectSession";
import InfoMessage from "src/components/InfoMessage/InfoMessage";

export const rescheduleRoute = defineRoute<{
  state: {
    attendance: TAttendanceDTO;
  };
}>({
  path: "/reschedule",
  build: (path) => path,
});

const SelfReschedulePage: React.FC = observer(() => {
  const { state } = rescheduleRoute.useParams();
  const attendance = state?.attendance;
  const { funboxStore } = useStores();
  const { loading, result } = useAsync(
    () => funboxStore.getFunboxByIdOrUrlPart({ id: attendance?.funbox_id }),
    [attendance?.id]
  );
  if (!attendance) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }

  return loading ? (
    <Splash pathToAnimation={"/splash.json"} />
  ) : !result ? (
    <Redirect to={ROUTES.DASHBOARD} />
  ) : (
    <CancelOrRescheduleVMProvider
      attendance={attendance}
      attendanceFunbox={result}
    >
      <SelfReschedulePageImpl />
    </CancelOrRescheduleVMProvider>
  );
});

const SelfReschedulePageImpl = observer(() => {
  const vm = useCancelOrReschedulePageVM();
  const to = vm.to;

  return (
    <Switch>
      <CancelOrReschedulePageRoot
        header="Self Reschedule"
        footer={
          vm.from.isRescheduleAllowed ? (
            <ScheduleDatesButton
              confirmSelectionClick={() => (to.isSelectionConfirmed = true)}
              disabled={!vm.to.isSelected}
              scheduleClick={vm.reschedule}
              isConfirmed={to.isSelectionConfirmed}
            >
              Reschedule
            </ScheduleDatesButton>
          ) : null
        }
      >
        <RescheduleLayout />
      </CancelOrReschedulePageRoot>
    </Switch>
  );
});

const RescheduleLayout = observer(() => {
  const vm = useCancelOrReschedulePageVM();
  const { isRescheduleAllowed } = vm.from;
  const { isProgram } = vm.to;

  return (
    <div className={"flex flex-col gap-4"}>
      <div className={classNames("typography-main text-text-color")}>
        {isProgram
          ? "Program rescheduling is available for Programs that use the same Credit Type as the one being rescheduled."
          : "Session rescheduling is available for Sessions that use the same Credit Type as the one being rescheduled."}
        <div className="mt-2 flex pl-[10px]">
          <i className="icon funbox-icon" />
          <div className="typography-main ml-1 w-[326px] overflow-hidden text-ellipsis whitespace-nowrap text-gray-text-color">
            {vm.from.attendance.funbox_name}
          </div>
        </div>
      </div>

      <StudentTile />
      <CancelOrRescheduleFrom />
      {isRescheduleAllowed ? (
        <>
          <AboveWillBeCanceledLabel />
          <RescheduledTo />
        </>
      ) : (
        <ExpiredMsg />
      )}
      {vm.isSelectLocationModalOpen && (
        <SelectLocationModal
          onClose={() => (vm.isSelectLocationModalOpen = false)}
          isShowingViewMoreButton={false}
          selectedFunbox={vm.attendanceFunbox}
          selectedLocation={vm.localSelectedLocation}
          onSelectLocation={vm.selectLocalLocation}
          useGlobalFolderSelector={false}
        />
      )}
    </div>
  );
});

const AboveWillBeCanceledLabel = observer(() => {
  return (
    <>
      <span className={classNames("text-main text-center text-text-color")}>
        above will be canceled
      </span>
      <div className={"flex items-center justify-center gap-2"}>
        <div className="icon arrow-down-icon" />
        <div className="icon arrow-down-icon" />
        <div className="icon arrow-down-icon" />
      </div>
    </>
  );
});

const RescheduledTo = observer(() => {
  const vm = useCancelOrReschedulePageVM();

  const to = vm.to;
  const { isProgram, programsVM, sessionsVM, isSelectionConfirmed } = to;
  const hasSelectedItems =
    sessionsVM.selected.length !== 0 || programsVM.selected.length !== 0;

  return (
    <div className={"mt-2"}>
      <div className={classNames("typography-h2 flex gap-1 text-header-color")}>
        Rescheduled
        <i className="icon week-icon bg-icon-grey-color" />
        to:
      </div>
      <LocationSelect
        className={classNames("mt-5 w-fit !pl-[10px]")}
        selectedLocation={vm.localSelectedLocation}
        onClick={() => (vm.isSelectLocationModalOpen = true)}
      />
      <div className={"mt-8 mb-[80px]"}>
        {isSelectionConfirmed ? (
          <div className="rounded-lg bg-card-color shadow-big">
            {programsVM.selected.map((program) => (
              <ProgramScheduleTable
                key={program.id}
                program={program}
                toggleProgram={programsVM.toggle}
              />
            ))}
            {sessionsVM.selectedSessionsByProgram.map((program) => (
              <SessionsScheduleTable
                key={program.id}
                program={program}
                toggleSession={sessionsVM.toggle}
              />
            ))}
            {!hasSelectedItems && (
              <div className={classNames("text-h3 p-4 text-placeholder-color")}>
                Nothing Selected
              </div>
            )}
            <div
              className={
                "border-0 border-t-[1px] border-solid  border-separator-color p-4 "
              }
            >
              <Button
                className="mx-auto "
                kind={"text"}
                onClick={() => (to.isSelectionConfirmed = false)}
              >
                {hasSelectedItems ? "Change Dates" : "Select Dates"}
              </Button>
            </div>
          </div>
        ) : (
          <div
            className={classNames(
              !vm.localSelectedLocation && "!min-h-[112px]"
            )}
          >
            {!vm.localSelectedLocation ? (
              <InfoMessage className="px-4 py-8">
                Select Location to View Available Dates
              </InfoMessage>
            ) : (
              <>
                {isProgram ? (
                  <SelectProgram
                    onDropInClick={() => {}}
                    applyToProgram={() => {}}
                    joinWaitlist={() => {}}
                    vm={programsVM}
                    selectedParticipants={[vm.student]}
                    selectedSessions={[]}
                    showDropIns={false}
                    showApplications={false}
                  />
                ) : (
                  <SelectSession
                    vm={sessionsVM}
                    selectedParticipants={[vm.student]}
                    selectedPrograms={[]}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

export default SelfReschedulePage;
