import { Button, MarkdownText } from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import React, { useState } from "react";
import LocationSelect from "src/components/LocationSelect";
import PageRoot from "src/components/PageRoot";
import useStores from "src/hooks/useStores";
import { ROUTES } from "src/stores/RouterStore";
import { Redirect } from "react-router-dom";
import SelectLocationModal from "src/components/SelectLocationModal/SelectLocationModal";
import { observer } from "mobx-react-lite";

const WelcomeScreenPage = observer(() => {
  const { commonStore, funboxStore, routerStore } = useStores();
  const [isSelectLocationModalOpen, setIsSelectLocationModalOpen] =
    useState(false);
  if (
    funboxStore.selectedFunbox == null ||
    funboxStore.selectedLocation == null
  ) {
    return <Redirect to={ROUTES.SELECT_FUNBOX_PAGE} />;
  }

  return (
    <PageRoot className="!min-h-[calc(100dvh-130px)]">
      <div className="flex flex-col  gap-3 ">
        <div className="flex flex-col gap-2 text-header-color">
          <div className="typography-h1 text-center">
            {commonStore.companyProfile.name}
          </div>
          <Button
            className="!typography-h3"
            kind="text"
            onClick={() =>
              routerStore.navigate(ROUTES.SELECT_FUNBOX_PAGE, {
                from: ROUTES.ROOT,
                replace: true,
              })
            }
          >
            <i className="icon funbox-icon icon-lg" />

            {funboxStore.selectedFunbox?.name}
          </Button>
        </div>
        {commonStore.publicSettings.welcome_screen_message.enabled && (
          <div className="typography-small__t rounded-[10px]  bg-on-main-color px-4 py-3 text-text-color shadow-big">
            <MarkdownText>
              {commonStore.publicSettings.welcome_screen_message.text}
            </MarkdownText>
          </div>
        )}
      </div>
      <div className="my-4 flex flex-col gap-6">
        <LocationSelect
          selectedLocation={funboxStore.selectedLocation}
          onClick={() => setIsSelectLocationModalOpen(true)}
        />
        <NavigationOption
          text={"View Dates & Register Now"}
          iconClassName="week-icon"
          onClick={() => routerStore.navigate(ROUTES.AVAILABILITY)}
        />
        {funboxStore.selectedFunbox.type !== "classes" && (
          <NavigationOption
            text="Explore Offerings"
            iconClassName="offerings-icon"
            onClick={() => routerStore.navigate(ROUTES.EXPLORE_OFFERINGS)}
          />
        )}
        {funboxStore.isBuyCreditsAndSaveEnabled &&
          funboxStore.hasPaidCreditTypes && (
            <NavigationOption
              text={"Credits & Packages"}
              iconClassName="issue-payment-icon"
              onClick={() => {
                routerStore.navigate(ROUTES.FLEXIBLE_PAYMENTS);
              }}
            />
          )}
      </div>
      {isSelectLocationModalOpen && (
        <SelectLocationModal
          onClose={() => setIsSelectLocationModalOpen(false)}
          useGlobalFolderSelector
          isShowingViewMoreButton
          selectedFunbox={funboxStore.selectedFunbox}
          selectedLocation={funboxStore.selectedLocation}
          onSelectLocation={funboxStore.selectLocationId}
        />
      )}
    </PageRoot>
  );
});

const NavigationOption = ({
  text,
  iconClassName,
  onClick,
}: {
  text: React.ReactNode;
  iconClassName: string;
  onClick: () => void;
}) => {
  return (
    <div
      onClick={onClick}
      className="group box-border cursor-pointer rounded-xl border-[2px] border-solid border-main-color bg-on-main-color px-4 py-[22px] shadow-big hover:border-dark-main-color"
    >
      <div className="typography-h2 flex items-center gap-[10px] text-header-color group-hover:text-dark-main-color">
        <i
          className={classNames(
            "icon  !h-8 !w-8 bg-main-color group-hover:bg-dark-main-color ",
            iconClassName
          )}
        />
        {text}
      </div>
    </div>
  );
};

export default WelcomeScreenPage;
