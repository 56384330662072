import {
  Button,
  ColorLabel,
  CreditIcon,
  Loader,
  PriceWithCents,
  Splash,
  toOrdinal,
} from "@sizdevteam1/funjoiner-uikit";
import React from "react";
import PageHeader from "src/components/PageHeader";
import PageRoot from "src/components/PageRoot";
import dayjs from "dayjs";
import Fee from "../Checkout&Payment/Checkout/components/Fee";
import { observer } from "mobx-react-lite";
import {
  OrderPaymentPlanPageVMProvider,
  useOrderPaymentPlanPageVM,
} from "./OrderPaymentPlanPageVM";
import { Redirect, useParams } from "react-router-dom";
import { ROUTES } from "src/stores/RouterStore";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import Border from "src/components/Border";
import useStores from "src/hooks/useStores";
import ProcessingPayment from "../Checkout&Payment/components/ProcessingPayment";
import { useAsync } from "react-async-hook";
import api from "src/services/api";
import ProgramTile from "src/components/AttendanceTile/ProgramTile";
import ProgramOfSessionsTile from "src/components/AttendanceTile/ProgramOfSessionsTile";
import SessionTile from "src/components/AttendanceTile/SessionTile";
import StudentSelector from "src/components/StudentSelector";
import { formatDate } from "@sizdevteam1/funjoiner-uikit/util";
import { PaymentMethodPicker } from "../../components/PaymentMethods/PaymentMethodPicker";
import { isScheduleAndPayOrder } from "../../services/api/orders";

const OrderPaymentPlanPage = observer(() => {
  const { id } = useParams<{ id: string }>();

  if (!id) return <Redirect to={ROUTES.DASHBOARD} />;

  const { result, error } = useAsync(() => api.orders.getOrderById(+id), [id]);

  if (error || (result && !isScheduleAndPayOrder(result))) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }
  if (result && (!isScheduleAndPayOrder(result) || !result.payment_plan)) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }
  return result ? (
    <OrderPaymentPlanPageVMProvider order={result}>
      <OrderPaymentPlanPageImpl />
    </OrderPaymentPlanPageVMProvider>
  ) : (
    <Splash pathToAnimation={"/splash.json"} />
  );
});

const OrderPaymentPlanPageImpl = observer(() => {
  const vm = useOrderPaymentPlanPageVM();
  return vm.mode === "view_plan" ? <ViewPlan /> : <PayInstallment />;
});

const ViewPlan = observer(() => {
  const vm = useOrderPaymentPlanPageVM();

  return (
    <PageRoot>
      <PageHeader className="!mb-5" showBackLink>
        Payment Plan
      </PageHeader>
      <div className="typography-h2 text-text-color">
        Remaining Installments
      </div>
      <div className="typography-main mt-2 text-gray-text-color">
        Your credit card will be automatically charged on the set installments
        deadlines. Alternatively, you can make a payment from this screen.
      </div>
      <div className="mt-4 flex flex-col gap-4">
        {vm.missedInstallments.map((i, index) => (
          <div
            key={i.id}
            className="border-1 flex justify-between rounded-[10px]
             border-solid border-icon-red-color bg-on-main-color py-3  px-4 shadow-big"
          >
            <div className="flex flex-col">
              <span className="typography-h4 text-text-color">
                {toOrdinal(i.ordinal_number)} Installment
              </span>
              <span className="typography-small text-delete-color">
                Failed. Due {dayjs(i.due_date).format("MMM Do")}
              </span>
            </div>
            <div className="typography-h3 flex items-center gap-3">
              <PriceWithCents
                amount={i.amount}
                prefix="$"
                typography="typography-h3"
              />
              <Button
                disabled={index !== 0}
                onClick={() => vm.toPayment(i.id)}
                kind="secondary"
              >
                Pay
              </Button>
            </div>
          </div>
        ))}
        {vm.pendingInstallments.map((i, index) => (
          <div
            key={i.id}
            className="flex justify-between rounded-[10px]  bg-on-main-color py-3  px-4 shadow-big"
          >
            <div className="flex flex-col">
              <span className="typography-h4 text-text-color">
                {toOrdinal(i.ordinal_number)} Installment
              </span>
              <span className="typography-small text-gray-text-color">
                Pending. Due {dayjs(i.due_date).format("MMM Do")}
              </span>
            </div>
            <div className="typography-h3 flex items-center gap-3">
              <PriceWithCents
                amount={i.amount}
                prefix="$"
                typography="typography-h3"
              />
              <Button
                onClick={() => {
                  vm.toPayment(i.id);
                }}
                disabled={vm.missedInstallments.length > 0 || index !== 0}
                kind="secondary"
              >
                Pay
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div className="typography-small my-6 flex  items-center justify-between gap-3 text-gray-text-color">
        <div className="h-[1px] w-full bg-separator-color"></div>
        <div className="whitespace-nowrap">Payment Plan Details</div>
        <div className="h-[1px] w-full bg-separator-color"></div>
      </div>
      <DetailsSection />
    </PageRoot>
  );
});

const DetailsSection = observer(() => {
  const vm = useOrderPaymentPlanPageVM();
  const { commonStore } = useStores();

  const isOrderAdditionalDetailsBlockVisible =
    vm.order.promocode_id ||
    vm.order.own_fees.length > 0 ||
    vm.order.installment_fees.length > 0 ||
    vm.order.discount !== 0 ||
    vm.order.scholarship_amount != null;

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <span className="typography-h3 text-text-color">
          Order #{vm.order.id}
        </span>
        <span className="typography-label text-gray-text-color">
          {formatDate(vm.order.created_at)}
        </span>
      </div>
      <div className="mt-4 flex flex-col gap-3 rounded-[10px] bg-on-main-color py-3 shadow-big">
        {vm.creditGroupsByType.map((gr) => (
          <div
            key={gr.type.id}
            className="flex items-center justify-between gap-2 px-4"
          >
            <div className="flex items-center gap-2">
              <CreditIcon
                isFree={gr.type.is_free}
                size={24}
                isProgram={gr.type.is_program_credit}
              />
              <div className="typography-small flex flex-col gap-[2px] text-gray-text-color">
                <span className="typography-h3 text-text-color">
                  {gr.type.name}
                </span>
                <span>
                  {gr.type.is_free && "Free "}
                  {gr.type.is_program_credit ? "Program " : "Session "}
                  Credit × {gr.numberOfCredits}
                </span>
              </div>
            </div>
            <PriceWithCents
              amount={gr.totalGroupAmount}
              prefix="$"
              typography="typography-h3"
            />
          </div>
        ))}
        {isOrderAdditionalDetailsBlockVisible && (
          <>
            <div className="h-[1px] bg-separator-color px-4"></div>
            <div className="flex flex-col gap-3 text-text-color ">
              {vm.order.promocode_id && vm.order.promocode_discount && (
                <div className="typography-label flex items-center justify-between px-4 py-1">
                  <div className="flex items-center gap-1 text-gray-text-color">
                    <i className="icon promocodes-icon" />
                    <span> {vm.order.promocode_id}</span>
                  </div>
                  <PriceWithCents
                    amount={vm.order.promocode_discount}
                    prefix="-$"
                    typography="typography-label"
                  />
                </div>
              )}
              {vm.order.discount !== 0 && (
                <div className="typography-label flex items-center justify-between px-4 py-[2px]  ">
                  <ColorLabel
                    className="bg-icon-in-input-color"
                    text="Discount"
                  />
                  <PriceWithCents
                    amount={vm.order.discount}
                    prefix="-$"
                    typography="typography-label"
                  />
                </div>
              )}
              {vm.order.scholarship_amount != null && (
                <div className="typography-label flex items-center justify-between px-4 py-[2px] ">
                  <ColorLabel
                    className="bg-surface-purple-color"
                    text="Scholarship"
                  />

                  <PriceWithCents
                    amount={vm.order.scholarship_amount}
                    prefix="-$"
                    typography="typography-label"
                  />
                </div>
              )}
              {[...vm.order.own_fees, ...vm.order.installment_fees].map((f) => (
                <Fee fee={f} key={f.id} />
              ))}
            </div>
          </>
        )}
        <div className="h-[1px] bg-separator-color px-4"></div>
        <div className="typography-main_sb flex justify-between px-4 text-text-color">
          <span>Total Price</span>
          <PriceWithCents
            amount={vm.order.final_price}
            prefix="$"
            typography="typography-main_sb"
          />
        </div>
      </div>
      {vm.isLoadingAttendances ? (
        <Loader className="mt-6" />
      ) : (
        vm.hasAttendances && (
          <div className={"mt-6"}>
            <div className="typography-h3 text-text-color">Scheduled To</div>
            <StudentSelector
              className="mt-4"
              students={vm.scheduledStudents}
              selectedStudentId={vm.selectedStudent?.id}
              onSelectStudent={vm.selectStudent}
            />

            <div className={classNames("relative mt-4")}>
              {vm.isFakeStudentSelectLoading && <Loader className="absolute" />}
              <div
                className={classNames(
                  "flex flex-col gap-4",
                  vm.isFakeStudentSelectLoading && " opacity-0"
                )}
              >
                {vm.orderAttendancesForSelectedStudent.map((a) =>
                  a.type === "program" ? (
                    <ProgramTile key={a.id} attendance={a} actions={[]} />
                  ) : a.type === "program_of_sessions" ? (
                    <ProgramOfSessionsTile
                      key={a.id}
                      attendance={a}
                      actions={[]}
                    />
                  ) : (
                    <SessionTile key={a.id} attendance={a} actions={[]} />
                  )
                )}
              </div>
            </div>
          </div>
        )
      )}

      {commonStore.paymentPlanPolicy && (
        <a
          target={"_blank"}
          rel="noreferrer"
          href={commonStore.paymentPlanPolicy}
          className="group typography-main_sb mx-auto mb-6 mt-10 flex w-fit gap-1"
        >
          <i className="icon doc-icon icon-lg bg-main-color group-hover:bg-dark-main-color" />
          Payment Plans Policy
        </a>
      )}
    </div>
  );
});

const PayInstallment = observer(() => {
  const vm = useOrderPaymentPlanPageVM();

  if (!vm.installmentToPay) return null;
  if (vm.processingPayment) {
    return (
      <ProcessingPayment
        card={vm.processingPayment.card}
        amount={vm.processingPayment.amount}
      />
    );
  }

  return (
    <PageRoot>
      <PageHeader
        className="!mb-5"
        showBackLink
        onBackLinkClick={() => (vm.mode = "view_plan")}
      >
        Payment
      </PageHeader>
      <div className="rounded-t-2xl bg-on-main-color shadow-big">
        <div className="typography-h3 p-4 text-text-color">
          Payment Plan. {toOrdinal(vm.installmentToPay.ordinal_number)}{" "}
          Installment{" "}
        </div>
        {vm.plan.installments.map((i) => (
          <div
            key={i.id}
            className={twMerge(
              "flex items-center justify-between gap-x-5 px-4 py-2",
              vm.isSelectedToPay(i.id)
                ? "!bg-highlight-color"
                : "odd:bg-on-main-color even:bg-table-row-color"
            )}
          >
            <span
              className={twMerge(
                "typography-main whitespace-nowrap text-gray-text-color",
                i.is_missed && "typography-main_sb text-delete-color"
              )}
            >
              {dayjs(i.due_date).format("MMMM Do")}
            </span>
            <div>
              {i.is_paid ? (
                <i className="icon check-in-object-2-icon bg-icon-dark-green-color" />
              ) : (
                <div className="flex flex-wrap  justify-end">
                  <div
                    className={twMerge(
                      "typography-main text-gray-text-color",
                      vm.isSelectedToPay(i.id) &&
                        "typography-main_sb text-text-color"
                    )}
                  >
                    <PriceWithCents
                      amount={i.initial_amount}
                      prefix="$"
                      typography={
                        vm.isSelectedToPay(i.id)
                          ? "typography-main_sb"
                          : "typography-main"
                      }
                    />
                  </div>
                  {i.own_fees.length > 0 &&
                    i.own_fees.map((f, index) => (
                      <div
                        className={classNames(
                          "typography-main_sb flex whitespace-nowrap",
                          vm.isSelectedToPay(i.id)
                            ? "text-text-color"
                            : "text-gray-text-color"
                        )}
                        key={index}
                      >
                        &nbsp;+&nbsp;
                        <PriceWithCents
                          amount={f.amount}
                          prefix="$"
                          typography="typography-main_sb"
                        />
                        <span className="text-gray-text-color">
                          &nbsp;(fee)
                        </span>{" "}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="h-[1px] bg-separator-color"></div>
      <div className="typography-main_sb flex justify-between bg-on-main-color p-4 text-gray-text-color">
        <span className="text-text-color">Total</span>
        <PriceWithCents
          amount={vm.installmentToPayTotalAmountToPay}
          prefix="$"
          typography="typography-h3"
        />
      </div>
      <Border />
      <PaymentMethodPicker vm={vm.paymentMethodPickerVm} />
      <Button
        className={"sticky bottom-3 mt-auto"}
        autoLoading
        size={"big"}
        onClick={vm.payInstallment}
      >
        Pay&nbsp;
        <PriceWithCents
          amount={vm.installmentToPayTotalAmountToPay}
          prefix="$"
          typography="typography-button"
        />
      </Button>
    </PageRoot>
  );
});

export default OrderPaymentPlanPage;
