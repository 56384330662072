import {
  action,
  computed,
  IComputedValue,
  makeObservable,
  observable,
  reaction,
  toJS,
  when,
} from "mobx";
import { fromPromise, IPromiseBasedObservable } from "mobx-utils";
import RouterStore, { ROUTES } from "../RouterStore";
import debounce from "debounce";
import api, { ICreditDTO, IFunboxDTO, ILocationDTO } from "src/services/api";
import {
  ICalculatedScheduleAndPayOrderAndAdditionalInfoDTO,
  ICalculatedScheduleAndPayOrderDTO,
  ICalculateScheduleAndPayOrderDTO,
  ICreateScheduleAndPayOrderDTO,
  TBookingDataItemDTO,
} from "src/services/api/orders";
import notificator from "src/services/systemNotifications/notificationCenterService";
import { groupUsedCredits } from "src/util/groupCredits";
import CustomerStore from "../CustomerStore";
import PaymentStore from "../PaymentStore";
import { ProgramsVM, SessionsVM } from "./Programs&SessionsVMs";
import ParticipantSelectionVM from "./ParticipantSelectionVM";
import * as Sentry from "@sentry/react";
import { scheduleSuccessRoute } from "src/pages/SuccessPages/ScheduleSuccessPage";
import { AvailabilityVM } from "src/pages/AvailabilityPage/components/AvailabilityVM";
import {
  IAvailableDaycampSession,
  TAvailableProgram,
} from "src/services/api/availability";
import FunboxStore from "../FunboxStore";
import React, { useEffect, useMemo } from "react";
import useStores from "src/hooks/useStores";
import useVM from "src/hooks/useVM";
import { IBuyCreditRow, IUsedCreditRow } from "src/services/api/credits";
import { joinWaitlistRoute } from "src/pages/Schedule/JoinWaitlistPage/JoinWaitlistPage";
import { TScheduleToOpen } from "src/pages/Schedule/SchedulePage/SchedulePage";
import { applicationProcessPageRoute } from "../../pages/ApplicationProcessPage/ApplicationProcessPage";
import { beforeScheduleQuestionsPageRoute } from "../../pages/BeforeSheduleQuestionsPage/BeforeScheduleQuestionsPage";
import { isAbortError, raise } from "@sizdevteam1/funjoiner-uikit";
import { completeCustomerProfilePageRoute } from "../../pages/Schedule/CompleteCustomerProfilePage/CompleteCustomerProfilePage";
import { shouldDisplayCompleteCustomerProfilePage } from "../../pages/Schedule/CompleteCustomerProfilePage/CompleteCustomerProfilePageVm";
import CommonStore from "../CommonStore";
import { ICancelablePromise } from "@sizdevteam1/funjoiner-web-shared/services";
import PaymentMethodStore from "../PaymentMethodStore";
import { providePaymentInformationPageRoute } from "../../pages/Schedule/ProvidePaymentInformationPage";
import { assertNever } from "@sizdevteam1/funjoiner-uikit/util";
import dayjs from "dayjs";
import {
  BaseOrderCalculatorVm,
  getOrderMode,
} from "@sizdevteam1/funjoiner-web-shared/components/ScheduleAndPay/BaseOrderCalculatorVm";

export type SelectedScheduleVM = {
  vm: ScheduleVM;
  confirm: () => void;
};

export class SchedulePageVM {
  orderVm: OrderVm;

  readonly dispose: () => void;

  @observable
  linkageState: LinkedScheduleState | UnlinkedScheduleState;
  getSelectedUnlinkedParticipantsAmountForProgram = (programId: string) => {
    if (this.linkageState instanceof UnlinkedScheduleState) {
      let result = 0;
      for (let scheduleVm of Object.values(this.linkageState.scheduleVMs)) {
        if (scheduleVm.programsVM.selectedIds.has(programId)) {
          result++;
        }
      }
      return result;
    }
  };
  getSelectedUnlinkedParticipantsAmountForSession = (sessionId: string) => {
    if (this.linkageState instanceof UnlinkedScheduleState) {
      let result = 0;
      for (let scheduleVm of Object.values(this.linkageState.scheduleVMs)) {
        if (scheduleVm.sessionsVM.selectedIds.has(sessionId)) {
          result++;
        }
      }
      return result;
    }
  };
  @observable
  selectedSchedule?: SelectedScheduleVM;

  @observable
  isSecuringReservation = false;

  @computed
  get isLinked() {
    return isLinkedState(this.linkageState);
  }

  @computed
  get hasSelectedItems() {
    return Object.values(this.linkageState.scheduleMap).some(
      (e) => e.programs.length > 0 || e.sessions.length > 0
    );
  }

  @observable availabilityVM: AvailabilityVM;

  participantSelection: ParticipantSelectionVM;
  selectedFunbox: IComputedValue<IFunboxDTO>;
  selectedLocation: IComputedValue<ILocationDTO>;

  constructor(
    private routerStore: RouterStore,
    private paymentStore: PaymentStore,
    private paymentMethodStore: PaymentMethodStore,
    private funboxStore: FunboxStore,
    private customerStore: CustomerStore,
    private commonStore: CommonStore,
    private scheduleToOpen?: TScheduleToOpen
  ) {
    makeObservable(this);
    this.selectedFunbox = computed(
      () => this.funboxStore.selectedFunbox ?? raise("Funbox is null")
    );
    this.selectedLocation = computed(
      () => this.funboxStore.selectedLocation ?? raise("Location is null")
    );

    this.orderVm = new OrderVm(
      computed(() => this.customerStore.credits),
      computed(() => this.customerStore.customer.email),
      computed(() => this.linkageState),
      (payload) => api.orders.calculateScheduleAndPayOrder(payload),
      this.reset,
      this.routerStore,
      this.paymentStore
    );

    this.participantSelection = new ParticipantSelectionVM(
      this.customerStore,
      this.routerStore.returnToSourcePage,
      computed(
        () =>
          this.selectedFunbox.get().participants_or_customers === "CUSTOMERS"
      )
    );
    this.availabilityVM = new AvailabilityVM(
      this.routerStore,
      this.selectedFunbox,
      computed(
        () => this.funboxStore.selectedLocation?.id ?? raise("Location is null")
      ),
      "schedule_page",
      this.scheduleToOpen?.selectedScheduleMonth
    );

    this.linkageState = new LinkedScheduleState(
      this.availabilityVM,
      this.participantSelection
    );
    if (this.scheduleToOpen) {
      this.initScheduleToOpen(this.scheduleToOpen);
    }

    const disposeStateChanged = reaction(
      () => this.linkageState,
      (state) => {
        this.selectSchedule(
          state instanceof LinkedScheduleState
            ? state.scheduleVM
            : state.scheduleVMs[0]
        );
      },
      {
        fireImmediately: true,
      }
    );

    const disposeScheduleChanged = reaction(
      () => this.linkageState.scheduleMap,
      () => this.orderVm.recalculate()
    );

    const disposeSearchParamChanged = reaction(
      () => toJS(this.routerStore.searchParams),
      async (params) => {
        const action = params["action"];

        if (action != null) {
          this.routerStore.setSearchParam("action", undefined, true);
        } else {
          return;
        }

        if (action === "submit") {
          this.isSecuringReservation = true;
          await when(() => this.orderVm.calculationResult != null);
          await this.secureReservation();
        } else if (
          action === "join_waitlist" ||
          action === "apply_to_program"
        ) {
          const studentIds = params["student_ids"].split(",").map(Number);
          const programId = params["program_id"];

          if (action === "join_waitlist") {
            this.joinWaitlist(programId, studentIds);
          } else if (action === "apply_to_program") {
            this.applyToProgram(programId, studentIds);
          }
        }
      }
    );

    // newly added students may have restrictions. need to reload availability
    const disposeNewParticipantAdded = reaction(
      () => this.customerStore.studentsWithoutCustomerAsParticipant.length,
      () => this.availabilityVM.refresh()
    );

    const disposeFunboxOrLocationChanged = reaction(
      () => ({
        locationId: this.selectedLocation.get().id,
        funboxId: this.selectedFunbox.get().id,
      }),
      ({ locationId, funboxId }, prev) => {
        if (locationId !== prev.locationId || funboxId !== prev.funboxId) {
          this.reset();
        }
      }
    );

    this.dispose = () => {
      disposeStateChanged();
      disposeScheduleChanged();
      disposeSearchParamChanged();
      this.availabilityVM.dispose();
      disposeFunboxOrLocationChanged();
      disposeNewParticipantAdded();
    };
  }

  @action.bound
  unlink() {
    if (this.linkageState instanceof UnlinkedScheduleState) {
      return;
    }

    this.linkageState = this.linkageState.unlink();
  }

  @action.bound
  selectSchedule(vm: ScheduleVM) {
    this.selectedSchedule = {
      vm: vm,
      confirm: this.unselectSchedule,
    };
  }

  @action.bound
  unselectSchedule() {
    this.selectedSchedule = undefined;
  }

  @computed
  get isConfirmed() {
    if (isLinkedState(this.linkageState)) {
      return this.selectedSchedule?.vm !== this.linkageState.scheduleVM;
    } else
      return Object.values(this.linkageState.scheduleVMs).every(
        (vm) => this.selectedSchedule?.vm !== vm
      );
  }

  @observable
  isConfirmLocationChangeModalOpened = false;

  @action.bound
  handleOpenSelectLocationModal() {
    if (this.hasSelectedItems) {
      this.isConfirmLocationChangeModalOpened = true;
    } else {
      this.isSelectLocationModalOpen = true;
    }
  }

  @observable isSelectLocationModalOpen = false;

  @computed get atLeastOneOtherFunboxAvailable() {
    return (
      this.funboxStore.funboxes.filter(
        (f) => f.id !== this.selectedFunbox.get().id
      ).length > 0
    );
  }

  @observable private _selectedTab: "programs" | "sessions" = "programs";
  @computed get selectedTab() {
    const mode = this.selectedFunbox.get().mode;
    if (mode === "PROGRAMS_AND_SESSIONS") return this._selectedTab;
    return mode === "PROGRAMS" ? "programs" : "sessions";
  }
  @action.bound selectTab(t: "programs" | "sessions") {
    this._selectedTab = t;
  }

  @action.bound
  goBackFromWaitlist() {
    if (this.routerStore.currentPath === ROUTES.SCHEDULE_WAITLIST) {
      this.routerStore.returnToSourcePage(ROUTES.SCHEDULE);
    }
  }

  @action.bound
  applyToProgram = async (
    programId: string,
    selectedParticipantIds: number[]
  ) => {
    const program = this._getProgramById(selectedParticipantIds[0], programId);

    try {
      // Check if can apply
      await api.applications.getOrCreateApplications(
        program.schedule_set_id,
        program.id,
        selectedParticipantIds
      );
    } catch (e) {
      Sentry.captureException(e);
      notificator.error("Error", e);
      return;
    }

    if (
      !this._guardCustomerProfileIsComplete(
        "apply_to_program",
        programId,
        selectedParticipantIds
      )
    ) {
      return;
    }
    this.routerStore.navigateToRoute(
      applicationProcessPageRoute.build({
        searchParams: {
          programId: program.id,
          scheduleSetId: program.schedule_set_id,
          selectedParticipantIds: selectedParticipantIds.join(","),
        },
      })
    );
  };

  @action.bound
  async secureReservation() {
    this.isSecuringReservation = true;

    try {
      const calculatedOrder = this.orderVm.order;
      if (calculatedOrder == null) {
        return;
      }
      const bookingDataItems = [
        ...calculatedOrder.sign_up.schedule,
        ...calculatedOrder.sign_up.buy_then_schedule,
      ];

      if (!this._guardCustomerProfileIsComplete("submit")) {
        return null;
      }

      const unansweredQuestionSets =
        await api.requiredForAttendance.getUnansweredSmartFormsRequiredBeforeSchedule(
          bookingDataItems
        );

      if (unansweredQuestionSets.length > 0) {
        this.routerStore.navigateToRoute(
          beforeScheduleQuestionsPageRoute.build({
            state: { smart_forms: unansweredQuestionSets },
          })
        );
        return null;
      }

      if (!this._guardCustomerHasPaymentInformationAttached()) {
        return null;
      }

      await this.orderVm.proceed();
    } finally {
      this.isSecuringReservation = false;
    }
  }

  private _guardCustomerProfileIsComplete(
    action: "submit" | "join_waitlist" | "apply_to_program",
    program_id?: string,
    student_ids?: number[]
  ) {
    let selectedParticipantIds =
      student_ids ??
      this.participantSelection.selectedParticipants.map((s) => s.id);
    if (
      action === "submit" &&
      this.linkageState instanceof UnlinkedScheduleState
    ) {
      const scheduleVMs = this.linkageState.scheduleVMs;
      const idsWithSomethingSelected = Object.keys(scheduleVMs)
        .filter(
          (id) =>
            scheduleVMs[+id].programsVM.selected.length > 0 ||
            scheduleVMs[+id].sessionsVM.selected.length > 0
        )
        .map(Number);
      selectedParticipantIds = selectedParticipantIds.filter((s) =>
        idsWithSomethingSelected.includes(s)
      );
    }

    if (
      shouldDisplayCompleteCustomerProfilePage(
        this.customerStore,
        this.commonStore,
        this.routerStore,
        selectedParticipantIds
      )
    ) {
      this.routerStore.navigateToRoute(
        completeCustomerProfilePageRoute.build({
          state: {
            action: action,
            program_id: program_id,
            student_ids: selectedParticipantIds,
          },
        })
      );
      return false;
    }

    return true;
  }

  @computed
  get mustProvidePaymentInformation() {
    return (
      this.orderVm.order?.final_price === 0 &&
      this.selectedFunbox.get().all_schedules_require_payment_information &&
      this.paymentMethodStore.stripePaymentMethods.payment_methods.length === 0
    );
  }
  private _guardCustomerHasPaymentInformationAttached() {
    if (this.mustProvidePaymentInformation) {
      this.routerStore.navigateToRoute(
        providePaymentInformationPageRoute.build({ state: {} })
      );
      return false;
    }
    return true;
  }
  @action.bound
  joinWaitlist(programId: string, selectedParticipantIds: number[]) {
    if (
      !this._guardCustomerProfileIsComplete(
        "join_waitlist",
        programId,
        selectedParticipantIds
      )
    ) {
      return;
    }

    const program = this._getProgramById(selectedParticipantIds[0], programId);

    this.routerStore.navigateToRoute(
      joinWaitlistRoute.build({
        state: {
          args: {
            program: toJS(program),
            selectedParticipantIds: selectedParticipantIds,
          },
        },
      })
    );
  }

  private _getProgramById(
    studentId: number,
    programId: string
  ): TAvailableProgram {
    let scheduleVm =
      this.linkageState instanceof LinkedScheduleState
        ? this.linkageState.scheduleVM
        : this.linkageState.scheduleVMs[studentId];

    return scheduleVm.programsVM.availability.scheduleSets
      .flatMap((set) => set.programs)
      .find((program: TAvailableProgram) => program.id === programId)!;
  }

  @action.bound
  private reset = () => {
    this.linkageState = new LinkedScheduleState(
      this.availabilityVM,
      this.participantSelection
    );

    this.goBackFromWaitlist();
  };

  @action.bound
  private initScheduleToOpen(scheduleToOpen: TScheduleToOpen) {
    this.routerStore.resetState();
    if (!(this.linkageState instanceof LinkedScheduleState)) {
      return;
    }
    if (scheduleToOpen.type === "schedule_set") {
      this._selectedTab = "programs";
      this.linkageState.scheduleVM.programsVM.openScheduleSetId =
        scheduleToOpen.schedule_set_id;
    } else if (scheduleToOpen.type === "program") {
      this._selectedTab = "programs";
      this.linkageState.scheduleVM.programsVM.openScheduleSetId =
        scheduleToOpen.schedule_set_id;
      this.linkageState.scheduleVM.programsVM.setToHighLight({
        id: scheduleToOpen.program_id,
        action: "select",
      });
    } else if (scheduleToOpen.type === "session") {
      this._selectedTab = "sessions";
      this.linkageState.scheduleVM.sessionsVM.focusedDate =
        scheduleToOpen.session_date;
      this.linkageState.scheduleVM.sessionsVM.setToHighLight(
        scheduleToOpen.session_id,
        true
      );
    } else if (scheduleToOpen.type === "apply") {
      this._selectedTab = "programs";
      this.linkageState.scheduleVM.programsVM.openScheduleSetId =
        scheduleToOpen.schedule_set_id;
      this.linkageState.scheduleVM.programsVM.setToHighLight({
        id: scheduleToOpen.program_id,
        action: "apply",
      });
    } else if (scheduleToOpen.type === "join_waitlist") {
      this._selectedTab = "programs";
      this.linkageState.scheduleVM.programsVM.openScheduleSetId =
        scheduleToOpen.schedule_set_id;
      this.linkageState.scheduleVM.programsVM.setToHighLight({
        id: scheduleToOpen.program_id,
        action: "join_waitlist",
      });
    } else {
      assertNever(scheduleToOpen);
    }
  }
}

type Schedule = {
  programs: TAvailableProgram[];
  sessions: IAvailableDaycampSession[];
};

type ScheduleMap = {
  [studentId: number]: Schedule;
};

export class ScheduleVM {
  @observable
  sessionsVM: SessionsVM;

  @observable
  programsVM: ProgramsVM;

  @action.bound resetSchedule() {
    this.sessionsVM.selected.forEach((s) => this.sessionsVM.toggle(s));
    this.programsVM.selected.forEach((p) => this.programsVM.toggle(p));
  }

  @computed
  get schedule(): Schedule {
    const sessions = [...this.sessionsVM.selected];
    const programs = [...this.programsVM.selected];
    return {
      sessions,
      programs,
    };
  }

  constructor(availabilityVM: AvailabilityVM, schedule?: Schedule) {
    this.programsVM = new ScheduleProgramsVM(
      availabilityVM,
      () => this.sessionsVM.selectedIds,
      schedule
    );
    this.sessionsVM = new ScheduleSessionsVM(
      availabilityVM,
      () => this.programsVM.selectedIds,
      schedule
    );

    makeObservable(this);
  }
}

class ScheduleSessionsVM extends SessionsVM {
  @observable
  selected: IAvailableDaycampSession[];

  constructor(
    public availabilityVM: AvailabilityVM,
    public getSelectedProgramIds: () => Set<string>,
    schedule: Schedule | undefined
  ) {
    super();
    this.availabilityVM = availabilityVM;
    this.selected = [...(schedule?.sessions ?? [])];
    makeObservable(this);
  }

  @computed
  get availability(): IAvailableDaycampSession[] {
    if (this.availabilityVM.availability.type !== "daycamp") return [];
    return this.availabilityVM.availability.sessions;
  }

  @action.bound
  toggle(session: IAvailableDaycampSession): void {
    const index = this.selected.findIndex((s) => s.id === session.id);
    if (index > -1) {
      this.selected.splice(index, 1);
    } else {
      this.selected.push(session);
    }
  }
}

class ScheduleProgramsVM extends ProgramsVM {
  @observable
  selected: TAvailableProgram[];

  constructor(
    public availabilityVM: AvailabilityVM,
    public getSelectedSessionIds: () => Set<string>,
    schedule: Schedule | undefined
  ) {
    super();
    this.availabilityVM = availabilityVM;
    this.selected = [...(schedule?.programs ?? [])];

    makeObservable(this);
  }

  @computed
  get availability() {
    return this.availabilityVM.availability;
  }

  @action.bound
  toggle(program: TAvailableProgram): void {
    const index = this.selected.findIndex((p) => p.id === program.id);
    if (index > -1) {
      this.selected.splice(index, 1);
    } else {
      this.selected.push(program as any);
    }
  }
}

export type OrderState =
  | {
      creditsToUse: IUsedCreditRow[];
      creditsToBuy: IBuyCreditRow[];
    }
  | undefined;

export class OrderVm extends BaseOrderCalculatorVm<
  ICalculateScheduleAndPayOrderDTO,
  ICalculatedScheduleAndPayOrderAndAdditionalInfoDTO
> {
  constructor(
    private customerCredits: IComputedValue<ICreditDTO[]>,
    private customerEmail: IComputedValue<string | null>,
    private linkageState: IComputedValue<
      LinkedScheduleState | UnlinkedScheduleState | null
    >,
    calculateOrder: (
      payload: ICalculateScheduleAndPayOrderDTO
    ) => ICancelablePromise<ICalculatedScheduleAndPayOrderAndAdditionalInfoDTO>,
    private onError: () => void,
    private routerStore: RouterStore,
    private paymentStore: PaymentStore,
    onRecalculate?: () => void
  ) {
    super(
      computed(() => {
        const state = linkageState.get();
        if (state == null) return null;

        const payload = this._payloadFromScheduleMap(state.scheduleMap);
        if (payload.booking_data.length === 0) return null;

        return payload;
      }),
      calculateOrder,
      onRecalculate
    );

    console.log("");
  }

  @computed
  get state(): OrderState {
    const order = this.order;
    if (order == null) return undefined;

    return {
      creditsToUse: this.getCreditsToUse(order),
      creditsToBuy: this.getCreditsToBuy(order),
    };
  }

  @computed
  get order() {
    return this.calculationResult?.order;
  }

  private getCreditsToUse(
    order: ICalculatedScheduleAndPayOrderDTO
  ): IUsedCreditRow[] {
    return groupUsedCredits(order.credits_used, this.customerCredits.get());
  }

  private getCreditsToBuy(
    order: ICalculatedScheduleAndPayOrderDTO
  ): IBuyCreditRow[] {
    return order.items.map((item) => ({
      credit_type_id: item.credit_type.id,
      is_program_credit: item.credit_type.is_program_credit,
      name: item.credit_type.name,
      quantity: item.quantity,
      is_free: item.credit_type.is_free,
      total: undefined,
    }));
  }

  recalculate = async (): Promise<void> => {
    const linkageState = this.linkageState.get();
    try {
      await super.recalculate();
    } catch (e) {
      console.log(e);
      if (isAbortError(e)) return;
      if (linkageState != null && isLinkedState(linkageState)) {
        notificator.error(
          "Error",
          "One or more participants cannot be scheduled for selected sessions. Please select new items"
        );
      }
      // this.onError();
    }
  };

  proceed = async () => {
    const orderAndAdditionalInfo =
      this.calculationResult ?? raise("Cannot be null");
    const order = orderAndAdditionalInfo.order;
    const orderPayload =
      this.orderPayload.get() ?? raise("Order payload cannot be null on place");
    const orderMode = getOrderMode(order);

    try {
      if (orderMode === "schedule") {
        const attendanceIds = await api.attendances.signUp(
          order.sign_up.schedule
        );
        this.routerStore.navigateToRoute(
          scheduleSuccessRoute.build({
            state: {
              bookingResult: { attendanceIds },
            },
          })
        );
      } else if (orderMode === "complimentary") {
        const order = await api.orders.placeScheduleAndPayOrder({
          ...orderPayload,
          new_customer_email: this.customerEmail.get() ?? undefined,
          payment_type: "stripe",
        });

        if (order.status === "completed") {
          this.routerStore.navigateToRoute(
            scheduleSuccessRoute.build({
              state: {
                bookingResult: {
                  attendanceIds: order.sign_up.created_attendance_ids,
                },
              },
            })
          );
        } else {
          notificator.error("Error!", "Please, try again");
        }
      } else {
        this.paymentStore.setIncompleteOrder({
          type: "schedule_and_pay",
          order: this.calculationResult ?? raise("Cannot be null"),
          payload: this.orderPayload.get() ?? raise(""),
        });
        this.routerStore.navigate(ROUTES.SCHEDULE_CHECKOUT);
      }
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      //fixme: should use generic method to show error messages https://funjoiner.atlassian.net/browse/FJ-1302
      notificator.error("Error!", e);
    }
  };

  private _payloadFromScheduleMap(
    scheduleMap: ScheduleMap
  ): ICalculateScheduleAndPayOrderDTO {
    const bookingData = Object.entries(scheduleMap);
    const bookedPrograms: TBookingDataItemDTO[] = bookingData.flatMap(
      ([student_id, schedule]) =>
        schedule.programs.map((p) => ({
          student_id: +student_id,
          schedule_set_id: p.schedule_set_id,
          program_id: p.id,
        }))
    );
    const bookedSessions: TBookingDataItemDTO[] = bookingData.flatMap(
      ([student_id, schedule]) =>
        schedule.sessions
          .sort((a, b) => (dayjs(a.start).isBefore(b.start) ? -1 : 1))
          .map((s) => ({
            student_id: +student_id,
            schedule_set_id: s.schedule_set_id,
            program_id: s.program_id,
            session_id: s.id,
          }))
    );

    return {
      booking_data: [...bookedPrograms, ...bookedSessions],
      promocode_id: undefined,
      payment_plan_id: undefined,
    };
  }
}

export function isLinkedState(
  state: LinkedScheduleState | UnlinkedScheduleState
): state is LinkedScheduleState {
  return state instanceof LinkedScheduleState;
}

class LinkedScheduleState {
  scheduleVM: ScheduleVM;

  @computed
  get scheduleMap(): ScheduleMap {
    const map: ScheduleMap = {};
    for (const id of this.participantSelection.selectedStudentIds) {
      map[id] = { ...this.schedule };
    }
    return map;
  }

  @computed
  private get schedule() {
    return this.scheduleVM.schedule;
  }

  constructor(
    private availabilityVM: AvailabilityVM,
    private participantSelection: ParticipantSelectionVM
  ) {
    this.scheduleVM = new ScheduleVM(availabilityVM);

    makeObservable(this);
  }

  unlink() {
    return new UnlinkedScheduleState(
      this.availabilityVM,
      this.participantSelection,
      this.schedule
    );
  }
}

class UnlinkedScheduleState {
  @computed
  get scheduleMap(): ScheduleMap {
    const map: ScheduleMap = {};
    for (let [id, vm] of Object.entries(this.scheduleVMs)) {
      map[+id] = { ...vm.schedule };
    }

    return map;
  }

  dispose: () => void;

  @observable
  scheduleVMs: {
    [studentId: number]: ScheduleVM;
  } = {};

  constructor(
    availabilityVM: AvailabilityVM,
    private participantsSelection: ParticipantSelectionVM,
    schedule: Schedule
  ) {
    this.scheduleVMs = this.recreateSchedules(
      () => new ScheduleVM(availabilityVM, schedule)
    );

    this.dispose = reaction(
      () => participantsSelection.selectedParticipantIds,
      () =>
        (this.scheduleVMs = this.recreateSchedules(
          () => new ScheduleVM(availabilityVM)
        ))
    );
    makeObservable(this);
  }

  @action.bound
  private recreateSchedules(onNew: (id: number) => ScheduleVM) {
    const schedules: typeof this.scheduleVMs = {};

    for (let id of this.participantsSelection.selectedParticipantIds) {
      schedules[id] = this.scheduleVMs[id] ?? onNew(id);
    }

    return schedules;
  }
}

const ctx = React.createContext<SchedulePageVM | null>(null);

export interface IProps {
  children: React.ReactNode;
  scheduleToOpen?: TScheduleToOpen;
}

export const SchedulePageVMProvider: React.FC<IProps> = ({
  children,
  scheduleToOpen,
}) => {
  const {
    funboxStore,
    routerStore,
    customerStore,
    paymentStore,
    paymentMethodStore,
    commonStore,
  } = useStores();
  const vm = useMemo(
    () =>
      new SchedulePageVM(
        routerStore,
        paymentStore,
        paymentMethodStore,
        funboxStore,
        customerStore,
        commonStore,
        scheduleToOpen
      ),
    // schedule to open is not a dependency because it is used only once
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [routerStore, paymentStore, funboxStore, customerStore, commonStore]
  );

  useEffect(
    () => () => {
      vm.dispose();
    },
    [vm]
  );

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useSchedulePageVM = () => useVM(ctx);
