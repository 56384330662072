import { defineRoute } from "../../util/typedRouting";

import React from "react";
import { observer } from "mobx-react-lite";
import {
  ApplicationsAndWaitlistsPageVmProvider,
  useApplicationsAndWaitlistsPageVm,
} from "./ApplicationsAndWaitlistsPageVm";
import PageRoot from "../../components/PageRoot";
import PageHeader from "../../components/PageHeader";
import StudentSelector from "../../components/StudentSelector";
import { ApplicationDTO } from "../../services/api/availability";
import cn from "classnames";
import formatStartEndDate from "../../util/formatStartEndDate";
import { Button } from "@sizdevteam1/funjoiner-uikit";
import { ApplicationStatusText } from "../../components/ApplicationStatusText";
import ProgramTile from "../../components/AttendanceTile/ProgramTile";
import { IWaitlistRecordDTO } from "../../services/api/waitlists";

export const applicationsAndWaitlistsPageRoute = defineRoute<{
  searchParams?: {
    studentId?: string;
  };
}>({
  path: "/applications/",
  build: (path) => path,
});

interface IProps {}

const ApplicationsAndWaitlistsPage: React.FC<IProps> = observer((props) => {
  return (
    <ApplicationsAndWaitlistsPageVmProvider>
      <ApplicationsAndWaitlistsPageImpl {...props} />
    </ApplicationsAndWaitlistsPageVmProvider>
  );
});

const ApplicationsAndWaitlistsPageImpl: React.FC<IProps> = observer((props) => {
  const vm = useApplicationsAndWaitlistsPageVm();
  return (
    <PageRoot>
      <PageHeader showBackLink>Applications/Waitlists</PageHeader>
      <StudentSelector
        students={vm.students}
        selectedStudentId={vm.selectedStudentId}
        onSelectStudent={(student) => vm.selectStudent(student.id)}
      />
      {vm.studentApplications.length > 0 && (
        <div className={"mt-6 flex flex-col"}>
          <div className={"flex flex-row gap-2"}>
            <i className={"icon application-icon bg-main-color"} />
            <div className={"typography-h2 text-header-color"}>
              Applications
            </div>
          </div>
          <div className={"mt-4 flex flex-col gap-4"}>
            {vm.studentApplications.map((application) => (
              <ApplicationTile key={application.id} application={application} />
            ))}
          </div>
        </div>
      )}
      {vm.studentWaitlistRecords.length > 0 && (
        <div className={"mt-6 flex flex-col"}>
          <div className={"flex flex-row gap-2"}>
            <i className={"icon waitlist-icon bg-main-color"} />
            <div className={"typography-h2 text-header-color"}>Waitlists</div>
          </div>
          <div className={"mt-4 flex flex-col gap-4"}>
            {vm.studentWaitlistRecords.map((record) => (
              <WaitlistTile key={record.id} waitlistRecord={record} />
            ))}
          </div>
        </div>
      )}
    </PageRoot>
  );
});

const ApplicationTile = observer(
  ({
    application,
    className,
  }: {
    application: ApplicationDTO;
    className?: string;
  }) => {
    const { program, location_name, schedule_set_name } = application;

    return (
      <div
        className={cn(
          "flex flex-col gap-2 overflow-clip rounded-[10px] shadow-big",
          className
        )}
      >
        <div className={"flex flex-col bg-on-main-color px-4 py-3"}>
          <div className={"flex flex-row justify-between"}>
            <div
              className={
                "typography-main_sb max-w-[190px] truncate text-text-color"
              }
            >
              {program.program_type_name}
            </div>
            <div className={"typography-small__t text-text-color"}>
              {formatStartEndDate(program)}
            </div>
          </div>
          <div className={"mt-1 flex flex-row justify-between"}>
            <div
              className={
                "typography-small__t max-w-[190px] truncate text-gray-text-color"
              }
            >
              {schedule_set_name}
            </div>
            <div className={"flex max-w-[140px] flex-row items-center"}>
              <i className={"icon locations-icon icon-sm mr-[2px]"} />
              <div
                className={"typography-small__t  truncate text-gray-text-color"}
              >
                {location_name}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "flex flex-row justify-between bg-table-row-color px-4 py-3"
          }
        >
          <ApplicationStatusText
            status={application.status}
            resubmissionRequiredText={"Resubmission Required"}
          />
          <ApplicationButton application={application} />
        </div>
      </div>
    );
  }
);

const ApplicationButton = observer(
  ({ application }: { application: ApplicationDTO }) => {
    const vm = useApplicationsAndWaitlistsPageVm();

    return (
      <Button
        onClick={() => vm.navigateToApplicationPage(application.id)}
        kind={"text"}
      >
        {application.status === "REJECTED" ? "View Reason" : "View"}
      </Button>
    );
  }
);

const WaitlistTile = observer(
  ({ waitlistRecord }: { waitlistRecord: IWaitlistRecordDTO }) => {
    const vm = useApplicationsAndWaitlistsPageVm();
    return (
      <ProgramTile
        key={waitlistRecord.id}
        attendance={waitlistRecord}
        actions={[
          {
            icon: <i className={"icon big-cross-icon"} />,
            label: "Leave Waitlist",
            onClick: () =>
              vm.leaveWaitlist(
                waitlistRecord.program_id,
                waitlistRecord.student_id,
                waitlistRecord.schedule_set_id
              ),
          },
        ]}
      />
    );
  }
);
export default ApplicationsAndWaitlistsPage;
