import React from "react";
import cn from "classnames";
import {
  CreditIcon,
  formatPrice,
  PriceWithCents,
} from "@sizdevteam1/funjoiner-uikit";

interface IProps {
  credit_type_id: number;
  is_program_credit: boolean;
  is_free: boolean;
  name: string;
  quantity: number;
  price?: number;
  className?: string;
}

const CreditRow: React.FC<IProps> = (props) => {
  const quantity = props.quantity || 0;
  const name = props.name;

  return (
    <div
      className={cn(
        "box-border flex items-center justify-between gap-2 p-4",
        props.className
      )}
    >
      <div className={"flex items-center"}>
        <CreditIcon
          size={24}
          isFree={props.is_free}
          isProgram={props.is_program_credit}
        />
        <div className={"ml-2  flex flex-col gap-1"}>
          <div className={"text-h3 text-header-color"}>{name}</div>
          <div
            className={cn("text-small flex items-center text-gray-text-color")}
          >
            {props.is_free && "Free "}
            {props.is_program_credit ? "Program" : "Session"} Credit ×&nbsp;
            {props.quantity}
          </div>
        </div>
      </div>
      {!!props.price ? (
        <PriceWithCents
          typography="typography-h3"
          amount={props.price * quantity}
        />
      ) : (
        <i className="icon check-in-object-icon bg-icon-dark-green-color" />
      )}
    </div>
  );
};

export default CreditRow;
