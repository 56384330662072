import React from "react";
import { observer } from "mobx-react-lite";
import PageRoot from "src/components/PageRoot";
import { Button } from "@sizdevteam1/funjoiner-uikit";
import LocationSelect from "src/components/LocationSelect";
import MainSection from "./components/MainSection";
import PageHeader from "../../components/PageHeader";

import {
  AvailabilityPageVMProvider,
  useAvailabilityPageVM,
} from "./AvailablilityPageVM";
import InfoMessage from "src/components/InfoMessage/InfoMessage";
import AvailabilityFiltersModal, {
  AvailabilityFiltersButton,
} from "../../components/AvailabilityFiltersModal/AvailabilityFiltersModal";
import useStores from "src/hooks/useStores";
import ScheduleSetDescriptionModal from "src/components/ScheduleSetDescriptionModal/ScheduleSetDescriptionModal";
import { ROUTES } from "../../stores/RouterStore";
import SelectLocationModal from "src/components/SelectLocationModal/SelectLocationModal";

const AvailabilityPage = observer(() => {
  const { funboxStore, routerStore } = useStores();

  if (funboxStore.selectedFunbox == null) {
    // Redirect component doesn't write from state. have to use navigate.
    // SetTimeout is used to make sure that the route is changed after the page is rendered
    setTimeout(() => {
      routerStore.navigate(ROUTES.SELECT_FUNBOX_PAGE, {
        replace: true,
        rewriteFromOnReplace: true,
      });
    });

    return null;
  }
  if (funboxStore.selectedLocation == null) {
    // Redirect component doesn't write from state. have to use navigate.
    // SetTimeout is used to make sure that the route is changed after the page is rendered
    setTimeout(() => {
      routerStore.navigate(ROUTES.LOCATIONS, {
        replace: true,
        rewriteFromOnReplace: true,
      });
    });

    return null;
  }
  return (
    <AvailabilityPageVMProvider>
      <AvailabilityPageImpl />
    </AvailabilityPageVMProvider>
  );
});

const AvailabilityPageImpl = observer(() => {
  const vm = useAvailabilityPageVM();

  const { funboxStore } = useStores();

  return (
    <PageRoot className="!pb-0">
      <PageHeader showBackLink>View Availability</PageHeader>
      {vm.isBuyCreditsAndSaveEnabled && funboxStore.hasPaidCreditTypes && (
        <div className={"ml-[10px] flex items-center gap-1"}>
          <div className={"typography-main text-gray-text-color"}>or</div>
          <Button kind={"text"} onClick={vm.toFlexiblePayments}>
            <i className={"icon credit-types-icon icon-lg"} />
            Buy Credits
          </Button>
        </div>
      )}
      <div className={"mt-2 flex items-start justify-between gap-3"}>
        <LocationSelect
          className={"ml-[10px]"}
          selectedLocation={vm.selectedLocation}
          onClick={() => (vm.isSelectLocationModalOpen = true)}
        />
        <AvailabilityFiltersButton vm={vm.availabilityVM.filtersVM} />
      </div>
      <div className="typography-main mt-4 mb-3 text-gray-text-color">
        <span>
          This option allows you to check schedule availability. Next, you may
        </span>
        {vm.isBuyCreditsAndSaveEnabled && <span> buy credits or</span>}
        <span> schedule dates and pay.</span>
      </div>
      <div>
        {!vm.selectedLocation ? (
          <InfoMessage className="px-4 py-8">
            Select Location to View Available Dates
          </InfoMessage>
        ) : (
          <MainSection />
        )}
      </div>
      <div className="sticky bottom-3 mt-auto w-full">
        <Button className="w-full" size={"big"} onClick={vm.toSchedule}>
          Schedule & Reserve Now
        </Button>
      </div>
      <AvailabilityFiltersModal vm={vm.availabilityVM.filtersVM} />
      {vm.setToInitiallyOpenInDescriptionModal && (
        <ScheduleSetDescriptionModal
          descriptionData={{
            description:
              vm.setToInitiallyOpenInDescriptionModal.selected_description,
            funbox_mode: vm.setToInitiallyOpenInDescriptionModal.funbox_mode,
            location_id: vm.setToInitiallyOpenInDescriptionModal.location_id,
            funbox_id: vm.setToInitiallyOpenInDescriptionModal.funbox_id,
            schedule_set_id: vm.setToInitiallyOpenInDescriptionModal.id,
            set_start_date: vm.setToInitiallyOpenInDescriptionModal.start_date,
            set_end_date: vm.setToInitiallyOpenInDescriptionModal.end_date,
            first_nearby_session: vm.firstNearbySession,
          }}
          isNavigateToScheduleButtonVisible={true}
          onClose={() => (vm.setToInitiallyOpenInDescriptionModal = undefined)}
        />
      )}
      {vm.isSelectLocationModalOpen &&
        vm.selectedFunbox &&
        vm.selectedLocation && (
          <SelectLocationModal
            onClose={() => (vm.isSelectLocationModalOpen = false)}
            isShowingViewMoreButton={vm.isAtLeastOneOtherFunboxAvailable}
            selectedFunbox={vm.selectedFunbox}
            selectedLocation={vm.selectedLocation}
            onSelectLocation={funboxStore.selectLocationId}
            useGlobalFolderSelector={true}
          />
        )}
    </PageRoot>
  );
});

export default AvailabilityPage;
