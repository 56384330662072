import React from "react";
import cn from "classnames";
import dayjs from "dayjs";
import { Button } from "@sizdevteam1/funjoiner-uikit";
import { TDocumentDTO } from "src/services/api";
import styles from "../styles.module.scss";
import { useDocumentsPageVM } from "../DocumentsPageVM";
import { isSignedByCustomer } from "src/util/isSignedByCustomer";
import { observer } from "mobx-react-lite";

interface IProps {
  document: TDocumentDTO;
}

function iconClassName(doc: TDocumentDTO) {
  let className: string;
  if (doc.signed) {
    if (doc.expired) {
      className = "disconnect-icon";
    } else {
      className = "doc-icon !bg-icon-dark-green-color";
    }
  } else {
    className = "doc-blank-icon";
  }

  return className;
}

const DocumentItem: React.FC<IProps> = observer(({ document }) => {
  const vm = useDocumentsPageVM();
  const { expired } = document;
  const signedByCustomer = isSignedByCustomer(document);
  const hasDownloadUrl = document.download_url != null;
  return (
    <div
      onClick={() => vm.openSignedDocument(document.id)}
      className={cn(
        signedByCustomer ? styles.filledDocument : styles.document,
        hasDownloadUrl && "cursor-pointer",
        {
          [styles.expired]: expired,
        }
      )}
    >
      <div className="mr-2 flex-grow">
        <i className={`icon !min-w-[24px] ${iconClassName(document)}`} />
        <div className={cn("text-h3", styles.docName)}>{document.name}</div>
      </div>
      {signedByCustomer ? (
        <Status doc={document} />
      ) : (
        <Button
          className="flex-shrink-0"
          autoLoading
          kind={"text"}
          onClick={() => vm.signDocument(document.id)}
        >
          {document.resubmission_reason != null ? "Resubmit" : "Sign Now"}
        </Button>
      )}
    </div>
  );
});

const Status: React.FC<{ doc: TDocumentDTO }> = observer(({ doc }) => {
  const { expired, expiration_date } = doc;
  if (expiration_date == null) return null;

  return (
    <div className="flex flex-col">
      <div className={"text-small"}>
        {expired ? "Expired on" : "Valid until"}
      </div>
      <div className={"text-small"}>
        {dayjs(expiration_date).format("M/D/YYYY")}
      </div>
    </div>
  );
});

export default DocumentItem;
