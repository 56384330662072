import { action, computed, makeObservable, observable } from "mobx";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import useStores from "src/hooks/useStores";
import useVM from "src/hooks/useVM";
import api, {
  IAvailableDaycampSession,
  ICreditTypeDTO,
  IFunboxDTO,
  ILocationDTO,
  IStudentDTO,
  TAttendanceDTO,
} from "src/services/api";
import notificator from "src/services/systemNotifications/notificationCenterService";
import { CommonStore, CustomerStore, RouterStore } from "src/stores";

import { ROUTES } from "src/stores/RouterStore";
import {
  ProgramsVM,
  SessionsVM,
} from "src/stores/ScheduleStore/Programs&SessionsVMs";
import { AvailabilityVM } from "../AvailabilityPage/components/AvailabilityVM";
import { TAvailableProgram } from "src/services/api/availability";
import { isPolicyCompliant } from "../../util/isPolicyCompliant";
import FunboxStore from "src/stores/FunboxStore";
import { findById } from "@sizdevteam1/funjoiner-uikit";
import getAge from "src/util/getAge";
import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";

export class CancelOrReschedulePageVM {
  readonly isProgram;

  @observable.ref
  from: FromState;

  @observable.ref
  to: ToState;

  constructor(
    private attendance: TAttendanceDTO,
    public readonly attendanceFunbox: IFunboxDTO,
    private commonStore: CommonStore,
    private routerStore: RouterStore,
    private customerStore: CustomerStore,
    private funboxStore: FunboxStore
  ) {
    makeObservable(this);
    if (this.attendance.credit.unpaid_payment_plan) {
    }
    this.isProgram = this.attendance.type !== "session_in_program";
    this.from = new FromState(
      this.attendance,
      this.attendanceFunbox,
      this.commonStore
    );
    this.to = new ToState(
      this.isProgram,
      this.routerStore,
      attendanceFunbox,
      this.attendance.location_id,
      this.from.creditType,
      this.student,
      this.attendance.credit.unpaid_payment_plan?.reschedule_after_date
    );
  }

  @observable localSelectedLocationId = this.attendance.location_id;

  @computed get localSelectedLocation() {
    return findById(
      this.localSelectedLocationId,
      this.funboxStore.activeLocations
    );
  }

  @observable isSelectLocationModalOpen = false;

  @action.bound
  selectLocalLocation(location_id: number) {
    this.localSelectedLocationId = location_id;
    this.to.dispose();
    this.to = new ToState(
      this.isProgram,
      this.routerStore,
      this.attendanceFunbox,
      this.localSelectedLocationId,
      this.from.creditType,
      this.student
    );
  }
  @computed
  get student(): IStudentDTO {
    return findById(
      this.from.attendance.student_id,
      this.customerStore.studentsWithCustomerAsParticipant
    );
  }

  @action
  public reschedule = async () => {
    const from = this.from;
    const to = this.to;

    if (!to.isRescheduleAllowed) return;

    try {
      await api.attendances.reschedule(
        from.attendance.id,
        (() => {
          if (this.isProgram) {
            return {
              new_program_id: to.programsVM.selected[0].id,
              new_schedule_set_id: to.programsVM.selected[0].schedule_set_id,
            };
          } else {
            const selectedSession = to.sessionsVM.selected[0];
            return {
              new_schedule_set_id: selectedSession.schedule_set_id,
              new_program_id: selectedSession.program_id,
              new_session_id: selectedSession.id,
            };
          }
        })()
      );
      this.routerStore.navigate(ROUTES.THANKYOU, { replace: true });
    } catch (e) {
      Sentry.captureException(e);
      notificator.error("Failed to reschedule", e);
    }
  };

  @action
  public cancel = async () => {
    const from = this.from;
    if (from === undefined) return;
    try {
      await api.attendances.cancel(from.attendance.id);
      await this.customerStore.loadCredits();
      notificator.success("Success!", "Schedule Successfully Canceled");
      this.routerStore.history.goBack();
    } catch (e) {
      Sentry.captureException(e);
      notificator.error("Error!", e);
    }
  };

  dispose() {
    this.to.dispose();
  }
}

class FromState {
  constructor(
    public readonly attendance: TAttendanceDTO,
    public readonly attendanceFunbox: IFunboxDTO,
    private readonly commonStore: CommonStore
  ) {
    makeObservable(this);
  }

  @computed
  get location(): ILocationDTO {
    return this.commonStore.locationsById[this.attendance.location_id];
  }

  @computed
  get creditTypeName(): string {
    return this.creditType.name;
  }

  @computed
  get scheduleSetName(): string {
    return this.attendance.schedule_set_name;
  }

  @computed
  get programTypeName(): string {
    return this.attendance.program_type_name;
  }

  @computed
  get creditType(): ICreditTypeDTO {
    return this.commonStore.creditTypesById[this.attendance.credit_type_id];
  }

  @computed
  get isCancelAllowed(): boolean {
    return isPolicyCompliant(
      {
        isProgram:
          this.attendance.type === "program" ||
          this.attendance.type === "program_of_sessions",
        start: this.attendance.start,
      },
      this.creditType.is_free
        ? this.attendanceFunbox.free_credits_settings.cancel_policy
        : this.attendanceFunbox.paid_credits_settings.cancel_policy
    );
  }

  @computed
  get isRescheduleAllowed(): boolean {
    return isPolicyCompliant(
      {
        isProgram:
          this.attendance.type === "program" ||
          this.attendance.type === "program_of_sessions",
        start: this.attendance.start,
      },
      this.creditType.is_free
        ? this.attendanceFunbox.free_credits_settings.reschedule_policy
        : this.attendanceFunbox.paid_credits_settings.reschedule_policy
    );
  }
}

class ToState {
  @observable.ref
  sessionsVM: SessionsVM;

  @observable.ref
  programsVM: ProgramsVM;

  @observable
  isSelectionConfirmed = false;

  @observable.ref
  availabilityVM: AvailabilityVM;

  constructor(
    public isProgram: boolean,
    routerStore: RouterStore,
    selectedFunbox: IFunboxDTO,
    defaultSelectedLocationId: number,
    fromCreditType: ICreditTypeDTO,
    student: IStudentDTO,
    minRescheduleDate?: ISOString
  ) {
    makeObservable(this);
    this.availabilityVM = new AvailabilityVM(
      routerStore,
      computed(() => selectedFunbox),
      computed(() => defaultSelectedLocationId),
      "reschedule_page",
      undefined,
      minRescheduleDate
    );
    this.availabilityVM.filtersVM.programTypeVm.setValue(
      fromCreditType.active_program_type_ids
    );
    this.availabilityVM.filtersVM.setAllOrAvailable("available");
    this.availabilityVM.filtersVM.setAges(
      getAge(student.birth_date),
      getAge(student.birth_date)
    );
    this.availabilityVM.filtersVM.apply();
    this.programsVM = new RescheduleProgramsVM(this.availabilityVM);
    this.sessionsVM = new RescheduleSessionsVM(this.availabilityVM);
  }

  @computed
  get noAvailability(): boolean {
    const availability = this.isProgram
      ? this.availabilityVM.availability.scheduleSets
      : this.availabilityVM.availability.type === "daycamp"
      ? this.availabilityVM.availability.sessions
      : [];

    return availability.length > 0;
  }

  @computed
  get isSelected(): boolean {
    const selectables = (this.isProgram ? this.programsVM : this.sessionsVM)
      .selected;
    return selectables.length > 0;
  }

  @computed
  get isRescheduleAllowed(): boolean {
    return this.isSelected && this.isSelectionConfirmed;
  }
  dispose() {
    this.availabilityVM.dispose();
  }
}

class RescheduleProgramsVM extends ProgramsVM {
  getSelectedSessionIds(): Set<string> {
    return new Set();
  }

  constructor(public availabilityVM: AvailabilityVM) {
    super();
    makeObservable(this);
  }

  @observable
  selected: TAvailableProgram[] = [];

  get availability() {
    return this.availabilityVM.availability;
  }

  @action.bound
  toggle(program: TAvailableProgram) {
    const index = this.selected.findIndex((p) => p.id === program.id);
    if (index === -1) {
      this.selected = [program];
    } else {
      this.selected = [];
    }
  }
}
class RescheduleSessionsVM extends SessionsVM {
  getSelectedProgramIds(): Set<string> {
    return new Set();
  }
  @observable
  selected: IAvailableDaycampSession[] = [];

  constructor(public availabilityVM: AvailabilityVM) {
    super();
    makeObservable(this);
  }

  @action.bound
  toggleSession(session: IAvailableDaycampSession) {
    const index = this.selected.findIndex((s) => s.id === session.id);
    if (index === -1) {
      this.selected = [session];
    } else {
      this.selected = [];
    }
  }

  get availability(): IAvailableDaycampSession[] {
    if (this.availabilityVM.availability.type !== "daycamp") return [];
    return this.availabilityVM.availability.sessions;
  }

  @action.bound toggle(session: IAvailableDaycampSession) {
    const index = this.selected.findIndex((p) => p.id === session.id);
    if (index === -1) {
      this.selected = [session];
    } else {
      this.selected = [];
    }
  }
}
const ctx = React.createContext<CancelOrReschedulePageVM | null>(null);

interface ICancelOrRescheduleVMProviderProps {
  attendance: TAttendanceDTO;
  attendanceFunbox: IFunboxDTO;
}

export const CancelOrRescheduleVMProvider: React.FC<ICancelOrRescheduleVMProviderProps> =
  observer(({ children, attendanceFunbox, attendance }) => {
    const { commonStore, routerStore, customerStore, funboxStore } =
      useStores();

    const vm = useMemo(
      () =>
        new CancelOrReschedulePageVM(
          attendance,
          attendanceFunbox,
          commonStore,
          routerStore,
          customerStore,
          funboxStore
        ),
      [
        attendance,
        attendanceFunbox,
        commonStore,
        routerStore,
        customerStore,
        funboxStore,
      ]
    );

    useEffect(
      () => () => {
        vm.dispose();
      },
      [vm]
    );

    return <ctx.Provider value={vm}>{children}</ctx.Provider>;
  });

export const useCancelOrReschedulePageVM = () => useVM(ctx);
