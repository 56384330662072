import React from "react";
import { observer } from "mobx-react-lite";
import {
  ApplicationPageVmProvider,
  useApplicationPageVm,
} from "./ApplicationPageVm";
import PageRoot from "../../components/PageRoot";
import PageHeader from "../../components/PageHeader";
import { defineRoute } from "../../util/typedRouting";
import { ApplicationStatusText } from "../../components/ApplicationStatusText";
import {
  Avatar,
  Button,
  Dropdown,
  exists,
  Modal,
  raise,
} from "@sizdevteam1/funjoiner-uikit";
import cn from "classnames";
import Invoice from "../DashboardPage/components/Invoice";
import { StrikethroughDivider } from "../../components/StrikethroughDivider";
import ProgramTile from "../../components/AttendanceTile/ProgramTile";

export const applicationPageRoute = defineRoute<{
  pathParams: {
    application_id: string;
  };
  searchParams?: {
    document_id?: string;
  };
}>({
  path: "/applications/:application_id",
  build: (path, { application_id }) => {
    return path.replace(":application_id", application_id);
  },
});
interface IProps {}

const ApplicationPage: React.FC<IProps> = observer((props) => {
  return (
    <ApplicationPageVmProvider>
      <ApplicationPageImpl {...props} />
    </ApplicationPageVmProvider>
  );
});

const ApplicationPageImpl: React.FC<IProps> = observer((props) => {
  const vm = useApplicationPageVm();
  if (vm.application == null) {
    return null;
  }

  return (
    <PageRoot>
      <PageHeader showBackLink={true}>
        <div className={"flex flex-row justify-between"}>
          Application
          <DropdownMenu />
        </div>
      </PageHeader>
      <Header />
      {vm.application.status === "AWAITING_PAYMENT" && (
        <Invoice
          className={"mt-6"}
          vm={vm.invoiceVm}
          invoice={vm.application.order!}
        />
      )}
      {vm.application.reject_reason != null && (
        <DeclineReason className={"mt-6"} />
      )}
      {vm.smartForms.length > 0 && <RequiredForms className={"mt-6"} />}
      {vm.documents.length > 0 && <RequiredDocuments className={"mt-6"} />}
      <StrikethroughDivider className={"mt-6"}>
        Schedule Details
      </StrikethroughDivider>
      <ScheduleDetails className={"mt-6"} />
      {vm.withdrawalModal != null && <WithdrawalModal />}
    </PageRoot>
  );
});

const WithdrawalModal = observer(() => {
  const modal =
    useApplicationPageVm().withdrawalModal ??
    raise("Withdrawal Modal is not defined");

  return (
    <Modal
      headerText={"Withdraw Application"}
      containerClassName={"w-[366px]"}
      onClose={modal.onClose}
    >
      <div
        className={
          "typography-main flex flex-col gap-[7px] text-center text-text-color"
        }
      >
        <div>
          Are you sure you want to Withdraw this Application? It will be removed
          from your Applications list.{" "}
        </div>
        <div>
          All signed Documents and completed Smart Forms will remain on your
          Profile.
        </div>
        <div>This action is irreversible</div>
      </div>
      <div className={"mt-4 flex flex-row gap-3"}>
        <Button
          className={"flex-1"}
          size={"big"}
          kind={"secondary"}
          onClick={modal.onClose}
        >
          Nevermind
        </Button>
        <Button
          className={"flex-1"}
          size={"big"}
          color={"red"}
          autoLoading={true}
          onClick={modal.onConfirm}
        >
          Withdraw
        </Button>
      </div>
    </Modal>
  );
});
const ScheduleDetails = observer(({ className }: { className: string }) => {
  const application = useApplicationPageVm().application;
  if (application == null) {
    return null;
  }
  return (
    <ProgramTile
      className={className}
      actions={[]}
      attendance={{
        start_date: application.program.start_date,
        end_date: application.program.end_date,
        location_id: application.location_id,
        schedule_set_name: application.schedule_set_name,
        program_type_name: application.program.program_type_name,
      }}
    />
  );
});

const DropdownMenu = observer(() => {
  const vm = useApplicationPageVm();
  if (vm.application == null) {
    return null;
  }
  return (
    <Dropdown
      showLoaderOnOptionsActions
      base={<i className={"icon more-options-icon icon-hover"} />}
      options={[
        vm.applicationPolicy && (
          <div onClick={vm.openApplicationPolicy}>
            <i className={"icon doc-icon"} />
            Application Policy
          </div>
        ),

        vm.application.status === "REJECTED" ? (
          <div onClick={vm.archiveApplication}>
            <i className={"icon archive-icon"} />
            Archive Application
          </div>
        ) : (
          <div onClick={vm.withdrawApplication}>
            <i className={"icon big-cross-icon"} />
            Withdraw Application
          </div>
        ),
      ].filter(exists)}
    />
  );
});
const Header = observer(() => {
  const { application } = useApplicationPageVm();
  if (application == null) {
    return null;
  }

  return (
    <div className={"flex flex-row justify-between"}>
      <div className={"flex flex-col items-start gap-2"}>
        <div className={"typography-label text-gray-text-color"}>
          Participant
        </div>
        <div className={"flex flex-row items-center gap-2"}>
          <Avatar person={application.student} avatarSize={"32px"} />
          <div className={"typography-h3 text-text-color"}>
            {application.student.full_name}
          </div>
        </div>
      </div>

      <div className={"flex flex-col items-start gap-3"}>
        <div className={"typography-label text-gray-text-color"}>Status</div>
        <ApplicationStatusText
          status={application.status}
          resubmissionRequiredText={"Resubmit"}
        />
      </div>
    </div>
  );
});

const DeclineReason = observer(({ className }: { className: string }) => {
  const { application, archiveApplication } = useApplicationPageVm();
  if (application == null) {
    return null;
  }
  return (
    <div
      className={cn(
        "rounded-[10px] border border-solid border-icon-red-color bg-on-main-color px-4 py-3",
        className
      )}
    >
      <div className={"typography-label text-icon-red-color"}>
        Decline Reason
      </div>
      <div className={"typography-main mt-2 text-text-color"}>
        {application.reject_reason}
      </div>
      <Button
        autoLoading
        onClick={archiveApplication}
        kind={"icon"}
        className={"mx-auto mt-3 gap-[6px]"}
      >
        <i className={"icon archive-icon bg-main-color"} />
        Archive
      </Button>
    </div>
  );
});
const RequiredForms = observer(({ className }: { className?: string }) => {
  const vm = useApplicationPageVm();

  return (
    <div className={cn("flex flex-col gap-4", className)}>
      <div className={"typography-h2 text-text-color"}>Required Forms</div>
      {vm.smartForms.map((smartForm) => (
        <ListTile
          isComplete={smartForm.status !== "INCOMPLETE"}
          onResubmit={() => vm.navigateToSmartForm(smartForm.id)}
        >
          {smartForm.name}
        </ListTile>
      ))}
    </div>
  );
});

const RequiredDocuments = observer(({ className }: { className?: string }) => {
  const vm = useApplicationPageVm();
  return (
    <div className={cn("flex flex-col gap-4", className)}>
      <div className={"typography-h2 text-text-color"}>Required Documents</div>
      {vm.documents.map((document) => (
        <ListTile
          isComplete={document.signed || vm.isPollingDocument(document.id)}
          onResubmit={() => vm.navigateToDocumentSignature(document.id)}
        >
          {document.name}
        </ListTile>
      ))}
    </div>
  );
});

const ListTile = observer(
  ({
    children: title,
    isComplete,
    onResubmit,
  }: {
    children: string;
    isComplete: boolean;
    onResubmit: () => void;
  }) => {
    return (
      <div
        className={
          "flex flex-row gap-2 rounded-[10px] bg-on-main-color p-4 shadow-big"
        }
      >
        {isComplete && (
          <i
            className={"icon check-in-object-2-icon bg-icon-dark-green-color"}
          />
        )}
        <div className={"typography-main_sb flex-1 text-text-color"}>
          {title}
        </div>
        {!isComplete && (
          <Button onClick={onResubmit} kind={"text"}>
            Resubmit
          </Button>
        )}
      </div>
    );
  }
);
export default ApplicationPage;
