import React from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Input,
  Label,
  PriceWithCents,
} from "@sizdevteam1/funjoiner-uikit";
import {
  AddPromocodeButtonVMProvider,
  useAddPromocodeButtonVM,
} from "./AddPromocodeButtonVM";
import cn from "classnames";
import { useCheckoutAndPaymentPageVM } from "src/pages/Checkout&Payment/Checkout&PaymentVM";

interface IProps {}

const AddPromocodeButton: React.FC<IProps> = (props) => (
  <AddPromocodeButtonVMProvider>
    <AddPromocodeButtonImpl {...props} />
  </AddPromocodeButtonVMProvider>
);

const AddPromocodeButtonImpl: React.FC<IProps> = observer((props: IProps) => {
  const vm = useAddPromocodeButtonVM();
  if (!vm.isPromocodeInputOpen) {
    return (
      <Button
        kind="text"
        className="h-[48px] !bg-table-row-color p-3"
        onClick={() => vm.setIsPromocodeInputOpen(true)}
      >
        <i className="icon small-add-icon" />
        <span className="ml-1">Add Promo Code</span>
      </Button>
    );
  }

  return (
    <div className="flex flex-col gap-2 bg-table-row-color px-3 py-4">
      {vm.isPromocodeApplied ? <AppliedPromocode /> : <PromocodeInput />}
    </div>
  );
});

const AppliedPromocode = observer(() => {
  const vm = useAddPromocodeButtonVM();
  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-row items-center justify-between">
        <Label className={"!mb-0"}>Promo Code</Label>
        <Button
          kind="text"
          className="text-h4 !max-h-3 !w-auto !p-0 text-main-color"
          onClick={vm.removePromocode}
          disabled={vm.isRemoveDisabled}
        >
          Remove
        </Button>
      </div>
      {vm.appliedPromocode && (
        <div className="flex flex-row items-center justify-between">
          <div className="flex items-center gap-1">
            <i className="icon promocodes-icon" />
            <span className="typography-button text-text-color">
              {vm.appliedPromocode.id}
            </span>
          </div>
          <div className="typography-label text-text-color">
            <PriceWithCents
              amount={vm.appliedPromocode.discount}
              prefix="-$"
              typography="typography-label"
            />
          </div>
        </div>
      )}
    </div>
  );
});

const PromocodeInput = observer(() => {
  const vm = useAddPromocodeButtonVM();
  const checkoutVM = useCheckoutAndPaymentPageVM();

  return (
    <>
      <div className="flex h-6 items-center justify-between">
        <Label className={"!mb-0"}>Promo Code</Label>
        <Button
          kind="text"
          className={cn(
            "pointer-events-none  !w-fit opacity-0 transition-all",
            vm.promocodeError && "pointer-events-auto visible opacity-100"
          )}
          onClick={() => (vm.draftPromocode = "")}
        >
          Clear
        </Button>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          !vm.isApplyDisabled && vm.applyPromocode();
        }}
      >
        <Input
          maxLength={24}
          onBlur={() => {
            setTimeout(() => (checkoutVM.isPromoInputFocused = false), 100);
          }}
          onFocus={() => (checkoutVM.isPromoInputFocused = true)}
          isError={vm.promocodeError !== ""}
          value={vm.draftPromocode}
          onChange={(v) => (vm.draftPromocode = v.currentTarget.value)}
          trailing={{
            width: 84,
            node: (
              <Button
                kind="text"
                className={cn("!w-auto", {
                  "!text-gray-text-color": vm.isApplyDisabled,
                })}
                disabled={vm.isApplyDisabled}
              >
                Apply
              </Button>
            ),
          }}
        />
      </form>
      {vm.availablePromocodes.length > 0 && (
        <div className="textcolor flex flex-wrap items-center gap-x-3">
          <span className="text-label text-text-color">Available:</span>
          {vm.availablePromocodes.map((e) => (
            <Button
              key={e.id}
              kind="text"
              className="text-label !w-auto cursor-pointer !px-0 text-main-color"
              onClick={() => vm.onPromocodeClicked(e.id)}
            >
              {e.id}
            </Button>
          ))}
        </div>
      )}
    </>
  );
});
export default AddPromocodeButton;
