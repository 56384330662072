import {
  Avatar,
  Button,
  ISOString,
  pluralize,
} from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import React from "react";
import { IStudentDTO } from "src/services/api";
import formatStartEndDate from "src/util/formatStartEndDate";
import { StudentRestrictions } from "./ProgramCard";
import dayjs from "dayjs";
import {
  IAvailableSessionInProgram,
  SessionsDisplayOption,
  TAvailableProgram,
} from "src/services/api/availability";
import formatStartEndTime from "src/util/formatStartEndTime";
import isDaycampProgram from "src/util/isDayCampProgram";
import { twMerge } from "tailwind-merge";
import {
  getDaysWithSessions,
  getMonthsWithSessions,
  getWeeksWithSessions,
} from "src/util/groupSessions";
import { ExpandableDateRow } from "../GroupedSessions/GroupedSessions";

type AttendanceKindWithDates =
  | { kind: "session"; date: ISOString }
  | {
      kind: "program";
      start_date: ISOString;
      end_date: ISOString;
    };

export type RestrictionStatus =
  | {
      type: "booked";
      attendance: AttendanceKindWithDates;
    }
  | {
      type: "already_selected";
      attendance: AttendanceKindWithDates;
    }
  | {
      type: "age_restricted";
      program_type_from_age: number | null;
      program_type_to_age: number | null;
      actual_age: number;
    }
  | {
      type: "on_waitlist";
    }
  | {
      type: "applied";
    };

export const RestrictionsBlock = ({
  restrictions,
}: {
  restrictions: StudentRestrictions[];
}) => {
  return (
    <div className="flex flex-col gap-2">
      {restrictions.map((rest) =>
        rest.statuses.map((status, index) => (
          <StudentRestriction
            key={index}
            student={rest.student}
            status={status}
          />
        ))
      )}
    </div>
  );
};

export const StudentRestriction = ({
  status,
  student,
}: {
  status: RestrictionStatus;
  student: IStudentDTO;
}) => {
  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-[6px] overflow-hidden">
        <Avatar person={student} avatarSize="24px" />
        <div className="typography-label  truncate text-text-color">
          {student.first_name}
        </div>
        {(status.type === "already_selected" || status.type === "booked") && (
          <div
            className={classNames(
              "typography-small__t whitespace-nowrap",
              status.attendance.kind === "program"
                ? "text-dark-main-color"
                : "text-surface-orange-dark-color"
            )}
          >
            {status.attendance.kind === "session"
              ? dayjs(status.attendance.date).format("MMM Do")
              : `${formatStartEndDate({
                  start_date: status.attendance.start_date,
                  end_date: status.attendance.end_date,
                })}`}
          </div>
        )}
        {status.type === "age_restricted" && (
          <div className="typography-small__t whitespace-nowrap text-dark-red-color">
            {status.actual_age} yrs
          </div>
        )}
      </div>
      {status.type === "on_waitlist" && <OnWaitlistLabel />}
      {status.type === "applied" && <AppliedLabel />}
      {status.type === "already_selected" && <SelectedLabel />}

      {status.type === "age_restricted" && (
        <AgeRestrictionLabel
          from_age={status.program_type_from_age}
          to_age={status.program_type_to_age}
        />
      )}
      {status.type === "booked" && <BookedLabel />}
    </div>
  );
};

const labelClass = "px-[6px] py-[1px] typography-small__t rounded-[4px] flex";

export const AgeRestrictionLabel = ({
  from_age,
  to_age,
}: {
  from_age: number | null;
  to_age: number | null;
}) => {
  if (!from_age && !to_age) {
    return null;
  }

  const getText = (from_age: number | null, to_age: number | null) => {
    if (from_age && to_age) {
      if (from_age === to_age) {
        return `${from_age} yrs`;
      }
      return `${from_age}-${to_age} yrs`;
    }
    if (from_age) {
      return `${from_age}+ yrs`;
    }
    if (to_age) {
      return `Up to ${to_age} yrs`;
    }
  };

  return (
    <div
      className={classNames(
        labelClass,
        "w-fit whitespace-nowrap border-[1px] border-solid border-main-color bg-on-main-color  text-text-color"
      )}
    >
      {getText(from_age, to_age)}
    </div>
  );
};

export const OnWaitlistLabel = () => (
  <div
    className={classNames(
      labelClass,
      "box-border  h-[22px] items-center gap-1 whitespace-nowrap  bg-dark-main-color  text-on-main-color"
    )}
  >
    <i className="icon check-in-object-icon h-4 w-4 bg-on-main-color" />
    On WaitList
  </div>
);

export const BookedLabel = () => (
  <div
    className={classNames(
      labelClass,
      "box-border  h-[22px] items-center gap-1 whitespace-nowrap  bg-dark-main-color  text-on-main-color"
    )}
  >
    <i className="icon check-in-object-icon h-4 w-4 bg-on-main-color" />
    Booked
  </div>
);

export const AppliedLabel = () => (
  <div
    className={classNames(
      labelClass,
      "box-border  h-[22px] items-center gap-1 whitespace-nowrap  bg-dark-main-color  text-on-main-color"
    )}
  >
    <i className="icon check-in-object-icon h-4 w-4 bg-on-main-color" />
    Applied
  </div>
);

export const SelectedLabel = () => (
  <div
    className={classNames(
      labelClass,
      "box-border  h-[22px] items-center gap-1 whitespace-nowrap  bg-icon-in-input-color text-on-main-color"
    )}
  >
    Selected
  </div>
);

export const SessionsOpenLabel = ({ withIcon }: { withIcon: boolean }) => {
  return (
    <div
      className={classNames(
        labelClass,
        "w-min justify-center gap-1 bg-surface-orange-dark-color  text-on-main-color",
        withIcon ? "flex-wrap" : "flex-nowrap"
      )}
    >
      Sessions
      <div className="flex items-center gap-1">
        {withIcon && <i className="icon day-icon h-4 w-4 bg-on-main-color" />}
        Open
      </div>
    </div>
  );
};

export const ApplicationsOpenLabel = ({ withIcon }: { withIcon: boolean }) => {
  return (
    <div
      className={classNames(
        labelClass,
        "w-min justify-center  gap-1  bg-main-color text-on-main-color",
        withIcon ? "flex-wrap" : "flex-nowrap"
      )}
    >
      Applications
      <div className="flex items-center gap-1">
        {withIcon && (
          <i className="icon application-icon h-4 w-4 bg-on-main-color" />
        )}
        Open
      </div>
    </div>
  );
};

export const WaitListOpenLabel = ({ withIcon }: { withIcon: boolean }) => {
  return (
    <div
      className={classNames(
        labelClass,
        "w-min  justify-center gap-1 rounded-[4px]  bg-main-color text-on-main-color",
        withIcon ? "flex-wrap" : "flex-nowrap"
      )}
    >
      Waitlist
      <div className="flex items-center gap-1">
        {withIcon && (
          <i className="icon waitlist-icon h-4 w-4 bg-on-main-color" />
        )}
        Open
      </div>
    </div>
  );
};

export const SpotsOpenLabel = ({ spots }: { spots: number }) => {
  return (
    <div
      className={classNames(
        labelClass,
        "box-border  h-[22px] justify-center gap-1 whitespace-nowrap  bg-surface-purple-color text-on-main-color"
      )}
    >
      {spots} {pluralize("Spot", spots)} Open!
    </div>
  );
};

export const DateLabel = ({
  start_date,
  end_date,
}: {
  start_date: string;
  end_date?: string;
}) => {
  return (
    <div
      className={classNames(
        labelClass,
        "box-border flex h-[22px]   justify-center gap-1  bg-dark-main-color text-on-main-color"
      )}
    >
      {start_date && end_date
        ? formatStartEndDate({ start_date, end_date })
        : dayjs(start_date).format("MMM Do")}
    </div>
  );
};

export const NoSpotsOpenBlock = ({ text }: { text: string }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="typography-small__t text-gray-text-color">{text}</div>
      <div
        className={classNames(
          labelClass,
          "box-border  h-[22px] justify-center  bg-icon-in-input-color  text-on-main-color"
        )}
      >
        No Spots Open
      </div>
    </div>
  );
};

export const RegistrationClosedBlock = ({ text }: { text: string }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="typography-small__t text-gray-text-color">{text}</div>
      <div
        className={classNames(
          labelClass,
          "box-border  h-[22px] justify-center  bg-icon-in-input-color  text-on-main-color"
        )}
      >
        Registration Closed
      </div>
    </div>
  );
};

export const OrJoinWaitlist = ({
  onJoinWaitlistClick,
}: {
  onJoinWaitlistClick: () => void;
}) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-[1fr_16px_1fr] items-center  gap-1">
        <div className="h-[1px] bg-separator-color"></div>
        <div className="typography-small  text-center text-gray-text-color">
          or
        </div>
        <div className="h-[1px] bg-separator-color"></div>
      </div>
      <Button
        onClick={onJoinWaitlistClick}
        kind="text"
        className="!typography-small"
      >
        Join Waitlist Now
      </Button>
    </div>
  );
};

export const ApplyForApplication = ({
  hasOtherOptionsToSchedule,
  onApplyClick,
  buttonName,
}: {
  hasOtherOptionsToSchedule: boolean;
  onApplyClick: () => void;
  buttonName: string;
}) => {
  return (
    <div className="flex flex-col gap-2">
      {
        <div className="grid grid-cols-[1fr_auto_1fr] items-center  gap-1">
          <div className="h-[1px] bg-separator-color"></div>
          <div className="typography-small whitespace-nowrap text-center text-gray-text-color">
            {hasOtherOptionsToSchedule ? "or" : "you can"}
          </div>
          <div className="h-[1px] bg-separator-color"></div>
        </div>
      }
      <Button
        style={{ wordBreak: "break-word" }}
        onClick={onApplyClick}
        autoLoading={true}
        kind="text"
        className="!typography-small "
      >
        {buttonName}
      </Button>
    </div>
  );
};

export const AvailableSession = ({
  session,
  onSessionClick,
}: {
  session: IAvailableSessionInProgram;
  onSessionClick: ({ id, date }: { id: string; date: ISOString }) => void;
}) => {
  return (
    <div
      key={session.id}
      onClick={() => onSessionClick(session)}
      className={`group flex cursor-pointer items-center justify-between gap-[6px] whitespace-nowrap rounded-md 
      border-[1px] border-solid border-surface-orange-dark-color 
      bg-on-main-color py-[2px] px-[5px] transition-all hover:bg-surface-orange-dark-color`}
    >
      <div className="typography-h4 text-surface-orange-dark-color transition-all group-hover:text-on-main-color">
        {dayjs(session.date).format("MMM Do")}
      </div>
      <div className="typography-small__t text-text-color transition-all group-hover:text-on-main-color">
        {formatStartEndTime(session)}
      </div>
    </div>
  );
};

export const SessionCount = ({ count }: { count: number }) => {
  return (
    <div
      className={`typography-small__t box-border h-[22px] rounded-[4px] border-[1px] border-solid 
    border-dark-main-color bg-table-row-color px-[6px] py-[1px] text-gray-text-color`}
    >
      {count} Sessions
    </div>
  );
};

const AvailableNonClickableDropIn = ({
  session,
  className,
}: {
  session: {
    date: string;
    start_time: string;
    end_time: string;
  };
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-between gap-2",
        className
      )}
    >
      <div className="typography-small__t text-text-color">
        {dayjs(session.date).format("MMM Do")}
      </div>
      <div className="typography-small__t text-gray-text-color">
        {formatStartEndTime(session)}
      </div>
    </div>
  );
};

export const AvailableClickableDropIns = ({
  availableSessions,
  onDropInClick,
  sessionsDisplayOption,
}: {
  availableSessions: IAvailableSessionInProgram[];
  onDropInClick: ({ id, date }: { id: string; date: ISOString }) => void;
  sessionsDisplayOption: SessionsDisplayOption;
}) => {
  if (sessionsDisplayOption === "DEFAULT") {
    return (
      <div className="flex w-fit flex-col gap-[6px]">
        {availableSessions.map((s) => (
          <AvailableSession
            key={s.id}
            session={s}
            onSessionClick={onDropInClick}
          />
        ))}
      </div>
    );
  }
  const rows =
    sessionsDisplayOption === "MONTH"
      ? getMonthsWithSessions(availableSessions)
      : sessionsDisplayOption === "WEEK"
      ? getWeeksWithSessions(availableSessions)
      : sessionsDisplayOption === "DAY"
      ? getDaysWithSessions(availableSessions)
      : [];

  return (
    <div className="flex  flex-col gap-[10px]">
      {rows.map((r, index) => (
        <ExpandableDateRow
          key={index}
          dateStringNode={
            "startOfMonth" in r ? (
              <div className="text-light-orange-color">
                {dayjs(r.startOfMonth).format("MMM, YYYY")}
              </div>
            ) : "startOfWeek" in r && "endOfWeek" in r ? (
              <div className="text-light-orange-color">
                {formatStartEndDate({
                  start_date: r.startOfWeek.toISOString(),
                  end_date: r.endOfWeek.toISOString(),
                })}{" "}
              </div>
            ) : "day" in r ? (
              <div className="text-light-orange-color">
                {r.day.format("MMM Do")}{" "}
              </div>
            ) : null
          }
          expand={
            <div className="mt-2 flex flex-col gap-[6px]">
              {r.sessions.map((s) => (
                <AvailableSession
                  key={s.id}
                  session={s}
                  onSessionClick={onDropInClick}
                />
              ))}
            </div>
          }
        />
      ))}
    </div>
  );
};

export const Dates = ({
  program,
  className,
  renderDetailsButton,
}: {
  program: TAvailableProgram;
  className?: string;
  renderDetailsButton?: () => React.ReactNode;
}) => {
  if (!isDaycampProgram(program)) {
    return (
      <div
        className={twMerge(
          "typography-main flex min-w-full items-center  gap-2 text-text-color",
          renderDetailsButton ? "justify-between" : "justify-center",
          className
        )}
      >
        <div>{formatStartEndDate(program)}</div>
        {renderDetailsButton?.()}
      </div>
    );
  } else if (program.sessions_display_option === "DEFAULT") {
    return (
      <div
        className={twMerge(
          "mx-auto flex flex-col gap-2 whitespace-nowrap",
          className
        )}
      >
        {program.sessions.map((s) => (
          <AvailableNonClickableDropIn key={s.id} session={s} />
        ))}
      </div>
    );
  }
  const rows =
    program.sessions_display_option === "MONTH"
      ? getMonthsWithSessions(program.sessions)
      : program.sessions_display_option === "WEEK"
      ? getWeeksWithSessions(program.sessions)
      : program.sessions_display_option === "DAY"
      ? getDaysWithSessions(program.sessions)
      : [];

  return (
    <div className="flex flex-col gap-[10px]">
      {rows.map((r, index) => (
        <ExpandableDateRow
          key={index}
          dateStringNode={
            "startOfMonth" in r ? (
              <div className="text-dark-main-color">
                {dayjs(r.startOfMonth).format("MMM, YYYY")}
              </div>
            ) : "startOfWeek" in r && "endOfWeek" in r ? (
              <div className="text-dark-main-color">
                {formatStartEndDate({
                  start_date: r.startOfWeek.toISOString(),
                  end_date: r.endOfWeek.toISOString(),
                })}{" "}
              </div>
            ) : "day" in r ? (
              <div className="text-dark-main-color">
                {r.day.format("MMM Do")}{" "}
              </div>
            ) : null
          }
          expand={
            <div
              className={`mt-2 flex flex-col gap-2 border-0 border-l-[1px] border-solid border-separator-color pl-2`}
            >
              {r.sessions.map((s) => (
                <AvailableNonClickableDropIn key={s.id} session={s} />
              ))}
            </div>
          }
        />
      ))}
    </div>
  );
};
