import React, { useEffect, useMemo } from "react";
import { action, computed, makeObservable, observable } from "mobx";
import useStores from "../../../hooks/useStores";
import useVM from "../../../hooks/useVM";
import formField, { FormField } from "../../../models/formField";
import { CommonStore, CustomerStore } from "../../../stores";
import * as Sentry from "@sentry/react";
import {
  getNameError,
  getPhoneError,
  validateEmail,
  validateRequired,
} from "../../../util/validators";
import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";
import { IStudentDTO, IUpdateCustomerDTO } from "../../../services/api";
import RouterStore, { ROUTES } from "../../../stores/RouterStore";
import notificator from "../../../services/systemNotifications/notificationCenterService";
import { ICreateCustomerAsParticipantDTO } from "../../../services/api/students";
import UpdatePhoto from "../../../models/UpdatePhoto";
import { assertNever, raise } from "@sizdevteam1/funjoiner-uikit";
import { completeCustomerProfilePageRoute } from "./CompleteCustomerProfilePage";
import PronounPickerVm from "../../../models/PronounPickerVm";
import GenderPickerVm from "../../../models/GenderPickerVm";

export const shouldDisplayCompleteCustomerProfilePage = (
  ...params: ConstructorParameters<typeof CompleteCustomerProfilePageVm>
): boolean => {
  const vm = new CompleteCustomerProfilePageVm(...params);
  const isValid = vm._validate();
  vm.dispose();
  return !isValid;
};

export class CompleteCustomerProfilePageVm {
  constructor(
    private customerStore: CustomerStore,
    private commonStore: CommonStore,
    private routerStore: RouterStore,
    selectedStudentIds: number[]
  ) {
    makeObservable(this);
    const selectedStudentsWithAlreadyCreatedCustomerAsParticipant =
      customerStore.studentsWithCustomerAsParticipant.filter((s) =>
        selectedStudentIds.includes(s.id)
      );

    if (
      selectedStudentsWithAlreadyCreatedCustomerAsParticipant.length === 0 &&
      selectedStudentIds.length === 0
    ) {
      console.error("No students selected");
      throw new Error("No students selected");
    }

    const isCustomerAsParticipant = //customer As Participant may not exist here
      selectedStudentIds[0] === -1 ||
      selectedStudentsWithAlreadyCreatedCustomerAsParticipant[0].type ===
        "CUSTOMER_AS_PARTICIPANT";

    this.formVm = isCustomerAsParticipant
      ? new CustomerAsParticipantFormVm(this.customerStore, this.commonStore)
      : new CustomerFormVm(
          this.customerStore,
          this.commonStore,
          selectedStudentsWithAlreadyCreatedCustomerAsParticipant
        );
  }

  formVm: CustomerFormVm | CustomerAsParticipantFormVm;

  submit = async () => {
    if (!this._validate()) return;
    try {
      await this.formVm.submit();

      this.routerStore.navigate(ROUTES.SCHEDULE, {
        replace: true,
        searchParams: this._scheduleSearchParams,
      });
    } catch (e) {
      Sentry.captureException(e);
      notificator.error("Error", e);
    }
  };

  @computed
  private get _scheduleSearchParams() {
    const state = completeCustomerProfilePageRoute.getParams(
      this.routerStore
    ).state;
    if (state?.action == null) return undefined;

    switch (state.action) {
      case "submit":
        return {
          action: "submit",
          program_id: "",
          student_ids: "",
        } as const;
      case "join_waitlist":
      case "apply_to_program":
        return {
          action: state.action,
          program_id: state.program_id!,
          student_ids: state.student_ids!.join(","),
        } as const;
      default:
        return assertNever(state.action);
    }
  }

  private _disposers = Array<() => void>();

  _validate = (): boolean => {
    return this.formVm._validate();
  };

  dispose = () => {
    this._disposers.forEach((d) => d());
  };
}

export class CustomerAsParticipantFormVm {
  constructor(
    private customerStore: CustomerStore,
    private commonStore: CommonStore
  ) {
    makeObservable(this);

    const customer = customerStore.customer;

    this.firstName = customer.first_name ? undefined : firstNameFormField();
    this.lastName = customer.last_name ? undefined : lastNameFormField();
    this.email = customer.email ? undefined : emailFormField();
    this.phoneNumber = customer.phone_number ? undefined : phoneFormField();

    const baselineQuestions = commonStore.publicSettings.baseline_questions;

    if (baselineQuestions.for_customers.address.status === "required") {
      this.address = customer.address ? undefined : addressField();
    }

    const participant = customerStore.customerAsParticipant;

    this.updatePhoto = new UpdatePhoto(
      "COMPANY_PUBLIC",
      participant?.photo_file
    );

    this.dateOfBirth = formField<ISOString | undefined>(
      participant?.birth_date,
      {
        validator: (v) => {
          if (!validateRequired(v)) return "Required";
          return null;
        },
      }
    );
    this.medicalConditions = formField<string>(
      participant?.medical_considerations ?? ""
    );
    this.allergies = formField<string>(participant?.allergies ?? "");
    this.notes = formField<string>(participant?.customer_notes ?? "");

    const pronounSettings = baselineQuestions.for_participants.pronoun;
    if (pronounSettings.status !== "disabled") {
      this.pronounPicker = new PronounPickerVm(
        participant?.personal_pronoun ?? "none",
        pronounSettings
      );
    }
    const genderSettings = baselineQuestions.for_participants.gender;
    if (genderSettings.status !== "disabled") {
      this.genderPicker = new GenderPickerVm(
        participant?.gender ?? undefined,
        genderSettings
      );
    }
  }

  firstName: FormField<string> | undefined;
  lastName: FormField<string> | undefined;
  email: FormField<string> | undefined;
  phoneNumber: FormField<string> | undefined;
  address: FormField<string> | undefined;

  updatePhoto: UpdatePhoto;
  dateOfBirth: FormField<ISOString | undefined>;
  medicalConditions: FormField<string>;
  allergies: FormField<string>;
  notes: FormField<string>;
  pronounPicker: PronounPickerVm | undefined;
  genderPicker: GenderPickerVm | undefined;

  @action
  _validate = () => {
    const isPronounValid =
      this.pronounPicker == null || this.pronounPicker.validate();
    const isGenderValid =
      this.genderPicker == null || this.genderPicker.validate();
    return (
      validateAll([
        this.firstName,
        this.lastName,
        this.email,
        this.phoneNumber,
        this.address,
        this.dateOfBirth,
        this.medicalConditions,
        this.allergies,
        this.notes,
      ]) &&
      isPronounValid &&
      isGenderValid
    );
  };

  @action
  submit = async () => {
    if (!this._validate()) throw new Error("Invalid form");
    const participant = this.customerStore.customerAsParticipant;
    const dto: ICreateCustomerAsParticipantDTO = {
      first_name: this.firstName?.value ?? undefined,
      last_name: this.lastName?.value ?? undefined,
      email: this.email?.value ?? undefined,
      phone_number: sanitizePhone(this.phoneNumber?.value),
      address: this.address?.value ?? undefined,
      birth_date: this.dateOfBirth.value ?? raise("Shouldn't validate"),
      allergies: this.allergies.value,
      customer_notes: this.notes.value,
      medical_considerations: this.medicalConditions.value,
      personal_pronoun:
        this.pronounPicker?.field.value ?? participant?.personal_pronoun,
      gender:
        this.genderPicker?.field.value ?? participant?.gender ?? undefined,
    };

    if (this.updatePhoto.hasChanged) {
      dto.photo_key = this.updatePhoto.newFileKey;
    }

    await this.customerStore.createCustomerAsParticipant(dto);
  };
}

export class CustomerFormVm {
  constructor(
    private customerStore: CustomerStore,
    commonStore: CommonStore,
    selectedParticipants: IStudentDTO[]
  ) {
    makeObservable(this);

    const customer = customerStore.customer;

    this.firstName = customer.first_name ? undefined : firstNameFormField();
    this.lastName = customer.last_name ? undefined : lastNameFormField();
    this.email = customer.email ? undefined : emailFormField();
    this.phoneNumber = customer.phone_number ? undefined : phoneFormField();

    const customerBaselineQuestions =
      commonStore.publicSettings.baseline_questions.for_customers;

    if (customerBaselineQuestions.address.status === "required") {
      this.address = customer.address ? undefined : addressField();
    }

    const existingEmergencyContacts = customerStore.customer.emergency_contacts;
    const requiredContactsNumber =
      customerBaselineQuestions.required_emergency_contacts -
      existingEmergencyContacts.length;

    if (requiredContactsNumber > 0) {
      this.emergencyContacts = [
        ...existingEmergencyContacts.map(EmergencyContactVm.fromDTO),
        ...Array.from(
          { length: requiredContactsNumber },
          () => new EmergencyContactVm("", "")
        ),
      ];
    }
    const participantsBaselineQuestions =
      commonStore.publicSettings.baseline_questions.for_participants;
    for (const participant of selectedParticipants) {
      const genderVm = new GenderPickerVm(
        participant.gender ?? undefined,
        participantsBaselineQuestions.gender
      );
      const pronounVm = new PronounPickerVm(
        participant.personal_pronoun,
        participantsBaselineQuestions.pronoun
      );
      const obj = {
        participant,
        genderVm: genderVm.validate() ? undefined : genderVm,
        pronounVm: pronounVm.validate() ? undefined : pronounVm,
        validate() {
          return (
            (this.genderVm?.validate() ?? true) &&
            (this.pronounVm?.validate() ?? true)
          );
        },
      };
      if (!obj.validate()) {
        obj.genderVm?.field.setError(null);
        obj.pronounVm?.field.setError(null);
        this.participantsToComplete.push(obj);
      }
    }
  }

  firstName: FormField<string> | undefined;
  lastName: FormField<string> | undefined;
  email: FormField<string> | undefined;
  phoneNumber: FormField<string> | undefined;
  address: FormField<string> | undefined;

  @observable
  emergencyContacts: EmergencyContactVm[] | undefined;

  participantsToComplete: {
    participant: IStudentDTO;
    genderVm?: GenderPickerVm;
    pronounVm?: PronounPickerVm;
    validate: () => boolean;
  }[] = [];

  @action
  _validate = () => {
    const allEmergencyContactsValid = this._validateEmergencyContacts();
    const allStudentsValid = this.validateParticipants();
    return (
      validateAll([
        this.firstName,
        this.lastName,
        this.email,
        this.phoneNumber,
        this.address,
      ]) &&
      allEmergencyContactsValid &&
      allStudentsValid
    );
  };

  @action
  private _validateEmergencyContacts = (): boolean => {
    let isValid = true;
    for (const contact of this.emergencyContacts ?? []) {
      if (!contact.validate()) {
        isValid = false;
      }
    }

    return isValid;
  };
  @action
  private validateParticipants = (): boolean => {
    let isValid = true;
    for (const p of this.participantsToComplete) {
      if (!p.validate()) {
        isValid = false;
      }
    }
    return isValid;
  };
  submit = async () => {
    if (!this._validate()) throw new Error("Invalid form");

    const dto: IUpdateCustomerDTO = {
      first_name: this.firstName?.value ?? undefined,
      last_name: this.lastName?.value ?? undefined,
      phone_number: sanitizePhone(this.phoneNumber?.value),
      email: this.email?.value ?? undefined,
      address: this.address?.value ?? undefined,
      emergency_contacts:
        this.emergencyContacts?.map((ec) => ec.toDTO()) ?? undefined,
    };

    await this.customerStore.updateProfile(dto);
    await Promise.all(
      this.participantsToComplete.map((participant) =>
        this.customerStore.updateParticipant(participant.participant.id, {
          personal_pronoun: participant.pronounVm?.field.value,
          gender: participant.genderVm?.field.value,
        })
      )
    );
  };
}

const sanitizePhone = (phoneNumber: string | null | undefined) => {
  return phoneNumber != null && phoneNumber.length > 0
    ? phoneNumber
    : undefined;
};
const firstNameFormField = () => {
  return formField<string>("", {
    validator: (v: string) => getNameError(v, "First Name"),
  });
};

const lastNameFormField = () => {
  return formField<string>("", {
    validator: (v: string) => getNameError(v, "Last Name"),
  });
};
const emailFormField = () => {
  return formField<string>("", {
    validator: (v: string) => (validateEmail(v) ? null : "Email is not valid"),
  });
};

const phoneFormField = () => {
  return formField<string>("", {
    validator: (v: string) => (v.length === 0 ? null : getPhoneError(v)),
  });
};

const addressField = () => {
  return formField<string>("", {
    validator: (v: string) => {
      if (!validateRequired(v)) return "Required";
      return null;
    },
  });
};

const validateAll = (fields: (FormField<any> | undefined)[]) => {
  for (const field of fields) {
    field?.validate();
  }

  for (const field of fields) {
    if (field == null) continue;
    if (field.error) return false;
  }

  return true;
};

export class EmergencyContactVm {
  constructor(name: string, phone_number: string) {
    this.name.set(name);
    this.phoneNumber.set(phone_number);
  }

  name = formField<string>("", { validator: getNameError });
  phoneNumber = formField<string>("", { validator: getPhoneError });

  static fromDTO(dto: { name: string; phone_number: string }) {
    return new EmergencyContactVm(dto.name, dto.phone_number);
  }
  toDTO() {
    return {
      name: this.name.value,
      phone_number: this.phoneNumber.value,
    };
  }

  validate = (): boolean => {
    return validateAll([this.name, this.phoneNumber]);
  };
}

const ctx = React.createContext<CompleteCustomerProfilePageVm | null>(null);

export const CompleteCustomerProfilePageVmProvider: React.FC<{}> = ({
  children,
}) => {
  const { customerStore, commonStore, routerStore } = useStores();
  const params = completeCustomerProfilePageRoute.useParams();
  const vm = useMemo(
    () =>
      new CompleteCustomerProfilePageVm(
        customerStore,
        commonStore,
        routerStore,
        params.state?.student_ids ?? []
      ),
    [commonStore, customerStore, routerStore]
  );
  useEffect(() => () => vm.dispose(), [vm]);
  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useCompleteCustomerProfilePageVm = () => useVM(ctx);
