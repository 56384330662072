import {
  computed,
  IComputedValue,
  makeObservable,
  observable,
  reaction,
} from "mobx";
import { PaymentMethodStore, PaymentStore } from "../../stores";
import { PaymentRequest, StripeElements } from "@stripe/stripe-js";
import { ICardDTO } from "../../services/api";
import { raise } from "@sizdevteam1/funjoiner-uikit";
import { computedFn } from "mobx-utils";
import cardFromStripePaymentMethod from "../../util/cardFromStripePaymentMethod";
import {
  BaseCustomerPaymentMethodsVm,
  CardPaymentMethod,
  NewCardPaymentMethodVm,
  PaymentMethod,
} from "@sizdevteam1/funjoiner-web-shared/components/ScheduleAndPay/PaymentMethodsVm";
import { TPaymentType } from "@sizdevteam1/funjoiner-web-shared/interfaces/orders";

export type CustomerPaymentMethod =
  | InvoicePaymentMethod
  | ApplePayPaymentMethodVm
  | NewCardPaymentMethodVm
  | CardPaymentMethod;
class InvoicePaymentMethod extends PaymentMethod {
  get type(): string {
    return "invoice" as const;
  }
  get orderPaymentType(): TPaymentType {
    return "stripe_invoice";
  }

  equals = (other: PaymentMethod): boolean => {
    return other instanceof InvoicePaymentMethod;
  };
}
class ApplePayPaymentMethodVm extends PaymentMethod {
  constructor(
    private createPaymentRequest: (
      description: string,
      price: number,
      isImmediateChargeRequired: boolean
    ) => Promise<PaymentRequest>,
    private attachCard: (card: ICardDTO) => Promise<ICardDTO>
  ) {
    super();
    makeObservable(this);
  }

  reconfigure = async (
    isImmediateChargeRequired: boolean,
    payment: Payment | undefined
  ) => {
    this._paymentRequest?.abort();
    if (payment == null) {
      this._paymentRequest = null;
      return;
    }

    const paymentRequest = await this.createPaymentRequest(
      payment.description,
      payment.final_price,
      isImmediateChargeRequired
    );

    if (await paymentRequest.canMakePayment()) {
      this._paymentRequest = paymentRequest;
    } else {
      this._paymentRequest = null;
    }
  };

  _confirm = async () => {
    const paymentRequest =
      this._paymentRequest ?? raise("Apple Pay is not enabled");
    const cardPromise = new Promise<ICardDTO>((resolve, reject) => {
      paymentRequest.once("paymentmethod", async (requestEvent) => {
        try {
          requestEvent.complete("success");
          const card = await this.attachCard(
            cardFromStripePaymentMethod(requestEvent.paymentMethod)
          );

          resolve(card);
        } catch (e) {
          requestEvent.complete("fail");
          console.error(e);
          reject(e);
        }
      });

      paymentRequest.once("cancel", () => {
        reject(new Error("Payment request was canceled"));
      });
    });

    paymentRequest.show();
    return await cardPromise;
  };

  get type(): string {
    return "apple_pay" as const;
  }

  get orderPaymentType(): TPaymentType {
    return "stripe";
  }

  equals(other: PaymentMethod): boolean {
    return other === this;
  }

  @observable
  _paymentRequest: PaymentRequest | null = null;
}

type Payment = { final_price: number; description: string };
type ApplePaySettings = {
  payment: IComputedValue<Payment | undefined>;
  isImmediateChargeRequired: boolean;
};

export class PaymentMethodPickerVm extends BaseCustomerPaymentMethodsVm<CustomerPaymentMethod> {
  constructor(
    private options: {
      isInvoiceEnabled: IComputedValue<boolean>;
      applePay?: ApplePaySettings;
    },
    private elements: StripeElements | null,
    private paymentStore: PaymentStore,
    private paymentMethodStore: PaymentMethodStore
  ) {
    super();
    makeObservable(this);

    const applePay = this.options.applePay;
    this._disposers.push(
      reaction(
        () => this.options.isInvoiceEnabled.get(),
        (isEnabled) => {
          if (!isEnabled && this.isSelected(new InvoicePaymentMethod())) {
            this.selectDefaultPaymentMethod();
          }
        }
      )
    );

    this.newCardVm = new NewCardPaymentMethodVm(async () => {
      if (!this.elements) {
        throw new Error("Elements is not initialized");
      }

      const paymentMethod =
        await this.paymentMethodStore.createPaymentMethodFromElements(
          this.elements
        );

      return await this.paymentMethodStore.attachNewCard(paymentMethod);
    });

    this.applePayVm = new ApplePayPaymentMethodVm(
      (description, price, isImmediateChargeRequired) =>
        this.paymentStore.createPaymentRequest(
          description,
          price,
          !isImmediateChargeRequired
        ),
      this.paymentMethodStore.attachNewCard
    );

    this.selectedPaymentMethod = this.defaultPaymentMethod;

    if (applePay != null) {
      this._disposers.push(
        reaction(
          () => applePay.payment.get(),
          async (payment) => {
            await this.applePayVm.reconfigure(
              applePay.isImmediateChargeRequired,
              payment
            );
            this.selectDefaultPaymentMethod();
          },
          { fireImmediately: true }
        )
      );
    }
  }

  newCardVm: NewCardPaymentMethodVm;
  applePayVm: ApplePayPaymentMethodVm;

  @computed
  get isApplePaySelected() {
    return this.isSelected(this.applePayVm);
  }

  selectApplePay = () => this.selectPaymentMethod(this.applePayVm);

  @computed
  get isInvoiceSelected() {
    return this.isSelected(new InvoicePaymentMethod());
  }

  selectInvoice = () => this.selectPaymentMethod(new InvoicePaymentMethod());

  isCardSelected = computedFn((card: ICardDTO) => {
    return this.isSelected(new CardPaymentMethod(card));
  });

  selectCard = (card: ICardDTO) =>
    this.selectPaymentMethod(new CardPaymentMethod(card));

  @computed
  get isNewCardSelected() {
    return this.isSelected(this.newCardVm);
  }

  selectNewCard = () => this.selectPaymentMethod(this.newCardVm);

  @observable
  selectedPaymentMethod: CustomerPaymentMethod;

  selectDefaultPaymentMethod = () => {
    this.selectPaymentMethod(this.defaultPaymentMethod);
  };

  @computed
  get defaultPaymentMethod() {
    if (this.defaultPaymentMethodId != null) {
      const card =
        this.cards.find(
          (c) => c.payment_method_id === this.defaultPaymentMethodId
        ) ?? raise("Card not found");
      return new CardPaymentMethod(card);
    } else if (this.isApplePayVisible) {
      return this.applePayVm;
    } else {
      return this.newCardVm;
    }
  }

  @computed
  get defaultPaymentMethodId() {
    return this.paymentMethodStore.stripePaymentMethods.default_payment_method;
  }

  @computed
  get cards() {
    return this.paymentMethodStore.stripePaymentMethods.payment_methods;
  }

  @computed
  get isInvoiceVisible() {
    return (
      this.options.isInvoiceEnabled.get() &&
      this.invoicePaymentMethodInfo != null
    );
  }

  @computed
  get invoicePaymentMethodInfo() {
    return this.paymentMethodStore.invoicePaymentMethod;
  }

  @computed
  get isApplePayVisible() {
    return this.applePayVm._paymentRequest != null;
  }

  confirm = async (paymentMethod: PaymentMethod) => {
    const card = await super.confirm(paymentMethod);

    this.selectPaymentMethod(new CardPaymentMethod(card));
    await this.paymentMethodStore.setDefaultPaymentMethod(
      card.payment_method_id
    );
    return card;
  };

  private _disposers = Array<() => void>();
  dispose = () => {
    this._disposers.forEach((d) => d());
  };
}
