import React from "react";
import { observer } from "mobx-react-lite";
import PageRoot from "src/components/PageRoot";
import PageHeader from "src/components/PageHeader";
import useStores from "src/hooks/useStores";
import dayjs from "dayjs";
import { formatPrice } from "@sizdevteam1/funjoiner-uikit";
import { IPromocodeDTO } from "src/services/api/profile";

// TODO
const PromocodesPage: React.FC = observer(() => {
  const {
    customerStore: { promocodes },
  } = useStores();

  return (
    <PageRoot>
      <PageHeader showBackLink={true}>Promo Codes</PageHeader>
      <div className="space-y-3">
        {promocodes.length > 0 ? (
          promocodes.map((e) => <PromocodeItem promocode={e} />)
        ) : (
          <div className="typography-h3  text-text-color">
            Your promo codes will appear here
          </div>
        )}
      </div>
    </PageRoot>
  );
});

interface IPromocodeItemProps {
  promocode: IPromocodeDTO;
}

const PromocodeItem: React.FC<IPromocodeItemProps> = observer(
  ({ promocode: e }) => {
    const {
      commonStore: { creditTypesById },
    } = useStores();

    const expirationDate =
      e.expiration_date != null ? dayjs(e.expiration_date) : null;
    const isInDollars = e.discount_percent === 100;
    const showAsterisk = !isInDollars && e.maximum_discount_value != null;

    const showExpires = expirationDate != null && e.status === "active";

    return (
      <div className="flex flex-col overflow-hidden rounded-[10px] shadow-big">
        <div className="flex flex-col space-y-[2px] bg-on-main-color p-4 pb-3">
          <span className="text-h3 text-text-color">{e.id}</span>
          <div className="flex flex-row justify-between">
            <span className="text-small capitalize text-text-color">
              {`${e.status} ${
                e.status === "active" && e.max_usages
                  ? `× ${e.usages_left}`
                  : ""
              }`}
            </span>
            {showExpires && (
              <span className="text-small text-gray-text-color">
                Expires: {expirationDate.format("M/D/YYYY")}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-1 bg-table-row-color p-4">
          <div className="flex flex-row justify-between">
            <span className="typography-small text-text-color">
              {e.credit_type_ids == null
                ? "Any credit"
                : e.credit_type_ids
                    .map((id) => creditTypesById[id].name)
                    .join(", ")}
            </span>
            <span className="typography-label text-text-color">
              {showAsterisk ? "*" : ""}
              {isInDollars
                ? `$${formatPrice(e.maximum_discount_value)}`
                : `${e.discount_percent}%`}
            </span>
          </div>
          {showAsterisk && (
            <span className="typography-small text-gray-text-color">
              *maximum discount value: ${formatPrice(e.maximum_discount_value)}
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default PromocodesPage;
