import {
  Button,
  Checkbox,
  FlipCard,
  MarkdownText,
} from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import React, { useLayoutEffect, useRef, useState } from "react";
import {
  IAvailableDaycampSession,
  IStudentDTO,
  TAvailabilityStudentInfo,
} from "src/services/api";
import formatStartEndTime from "src/util/formatStartEndTime";
import ScheduleSetDescriptionModal from "../ScheduleSetDescriptionModal/ScheduleSetDescriptionModal";
import {
  AgeRestrictionLabel,
  DateLabel,
  NoSpotsOpenBlock,
  RegistrationClosedBlock,
  RestrictionsBlock,
  RestrictionStatus,
  SpotsOpenLabel,
} from "./CardsComponents";
import { StudentRestrictions } from "./ProgramCard";
import styles from "./Cards.module.scss";
import { TAvailableProgram } from "src/services/api/availability";

interface SessionCardProps {
  session: IAvailableDaycampSession;
  selectedParticipants: IStudentDTO[];
  selectedUnlinkedParticipantsAmount?: number;
  selectedPrograms: TAvailableProgram[];
  isSelected: boolean;
  onSelect: () => void;
  highlight?: {
    shouldSelect: boolean;
    remove: () => void;
  };
}

type SessionScheduleStatus =
  | {
      status: "open";
      spots_left: number | null;
    }
  | {
      status: "not_enough_spots";
      spots_left: number | null;
    }
  | {
      status: "registration_closed";
    }
  | {
      status: "student_restriction";
      restrictions: StudentRestrictions[];
    };

const SessionCard = ({ props }: { props: SessionCardProps }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const { session, isSelected, onSelect } = props;
  const highlightedSessionRef = useRef<HTMLDivElement>(null);

  const schedule = getSessionCardStatus(props);
  useLayoutEffect(() => {
    if (
      props.highlight?.shouldSelect &&
      !isSelected &&
      schedule.status === "open"
    ) {
      props.onSelect();
    }
    if (highlightedSessionRef.current && props.highlight) {
      highlightedSessionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
    setTimeout(() => {
      if (highlightedSessionRef.current && props.highlight) {
        props.highlight.remove();
      }
    }, 4000);
  }, [props.highlight]);

  return (
    <div ref={highlightedSessionRef}>
      <FlipCard
        isFlipped={isFlipped}
        front={
          <div
            className={classNames(
              "w-[342px] rounded-lg",
              isSelected && styles.selected,
              props.highlight && styles.highlighted
            )}
          >
            <div className="rounded-t-lg bg-on-main-color px-4 py-3">
              <div className="flex items-start justify-between gap-3">
                <div className="typography-main_sb overflow-hidden text-ellipsis text-start text-header-color">
                  {session.program_type.name}
                </div>
                {schedule.status === "open" && (
                  <Checkbox checked={isSelected} onChange={onSelect} />
                )}
              </div>
              <div className="typography-small mt-1 text-start text-gray-text-color">
                {session.schedule_set_description ? (
                  <Button
                    onClick={() => setIsDescriptionModalOpen(true)}
                    kind="text"
                    className="!typography-small"
                  >
                    <i className="icon doc-icon" />
                    {session.schedule_set_name}
                  </Button>
                ) : (
                  session.schedule_set_name
                )}
              </div>
            </div>
            <div
              className={classNames(
                "rounded-b-lg px-4 py-3",
                schedule.status === "open"
                  ? "bg-highlight-color"
                  : "bg-table-row-color"
              )}
            >
              <ScheduleDetails
                props={props}
                setIsFlipped={() => setIsFlipped(true)}
              />
            </div>
          </div>
        }
        renderBack={(style) => (
          <div
            style={style}
            className={classNames(
              "flex h-full flex-col  items-start rounded-lg bg-on-main-color py-3 px-4",
              (isSelected || props.highlight) && styles.selected
            )}
          >
            <SessionLabelsSet props={props} showDates />
            <MarkdownText
              className=" typography-small__t mt-3 text-text-color"
              image={
                session.program_type.thumbnail_file?.download_url
                  ? {
                      url: session.program_type.thumbnail_file.download_url,
                    }
                  : undefined
              }
            >
              {session.program_type.description}
            </MarkdownText>
            <Button
              onClick={() => setIsFlipped(false)}
              className="!typography-label mt-auto self-center !pt-2"
              kind="text"
            >
              <i className="icon back-icon" />
              Back
            </Button>
          </div>
        )}
      />
      {isDescriptionModalOpen && (
        <ScheduleSetDescriptionModal
          descriptionData={{
            description: session.schedule_set_description,
            location_id: session.location_id,
            funbox_id: session.funbox_id,
            schedule_set_id: session.schedule_set_id,
            funbox_mode: session.funbox_mode,
          }}
          onClose={() => setIsDescriptionModalOpen(false)}
          isNavigateToScheduleButtonVisible={false}
        />
      )}
    </div>
  );
};

const SessionLabelsSet = ({
  props,
  showDates,
}: {
  props: SessionCardProps;
  showDates?: boolean;
}) => {
  const { session } = props;
  const sessionScheduleStatus = getSessionCardStatus(props);
  return (
    <div className={"flex  h-fit w-fit flex-row flex-wrap items-center gap-2"}>
      {sessionScheduleStatus.status === "open" &&
        sessionScheduleStatus.spots_left != null &&
        sessionScheduleStatus.spots_left < 10 && (
          <SpotsOpenLabel spots={sessionScheduleStatus.spots_left} />
        )}
      {sessionScheduleStatus.status === "not_enough_spots" &&
        sessionScheduleStatus.spots_left &&
        sessionScheduleStatus.spots_left > 0 && (
          <SpotsOpenLabel spots={sessionScheduleStatus.spots_left} />
        )}
      {showDates && <DateLabel start_date={session.date} />}
      {(session.program_type.from_age || session.program_type.to_age) && (
        <AgeRestrictionLabel
          from_age={session.program_type.from_age}
          to_age={session.program_type.to_age}
        />
      )}
    </div>
  );
};

const ScheduleDetails = ({
  props,
  setIsFlipped,
}: {
  props: SessionCardProps;
  setIsFlipped: () => void;
}) => {
  const { session } = props;
  const sessionScheduleStatus = getSessionCardStatus(props);

  if (sessionScheduleStatus.status === "student_restriction") {
    return (
      <RestrictionsBlock restrictions={sessionScheduleStatus.restrictions} />
    );
  }
  if (
    sessionScheduleStatus.status === "not_enough_spots" &&
    sessionScheduleStatus.spots_left === 0
  ) {
    return <NoSpotsOpenBlock text={formatStartEndTime(session)} />;
  }
  if (sessionScheduleStatus.status === "registration_closed") {
    return <RegistrationClosedBlock text={formatStartEndTime(session)} />;
  }

  const getLabelsCount = () => {
    let labels: string[] = [];
    if (
      sessionScheduleStatus.status === "open" &&
      sessionScheduleStatus.spots_left != null &&
      sessionScheduleStatus.spots_left < 10
    ) {
      labels.push("spots");
    }
    if (
      sessionScheduleStatus.status === "not_enough_spots" &&
      sessionScheduleStatus.spots_left &&
      sessionScheduleStatus.spots_left > 0
    ) {
      labels.push("spots");
    }

    if (session.program_type.from_age || session.program_type.to_age) {
      labels.push("age_restricted");
    }
    if (session.program_type.description) {
      labels.push("description");
    }
    return labels.length;
  };

  const labelsCount = getLabelsCount();

  return (
    <div className="flex flex-col gap-2">
      {labelsCount > 0 && <SessionLabelsSet props={props} />}

      <div
        className={classNames(
          "flex  gap-3",
          session.program_type.description
            ? "justify-between"
            : "justify-center"
        )}
      >
        <SessionTime session={session} />
        {session.program_type.description && (
          <Button
            onClick={setIsFlipped}
            className={classNames("!typography-label")}
            kind="text"
          >
            Details
            <i className="icon forward-icon !mr-0 ml-1" />
          </Button>
        )}
      </div>
    </div>
  );
};

const SessionTime = ({
  session,
  className,
}: {
  session: IAvailableDaycampSession;
  className?: string;
}) => {
  return (
    <div className={classNames("typography-main text-text-color", className)}>
      {formatStartEndTime(session)}
    </div>
  );
};
const getSessionCardStatus = ({
  session,
  selectedParticipants,
  selectedPrograms,
  selectedUnlinkedParticipantsAmount,
  isSelected,
}: {
  session: IAvailableDaycampSession;
  selectedParticipants: IStudentDTO[];
  selectedPrograms: TAvailableProgram[];
  selectedUnlinkedParticipantsAmount?: number;
  isSelected: boolean;
}): SessionScheduleStatus => {
  const selectedParticipantsRestrictedInfo = session.student_info.filter((s) =>
    selectedParticipants.find((participant) => participant.id === s.student_id)
  );

  const selectedProgramsThatIncludesSession = selectedPrograms.filter(
    (p) => p.id === session.program_id
  );

  const spotsLeft =
    session.registration.spots_left != null &&
    selectedUnlinkedParticipantsAmount
      ? session.registration.spots_left - selectedUnlinkedParticipantsAmount
      : session.registration.spots_left;

  if (session.registration.status === "CLOSED") {
    return {
      status: "registration_closed",
    };
  }

  if (selectedProgramsThatIncludesSession.length > 0) {
    selectedParticipants.forEach((participant) => {
      selectedProgramsThatIncludesSession.forEach((p) =>
        selectedParticipantsRestrictedInfo.push({
          type: "has_selected_program_includes_selected_session",
          program: p,
          student_id: participant.id,
        })
      );
    });
  }

  if (selectedParticipantsRestrictedInfo.length > 0) {
    return {
      status: "student_restriction",
      restrictions: getRestrictionsForSession({
        session,
        selectedParticipants,
        selectedPrograms,
        info: selectedParticipantsRestrictedInfo,
      }),
    };
  }

  if (session.registration.status === "OPEN") {
    return isSelected ||
      spotsLeft == null ||
      spotsLeft >= selectedParticipants.length
      ? {
          status: "open",
          spots_left: session.registration.spots_left,
        }
      : {
          status: "not_enough_spots",
          spots_left: session.available_spots,
        };
  }

  throw new Error("No Session Card Status");
};

const getRestrictionsForSession = ({
  session,
  selectedParticipants,
  selectedPrograms,
  info,
}: {
  session: IAvailableDaycampSession;
  selectedParticipants: IStudentDTO[];
  selectedPrograms: TAvailableProgram[];
  info: TAvailabilityStudentInfo[];
}): StudentRestrictions[] => {
  const selectedParticipantsRestrictions: StudentRestrictions[] =
    selectedParticipants.map((student) => ({
      student,
      statuses: info.flatMap((info) => {
        let statuses: RestrictionStatus[] = [];
        if (info.student_id !== student.id) return [];
        const selectedProgramsWithThisSession = selectedPrograms.filter(
          (p) => p.id === session.program_id
        );

        if (selectedProgramsWithThisSession.length > 0) {
          selectedProgramsWithThisSession.forEach((p) => {
            statuses.push({
              type: "already_selected",
              attendance: {
                kind: "program",
                start_date: p.start_date,
                end_date: p.end_date,
              },
            });
          });
        }

        if (info.type === "in_waitlist_for_program") {
          statuses.push({ type: "on_waitlist" });
        }

        if (info.type === "age_restricted" && student.id === info.student_id) {
          statuses.push({
            type: "age_restricted",
            actual_age: info.actual_age,
            program_type_from_age: session.program_type.from_age,
            program_type_to_age: session.program_type.to_age,
          });
        }

        if (info.type === "already_signed_for_program") {
          statuses.push({
            type: "booked",
            attendance: {
              kind: "program",
              start_date: info.start,
              end_date: info.end,
            },
          });
        }
        if (info.type === "already_signed_for_session") {
          statuses.push({
            type: "booked",
            attendance: {
              kind: "session",
              date: info.start,
            },
          });
        }

        if (info.type === "has_application_for_program") {
          statuses.push({
            type: "applied",
          });
        }
        return statuses;
      }),
    }));

  return selectedParticipantsRestrictions;
};
export default SessionCard;
