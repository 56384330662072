import { observer } from "mobx-react-lite";
import cn from "classnames";
import CardsListItem from "../cards/CardsListItem";
import { Button, Radio } from "@sizdevteam1/funjoiner-uikit";
import NewCard from "../cards/NewCard";
import React from "react";
import { PaymentMethodPickerVm } from "./PaymentMethodPickerVm";
import { PaymentMethodListTile } from "./PaymentMethodListTile";
import { twMerge } from "tailwind-merge";

export const PaymentMethodPicker = observer(
  ({
    className,
    vm,
    description,
  }: {
    className?: string;
    vm: PaymentMethodPickerVm;
    description?: string;
  }) => {
    const { cards } = vm;
    const hasCards = cards.length > 0;

    return (
      <div className={cn("typography-main text-text-color", className)}>
        {description && <div className={"mb-6"}>{description}</div>}
        <div className={"space-y-3"}>
          {cards.map((card) => (
            <CardsListItem
              key={card.payment_method_id}
              data={card}
              onClick={() => vm.selectCard(card)}
              trailing={
                <Radio
                  checked={vm.isCardSelected(card)}
                  onChange={() => vm.selectCard(card)}
                />
              }
            />
          ))}
          {vm.isApplePayVisible && <ApplePayOption vm={vm} />}
          {vm.isInvoiceVisible && <InvoicePaymentOption vm={vm} />}
          {vm.isNewCardSelected ? (
            <NewCard
              onValidityChanged={vm.newCardVm.setIsValid}
              onClose={
                hasCards ? () => vm.selectDefaultPaymentMethod() : undefined
              }
            />
          ) : (
            <Button
              className={"w-full"}
              size={"big"}
              kind={"secondary"}
              onClick={() => vm.selectNewCard()}
            >
              <i className={"icon small-add-icon"} />
              Add New Card
            </Button>
          )}
        </div>
      </div>
    );
  }
);

const ApplePayOption = observer(({ vm }: { vm: PaymentMethodPickerVm }) => {
  return (
    <PaymentMethodListTile
      key={"apple_pay"}
      title={
        <div className={"flex flex-row items-center gap-[3px]"}>
          <div className={"typography-main_sb"}>Pay with</div>
          <i className={"icon apple-pay-icon w-[46px] bg-black"} />
        </div>
      }
      trailing={
        <Radio checked={vm.isApplePaySelected} onChange={vm.selectApplePay} />
      }
    />
  );
});
const InvoicePaymentOption = observer(
  ({ vm }: { vm: PaymentMethodPickerVm }) => {
    return (
      <PaymentMethodListTile
        title={
          <div className={"flex flex-row gap-2"}>
            <div className={"flex w-[32px] items-center justify-center"}>
              <i className={"icon invoice-icon bg-main-color"} />
            </div>
            <div className={"typography-main_sb flex-grow"}>
              Pay with Invoice
            </div>
          </div>
        }
        description={
          vm.invoicePaymentMethodInfo?.description ? (
            <div className={"typography-small text-gray-text-color"}>
              {vm.invoicePaymentMethodInfo?.description}
            </div>
          ) : undefined
        }
        trailing={
          <Radio
            checked={vm.isInvoiceSelected}
            onChange={() => vm.selectInvoice()}
          />
        }
      />
    );
  }
);
