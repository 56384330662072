import React from "react";
import {
  IAvailableOvernightProgram,
  TAvailableProgram,
} from "src/services/api/availability";
import isDaycampProgram from "src/util/isDayCampProgram";
import formatStartEndDate from "src/util/formatStartEndDate";
import { GroupedSessions } from "src/components/GroupedSessions/GroupedSessions";
import SessionRow from "src/components/SessionRow/SessionRow";

interface IProps {
  program: TAvailableProgram;
  toggleProgram: (program: TAvailableProgram) => void;
}

const ProgramScheduleTable: React.FC<IProps> = ({ program, toggleProgram }) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between  py-3 px-4">
        <div className="flex flex-col">
          <div className="typography-small__t text-gray-text-color">
            {program.schedule_set_name}
          </div>
          <div className="typography-h3 text-text-color">
            {program.program_type.name}
          </div>
        </div>

        <i
          className={"icon close-icon icon-hover"}
          onClick={() => toggleProgram(program)}
        />
      </div>
      <ProgramView program={program} />
    </div>
  );
};

const ProgramView = ({ program }: { program: TAvailableProgram }) => {
  if (!isDaycampProgram(program)) {
    return <OvernightProgramRow key={program.id} program={program} />;
  }
  if (program.sessions_display_option === "DEFAULT") {
    return (
      <>
        {program.sessions.map((s) => (
          <SessionRow className="pr-4" session={s} key={s.id} />
        ))}
      </>
    );
  }
  return (
    <GroupedSessions
      displayOption={program.sessions_display_option}
      sessions={program.sessions}
    />
  );
};

const OvernightProgramRow = ({
  program,
}: {
  program: IAvailableOvernightProgram;
}) => {
  return (
    <div className="typography-main px-4 py-2 text-center text-text-color even:bg-table-row-color">
      {formatStartEndDate(program)}
    </div>
  );
};

export default ProgramScheduleTable;
