import { BaselineQuestion } from "../services/api/common";
import { action, computed, makeObservable } from "mobx";
import formField, { FormField } from "./formField";
import { Gender } from "../services/api/students";

export default class GenderPickerVm {
  constructor(value: Gender | undefined, settings: BaselineQuestion) {
    makeObservable(this);
    this.field = formField<Gender | undefined>(value, {
      validator: (v: Gender | undefined) => {
        if (settings.status === "required" && v == null) {
          return "Gender is required";
        }
        return null;
      },
    });
  }

  field: FormField<Gender | undefined>;

  @computed
  get selectedOption() {
    return this.availableOptions.find(
      (option) => option.id === this.field.value
    );
  }

  @computed
  get availableOptions() {
    return [
      { id: "MALE", name: "Male" } as const,
      { id: "FEMALE", name: "Female" } as const,
    ];
  }

  @action selectOption = (option: (typeof this.availableOptions)[number]) => {
    this.field.set(option.id);
  };

  validate = () => this.field.validate();
}
