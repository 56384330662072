import {
  Button,
  capitalize,
  ColorLabel,
  Loader,
} from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import cn from "classnames";
import React from "react";
import PageHeader from "src/components/PageHeader";
import PageRoot from "src/components/PageRoot";
import { ROUTES } from "src/stores/RouterStore";
import getAge from "src/util/getAge";
import StudentSelector from "../../components/StudentSelector/StudentSelector";
import {
  ParticipantsPageVMProvider,
  useParticipantsPageVM,
} from "./ParticipantsPageVM";
import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroller";
import { isMedicationRecord } from "../../services/api/healthLog";
import { formatDate } from "@sizdevteam1/funjoiner-uikit/util";

const ParticipantsPage = () => (
  <ParticipantsPageVMProvider>
    <ParticipantsPageImpl />
  </ParticipantsPageVMProvider>
);
const ParticipantsPageImpl = observer(() => {
  const vm = useParticipantsPageVM();
  return (
    <PageRoot>
      <PageHeader showBackLink backLinkRoute={ROUTES.PROFILE}>
        Participants
      </PageHeader>
      <StudentSelector
        className={"mt-3"}
        onSelectStudent={vm.selectStudent}
        selectedStudentId={vm.selectedStudent?.id}
        students={vm.students}
        trailingElement={
          <Button
            className="w-[88px] !pt-1"
            kind={"text"}
            onClick={vm.toAddParticipant}
          >
            <div className={"flex flex-col items-center gap-2.5"}>
              <div
                className={classNames(
                  "box-border flex h-[48px] w-[48px] items-center justify-center rounded-[50%] border-[2px] border-solid border-main-color "
                )}
              >
                <i
                  className={classNames(
                    "icon add-participant2-icon  !h-[26px] !w-[29px] bg-main-color"
                  )}
                />
              </div>
              <div className={"typography-label"}>Add New</div>
            </div>
          </Button>
        }
      />
      {vm.selectedStudent && (
        <div className={"mt-8 flex items-center justify-between"}>
          <div className={"space-y-1"}>
            <div className="typography-h2 text-header-color">
              {vm.selectedStudent.full_name}
            </div>
            <div className="typography-small text-gray-text-color">
              {getAge(vm.selectedStudent.birth_date)} yrs
            </div>
          </div>
          <Button
            kind={"text"}
            onClick={vm.toEditParticipant(vm.selectedStudent.id)}
          >
            <i className={"icon edit-icon icon-lg"} /> Edit
          </Button>
        </div>
      )}
      {vm.isInitializing && <Loader />}

      {!vm.isInitializing && vm.hasAnyStudentVisibleHealthLogRecords && (
        <HealthLog />
      )}
    </PageRoot>
  );
});

const HealthLog = observer(() => {
  const vm = useParticipantsPageVM();
  return (
    <div className={"my-6"}>
      <div className={"typography-h2 text-header-color"}>Health Log</div>

      <div className={"relative mt-4 min-h-[70px]"}>
        {vm.isHealthLogLoading && <Loader className={"absolute"} />}

        {!vm.isHealthLogLoading && vm.healthLog.length === 0 && (
          <div
            className={
              "typography-main flex justify-center rounded-[10px] bg-white-color py-6 text-gray-text-color"
            }
          >
            No Health Records to Display
          </div>
        )}

        <InfiniteScroll
          className={cn(
            "flex flex-col gap-3  ",
            vm.isHealthLogLoading && "pointer-events-none opacity-50"
          )}
          loadMore={vm.loadNextPage}
          hasMore={vm.hasMoreHealthLog}
        >
          {vm.healthLog.map((item) => (
            <div
              key={item.id}
              className={"overflow-clip rounded-[10px] shadow-big"}
            >
              <div className={"bg-white-color py-3 px-4"}>
                <div className={"flex gap-2"}>
                  <span className={"typography-h3 text-text-color"}>
                    {isMedicationRecord(item)
                      ? "Medication Record"
                      : "Accident/Incident Report"}
                  </span>
                  {isMedicationRecord(item) && (
                    <ColorLabel
                      className={cn(
                        item.type === "regular"
                          ? "bg-icon-dark-green-color"
                          : "bg-surface-purple-color"
                      )}
                      text={capitalize(item.type)}
                    />
                  )}
                  <Button
                    className={"ml-auto"}
                    kind={"icon"}
                    autoLoading
                    onClick={() => vm.downloadHealthLogItem(item)}
                  >
                    <i className={"icon download-icon"} />
                  </Button>
                </div>
                <div className={"typography-small mt-1 text-gray-text-color"}>
                  {formatDate(item.created_at)}
                </div>
              </div>
              <div className={"bg-table-row-color py-3 px-4 text-text-color"}>
                <div className={"typography-label"}>
                  {isMedicationRecord(item)
                    ? item.medication_name
                    : {
                        accident: "Accident",
                        behavioural: "Behavioural",
                        epidemic_illness: "Epidemic Illness",
                        other: "Other",
                      }[item.type]}
                </div>
                <div className={"typography-small__t"}>
                  {isMedicationRecord(item)
                    ? item.description
                    : item.sequence_of_activity}
                </div>
              </div>
            </div>
          ))}
        </InfiniteScroll>
        {vm.isLoadingNextPage && <Loader />}
      </div>
    </div>
  );
});
export default ParticipantsPage;
