import React from "react";
import { Redirect, Router, Switch } from "react-router-dom";
import PublicRoute from "src/components/PublicRoute";
import { Elements } from "@stripe/react-stripe-js";
import useStores from "./hooks/useStores";
import { Observer } from "mobx-react-lite";
import { Splash } from "@sizdevteam1/funjoiner-uikit";
import AppHeader from "./pages/AppHeader";
import Footer from "./components/Footer";
import { ROUTES } from "./stores/RouterStore";
import SignInPage from "./pages/SignInPage";
import ThankYouPage from "./pages/SuccessPages/ScheduleSuccessPage";
import DashboardPage from "./pages/DashboardPage";
import AvailabilityPage from "./pages/AvailabilityPage";
import HistoryPage from "./pages/HistoryPage";
import CardsPage from "./pages/CardsPage";
import DocumentsPage from "./pages/DocumentsPage";
import PrivateRoute from "./components/PrivateRoute";
import CheckoutAndPayment from "./pages/Checkout&Payment/Checkout&Payment";
import PaymentSuccessPage from "./pages/SuccessPages/PaymentSuccessPage";
import SchedulePage from "./pages/Schedule/SchedulePage";
import HelpPage from "./pages/HelpPage";
import DirectionsPage from "./pages/DirectionsPage";
import SelfReschedulePage, {
  rescheduleRoute,
} from "./pages/SelfRechedulePage/SelfReschedulePage";
import CancelCreditPage, {
  cancelCreditRoute,
} from "./pages/SelfRechedulePage/CancelCreditPage";
import ScheduleAndPaySuccessPage from "./pages/SuccessPages/ScheduleAndPaySuccessPage";
import UpgradeCreditPage from "./pages/UpgradeCredit/UpgradeCreditPage";
import PromocodesPage from "./pages/PromocodesPage/PromocodesPage";
import CustomDocumentPage from "./pages/DocumentsPage/CustomDocumentPage/CustomDocumentPage";
import SelectFunboxPage from "./pages/SelectFunboxPage";
import ParticipantCreateOrEditPage from "./pages/ParticipantCreateOrEditPage";
import SmartFormsPage from "./pages/SmartFormsPage/SmartFormsPage";
import StudentQuestionSetPage, {
  studentQuestionSetRoute,
} from "./pages/SmartFormsPage/StudentQuestionSetPage/StudentQuestionSetPage";
import AfterScheduleQuestionSetsPage, {
  afterScheduleQuestionSetsRoute,
} from "./pages/AfterScheduleQuestionSetsPage/AfterScheduleQuestionSetsPage";
import ScheduleSharingPage from "./pages/SuccessPages/ScheduleSharingPage";
import InvoiceSentSuccessPage from "./pages/SuccessPages/InvoiceSentSuccessPage";
import PersonalInfoPage from "./pages/ProfilePage/PersonalInfoPage/PersonalInfoPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage/ProfilePage";
import OrderPaymentPlanPage from "./pages/OrderPaymentPlanPage/OrderPaymentPlanPage";
import WelcomeScreenPage from "./pages/WelcomeScreenPage/WelcomeScreenPage";
import FlexiblePaymentsPage from "./pages/FlexiblePaymentsPage/FlexiblePaymentsPage";
import ExploreOfferingsPage from "./pages/ExploreOfferingsPage/ExploreOfferingsPage";
import PreselectFunboxByIdOrUrlPartPage from "./pages/PreselectFunboxByIdOrUrlPartPage/PreselectFunboxByIdOrUrlPartPage";
import CommunicationPreferencesModal from "./components/CommunicationPreferencesModal";
import ParticipantsPage from "./pages/ParticipantsPage/ParticipantsPage";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
import ApplicationProcessPage, {
  applicationProcessPageRoute,
} from "./pages/ApplicationProcessPage/ApplicationProcessPage";
import ApplicationsAndWaitlistsPage, {
  applicationsAndWaitlistsPageRoute,
} from "./pages/ApplicationsAndWaitlistsPage/ApplicationsAndWaitlistsPage";
import ApplicationPage, {
  applicationPageRoute,
} from "./pages/ApplicationPage/ApplicationPage";
import {
  SmartFormPipelinePage,
  smartFormPipelinePageRoute,
} from "./pages/SmartFormPipelinePage/SmartFormPipelinePage";
import ApplicationSuccessPage from "./pages/SuccessPages/ApplicationSuccessPage";

const App: React.FC = () => {
  const rootStore = useStores();
  const { paymentStore, routerStore, funboxStore } = rootStore;

  return (
    <div className="App">
      <Router history={routerStore.history}>
        <Observer>
          {() =>
            rootStore.showAppLoading ? (
              <Splash pathToAnimation={"/splash.json"} />
            ) : (
              <Elements stripe={paymentStore.stripePromise}>
                <CommunicationPreferencesModal />
                <main>
                  <AppHeader />
                  <Switch>
                    <PublicRoute
                      title={"Lineup"}
                      path={[
                        ROUTES.SELECT_FUNBOX_PAGE_BY_URL_PART_ACTION,
                        ROUTES.SELECT_FUNBOX_PAGE_BY_ID_ACTION,
                      ]}
                      component={PreselectFunboxByIdOrUrlPartPage}
                    />
                    <PublicRoute
                      title={"Lineup"}
                      path={ROUTES.SELECT_FUNBOX_PAGE}
                      exact
                      component={SelectFunboxPage}
                    />
                    <PublicRoute
                      title={"Sign In"}
                      path={ROUTES.SIGN_IN}
                      component={SignInPage}
                    />
                    <PublicRoute
                      title={"Add Participant Page"}
                      path={ROUTES.ADD_PARTICIPANT}
                      component={ParticipantCreateOrEditPage}
                    />
                    <PublicRoute
                      title={"Edit Participant Page"}
                      path={ROUTES.EDIT_PARTICIPANT}
                      component={ParticipantCreateOrEditPage}
                    />

                    <PublicRoute
                      title={"Get Help"}
                      path={ROUTES.HELP}
                      component={HelpPage}
                    />

                    <PrivateRoute
                      title={"My Dashboard"}
                      path={ROUTES.DASHBOARD}
                      component={DashboardPage}
                    />

                    <PrivateRoute
                      title={"Profile"}
                      path={ROUTES.PROFILE}
                      component={ProfilePage}
                    />
                    <PrivateRoute
                      title={"Contacts Page"}
                      path={ROUTES.CONTACTS}
                      component={ContactsPage}
                    />
                    <PrivateRoute
                      title={"Transaction History"}
                      path={ROUTES.HISTORY}
                      component={HistoryPage}
                    />
                    <PrivateRoute
                      title={"Cards"}
                      path={ROUTES.CARDS}
                      component={CardsPage}
                    />
                    <PrivateRoute
                      title={"Documents"}
                      path={[
                        ROUTES.DOCUMENTS,
                        ROUTES.DOCUMENT_SIGN_SUCCESS_CALLBACK,
                      ]}
                      component={DocumentsPage}
                    />
                    <PrivateRoute
                      title={"Participants"}
                      path={[ROUTES.PARTICIPANTS]}
                      component={ParticipantsPage}
                    />
                    <PrivateRoute
                      title={"Personal Information"}
                      path={[ROUTES.PERSONAL_INFO]}
                      component={PersonalInfoPage}
                    />
                    <PrivateRoute
                      title={"Promo Codes"}
                      path={ROUTES.PROMOCODES}
                      component={PromocodesPage}
                    />
                    <PrivateRoute
                      title={"Submit Document"}
                      path={ROUTES.CUSTOM_DOCUMENT}
                      component={CustomDocumentPage}
                    />
                    <PrivateRoute
                      title="Personal Information"
                      path={studentQuestionSetRoute.path}
                      component={StudentQuestionSetPage}
                    />
                    <PrivateRoute
                      title="Personal Information"
                      exact
                      path={ROUTES.SMART_FORMS}
                      component={SmartFormsPage}
                    />
                    <PrivateRoute
                      title="Personal Information"
                      path={afterScheduleQuestionSetsRoute.path}
                      component={AfterScheduleQuestionSetsPage}
                    />

                    <PrivateRoute
                      title={"Application"}
                      path={applicationPageRoute.path}
                      component={ApplicationPage}
                    />
                    <PrivateRoute
                      title={"Applications/Waitlists"}
                      path={applicationsAndWaitlistsPageRoute.path}
                      component={ApplicationsAndWaitlistsPage}
                    />

                    <PrivateRoute
                      title={"Schedule Dates"}
                      path={ROUTES.SCHEDULE}
                      component={SchedulePage}
                    />
                    <PrivateRoute
                      title={"Application Process"}
                      path={applicationProcessPageRoute.path}
                      component={ApplicationProcessPage}
                    />
                    <PrivateRoute
                      title={"Smart Forms"}
                      path={smartFormPipelinePageRoute.path}
                      component={SmartFormPipelinePage}
                    />
                    <PrivateRoute
                      title={"Payment Successful!"}
                      path={ROUTES.PAYMENT_SUCCESS}
                      component={PaymentSuccessPage}
                    />
                    <PrivateRoute
                      title={"Application Successful!"}
                      path={ROUTES.APPLICATION_SUCCESS}
                      component={ApplicationSuccessPage}
                    />
                    <PrivateRoute
                      title={"Self Reschedule"}
                      path={rescheduleRoute.path}
                      component={SelfReschedulePage}
                    />
                    <PrivateRoute
                      title={"Cancel Reservation"}
                      path={cancelCreditRoute.path}
                      component={CancelCreditPage}
                    />
                    <PrivateRoute
                      title={"Self Upgrade"}
                      path={ROUTES.UPGRADE_CREDIT}
                      component={UpgradeCreditPage}
                    />
                    <PrivateRoute
                      title={"Payment Plan"}
                      path={ROUTES.PAYMENT_PLAN}
                      component={OrderPaymentPlanPage}
                    />

                    <PublicRoute
                      title="Welcome Screen Page"
                      component={WelcomeScreenPage}
                      path={ROUTES.WELCOME_SCREEN}
                      exact
                    />
                    {funboxStore.isBuyCreditsAndSaveEnabled && (
                      <PublicRoute
                        title="Flexible Payments Page"
                        component={FlexiblePaymentsPage}
                        path={ROUTES.FLEXIBLE_PAYMENTS}
                      />
                    )}
                    <PublicRoute
                      title="Explore Offerings Page"
                      component={ExploreOfferingsPage}
                      path={ROUTES.EXPLORE_OFFERINGS}
                    />
                    <PublicRoute
                      title={"Check Schedule"}
                      path={ROUTES.AVAILABILITY}
                      component={AvailabilityPage}
                    />
                    <PrivateRoute
                      title={"Directions and details"}
                      path={ROUTES.DIRECTIONS_DETAILS}
                      component={DirectionsPage}
                    />
                    <PrivateRoute
                      title="Schedule Sharing"
                      path={ROUTES.SCHEDULE_SHARING}
                      component={ScheduleSharingPage}
                    />
                    <PrivateRoute
                      title={"Checkout"}
                      path={ROUTES.CHECKOUT}
                      component={CheckoutAndPayment}
                    />
                    <PrivateRoute
                      title={"Thank You!"}
                      path={ROUTES.THANKYOU}
                      component={ThankYouPage}
                    />
                    <PrivateRoute
                      title={"Thank You!"}
                      path={ROUTES.SCHEDULE_AND_PAY_SUCCESS}
                      component={ScheduleAndPaySuccessPage}
                    />
                    <PrivateRoute
                      title={"Invoice Sent!"}
                      path={ROUTES.INVOICE_SENT_SUCCESS}
                      component={InvoiceSentSuccessPage}
                    />
                    <Redirect
                      from={ROUTES.LOCATIONS}
                      to={ROUTES.SELECT_FUNBOX_PAGE}
                    />
                    <Redirect from={ROUTES.ROOT} to={ROUTES.WELCOME_SCREEN} />
                  </Switch>
                  <Footer />
                </main>
              </Elements>
            )
          }
        </Observer>
      </Router>
    </div>
  );
};

export default App;
