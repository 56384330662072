import apiService from "./apiService";
import * as common from "./common";
import * as availability from "./availability";
import * as paymentMethods from "./payment-methods";
import * as profile from "./profile";
import * as healthLog from "./healthLog";
import * as students from "./students";
import * as orders from "./orders";
import * as documents from "./documents";
import * as invoices from "./invoices";
import * as integrations from "./integrations";
import * as attendances from "./attendances";
import * as credits from "./credits";
import * as promocodes from "./promocodes";
import * as applications from "./applications";
import * as files from "./files";
import * as waitlists from "./waitlists";
import * as questionSets from "./smartForms";
import * as requiredForAttendance from "./required-for-attendance";

export type {
  ICompanyProfileDTO,
  ILocationDTO,
  IPriceModifierDTO,
  ICreditTypeDTO,
  IAvailableSession,
  ICheckAvailabilityParams,
  ICompanySettings,
  IFunboxDTO,
} from "./common";

export type {
  AlreadySignedForSessionInfo,
  AlreadySignedForProgramInfo,
  InWaitlistForProgramInfo,
  IAvailableDaycampSession,
  IAvailableDaycampScheduleSet,
  IAvailableOvernightScheduleSet,
  TAvailabilityStudentInfo,
  ProgramRegistrationInfo,
  ClosedRegistrationWithWaitlist,
  ApplicationInfoDTO,
  WaitlistInfoDTO,
} from "./availability";
export type {
  ICustomerDTO,
  ICustomerWithStudentsDTO,
  IUpdateCustomerDTO,
  ICreditDTO,
  IPaymentDTO,
} from "./profile";
export type {
  TAttendanceDTO,
  IOvernightProgramAttendanceDTO,
  IProgramOfSessionsAttendanceDTO,
  ISessionInProgramAttendanceDTO,
} from "./attendances";
export type { IncidentReportDTO, MedicationRecordDTO } from "./healthLog";
export type {
  IStudentDTO,
  ICreateStudentDTO,
  TPersonalPronoun,
} from "./students";
export type {
  ICreateInlineOrderItemDTO,
  ICreateCreditOrderItemDTO,
  IOrderItemDTO,
  IOrderDTO,
} from "./orders";
export type { IInvoiceDTO } from "./invoices";
export type {
  TDocumentDTO,
  ICustomDocumentDTO,
  ISignnowDocumentDTO,
} from "./documents";
export type { TSignnowAccountStatus } from "./integrations";

export type { IStorageFileDTO, TOwnershipType } from "./files";
export type { IManageWaitlistDTO } from "./waitlists";

export type {
  ICardDTO,
  ICustomerStripePaymentMethodDTO,
} from "./payment-methods";
const api = {
  apiService,
  availability,
  applications,
  common,
  paymentMethods,
  healthLog,
  profile,
  students,
  orders,
  documents,
  invoices,
  integrations,
  attendances,
  credits,
  promocodes,
  files,
  waitlists,
  questionSets,
  requiredForAttendance,
};

export default api;
